import React, { Component } from 'react';
import { Rings } from  'react-loader-spinner';

class Loader extends Component {

  render() {
    const { loading } = this.props;
    return (
      <Rings
        height="100"
        width="100"
        color="black"
        radius="6"
        wrapperStyle={{
          flex: 1, 
          backgroundColor: 'rgba(255,255,255,0.5)', 
          alignItems: "center", 
          justifyContent: "center",
          position: "absolute",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          pointerEvents: "auto",
          zIndex:10
        }}
        wrapperClass=""
        visible={ loading }
        ariaLabel="rings-loading"
      />
    )

  }
};

export default Loader;

