import React, { useState, useEffect } from 'react';
import jStat from 'jstat'
import { Row, Col, Table } from "reactstrap";
import Plot from 'react-plotly.js';

export const HistChart = (
  {
    title,
    dataSeries,
    xAxisText,
    yAxisText,
    yAxisMin,
    yAxisMax,
    decimalDigitsGeneral,
    setSelectedId,
    downloadFileName,
    showLegend,
  }) => {
  const [data, setData] = useState([]);
  const [stats, setStats] = useState([]);
  const [layout, setLayout] = useState({});
  
  var disk = {
    // Plotly disk: https://github.com/plotly/plotly.js/blob/master/src/fonts/ploticon.js
    'width': 857.1,
    'height': 1000,
    'path': 'm214-7h429v214h-429v-214z m500 0h72v500q0 8-6 21t-11 20l-157 156q-5 6-19 12t-22 5v-232q0-22-15-38t-38-16h-322q-22 0-37 16t-16 38v232h-72v-714h72v232q0 22 16 38t37 16h465q22 0 38-16t15-38v-232z m-214 518v178q0 8-5 13t-13 5h-107q-7 0-13-5t-5-13v-178q0-8 5-13t13-5h107q7 0 13 5t5 13z m357-18v-518q0-22-15-38t-38-16h-750q-23 0-38 16t-16 38v750q0 22 16 38t38 16h517q23 0 50-12t42-26l156-157q16-15 27-42t11-49z',
    'transform': 'matrix(1 0 0 -1 0 850)'
  };

  const updateXAxis = () => {

    return {
      title: xAxisText ? xAxisText : null,
      // showgrid: true,
      showline: true,
      rangemode: "tozero",
      // autorange: (xAxisMin || xAxisMax) ? null : "reversed",
      // range: [
      //   0,
      //   Math.max(...dataSeries.x)
      // ],
      // minor: {
      //   showgrid: true,
      //   gridcolor: "lightgrey",
      // }
    }
  };

  const updateYAxis = () => {
    return {
      title: yAxisText ? yAxisText : null,
      // zeroline: true,
      // mirror: "all",
      // showgrid: true,
      showline: true,
      tickformat: "0."+decimalDigitsGeneral+"%",
    }
  };

  const updateStatistics = () => {
    let x = dataSeries[0].x
    var stats = [
      {
        title: "Mean",
        // value: jStat.mean(x).toFixed(decimalDigitsGeneral)
        value: new Intl.NumberFormat("en-US", {maximumFractionDigits: decimalDigitsGeneral}).format(jStat.mean(x))
      },
      {
        title: "stdDev",
        // value: jStat.stdev(x).toFixed(decimalDigitsGeneral)
        value: new Intl.NumberFormat("en-US", {maximumFractionDigits: decimalDigitsGeneral}).format(jStat.stdev(x))
      },
      {
        title: "Skewness",
        // value: jStat.skewness(x).toFixed(decimalDigitsGeneral)
        value: new Intl.NumberFormat("en-US", {maximumFractionDigits: decimalDigitsGeneral}).format(jStat.skewness(x))
      },
      {
        title: "Kurtosis",
        // value: jStat.kurtosis(x).toFixed(decimalDigitsGeneral)
        value: new Intl.NumberFormat("en-US", {maximumFractionDigits: decimalDigitsGeneral}).format(jStat.kurtosis(x))
      },
      {
        title: "5th-Percentile",
        // value: jStat.percentile(x, 0.05).toFixed(decimalDigitsGeneral)
        value: new Intl.NumberFormat("en-US", {maximumFractionDigits: decimalDigitsGeneral}).format(jStat.percentile(x, 0.05))
      },
      {
        title: "25th-Percentile",
        // value: jStat.percentile(x, 0.25).toFixed(decimalDigitsGeneral)
        value: new Intl.NumberFormat("en-US", {maximumFractionDigits: decimalDigitsGeneral}).format(jStat.percentile(x, 0.25))
      },
      {
        title: "50th-Percentile",
        // value: jStat.percentile(x, 0.5).toFixed(decimalDigitsGeneral)
        value: new Intl.NumberFormat("en-US", {maximumFractionDigits: decimalDigitsGeneral}).format(jStat.percentile(x, 0.5))
      },
      {
        title: "75th-Percentile",
        // value: jStat.percentile(x, 0.75).toFixed(decimalDigitsGeneral)
        value: new Intl.NumberFormat("en-US", {maximumFractionDigits: decimalDigitsGeneral}).format(jStat.percentile(x, 0.75))
      },
      {
        title: "95th-Percentile",
        // value: jStat.percentile(x, 0.95).toFixed(decimalDigitsGeneral)
        value: new Intl.NumberFormat("en-US", {maximumFractionDigits: decimalDigitsGeneral}).format(jStat.percentile(x, 0.95))
      }

    ]

    return stats
  };

  const onClickHandler = (data) => {
    // console.log(data);
    let pt = data.points[0];
    let pt_idx = pt.pointIndex;
    let id = pt.data.id[pt_idx]
    // let x = pt.data.x[pt_idx]
    // let xn = jStat.normal.cdf(x, 0, 1)
    // let y = pt.data.y[pt_idx]
    // let name = pt.data.name
    // console.log("Clicked: ", name, id, x, y, xn)
    if (setSelectedId) {
      setSelectedId(id)
    }
  };

  const cleanFileName = (filename) => {
    let cleanedTitle = filename
      .replace(/<\/?[^>]+(>|$)/g, "_")
      .replace(/[^a-zA-Z0-9]/g, "_")
      .replace(" ", "_")
      .replace(/_+/g, "_");
    if (cleanedTitle.endsWith("_")) {
      cleanedTitle = cleanedTitle.substring(0, cleanedTitle.length - 1);
    }
    return cleanedTitle;
  };

  const csvDownload = {
    name: 'downloadCsv',
    title: 'Download data as csv',
    icon: disk,
    click: function (d) {
      // Borrowed some from here https://github.com/plotly/plotly.js/issues/2171
      var filename = downloadFileName ? downloadFileName : "QuantileSamplingData";
      var csvData = [];

      let trace = d.data[0]
      var header = [trace.name];

      csvData.push(header);
      csvData = csvData.concat(trace.x)
      
      var csvFile = csvData.join("\r\n");
      var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
      var link = document.createElement("a");
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", cleanFileName(filename) ? cleanFileName(filename) + ".csv" : "Quantile_Sampling_Data.csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    const updatePlot = () => {
      // let data = [updateData()]
      setData(dataSeries)
      setStats(updateStatistics())
      let layout = {
        title: title ? title : null,
        margin: { t: 50 },
        showlegend: showLegend,
        xaxis: updateXAxis(),
        yaxis: updateYAxis()
      }
      setLayout(layout)

    }
    updatePlot()

    // eslint-disable-next-line
  }, [
    title,
    dataSeries,
    xAxisText,
    yAxisText,
    yAxisMin,
    yAxisMax,
    decimalDigitsGeneral,
    showLegend,
  ]);

  const info = stats;
  const SummaryTable = (() => {
    return (
      <Table bordered style={{fontSize:14}}>
      <thead>
        <tr>
          <th colSpan={info.length}>Summary Statistics</th>
        </tr>
      </thead>
      <tbody>
        {info.map((item, index) => (
          <tr key={index}>
              <td style={{fontSize: 14, width:`50%`, textAlign:'left', padding: 5}}>{item.title}</td>
              <td style={{fontSize: 14, width:`50%`, textAlign:'center', padding: 5}}>{item.value}</td>
          </tr>
        ))}
      </tbody>
    </Table>
    )
  })

  return (
    <div>
      <Row>
        <Col md="8">
          <Plot
            data={data}
            layout={layout}
            style={{ "width": "100%", "height": "100%" }}
            onClick={(d) => { onClickHandler(d) }}
            // divId={"plotly"}
            useResizeHandler
            config={{
              displaylogo: false,
              toImageButtonOptions: {
                filename: downloadFileName ? downloadFileName : "Histogram_Plot",
                height: 500,
                width: 700,
              },
              modeBarButtons: [
                [csvDownload],
                [
                  'toImage',
                  'zoom2d',
                  'pan2d',
                  'autoScale2d',
                  'resetScale2d',
                ]
              ]
            }}
          />
        </Col>
        <Col md="4" className='mt-5'>
          <Col md="10" className='mb-5 mt-5 pb-5'>
          {info && (
            <SummaryTable />
          )}
          </Col>
        </Col>
      </Row>
    </div>

  );
}
