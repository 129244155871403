export const required = ([key, value]) =>
  value && value.length > 0 ? "" : `Required.`;

export const min = (mn) => ([key, value]) =>
  value > mn ? "" : `Value must be greater than ${mn}.`;

export const max = (mx) => ([key, value]) =>
  value && value < mx ? "" : `Value must be less than ${mx}.`;

export const between = (mn, mx) => ([key, value]) =>
  mn < value < mx ? "" : `Value must be between ${mn} and ${mx}`;

export const validate = (validator, obj, initialErrState) => {
  const errors = Object.entries(obj).reduce(
    (newObj, [key, value]) => {
      if (typeof value === "object") {
        if (Array.isArray(value)) {
          newObj[key] =
            typeof validator[key] === "function"
              ? value.map((v) => validator[key]([key, v]))
              : value.map((v) => validate(validator[key], v));
          return newObj;
        }
        newObj[key] = validate(validator[key], value);
        return newObj;
      }
      let validFn = validator[key];
      if (validFn) {
        newObj[key] = validFn([key, value]);
        if (newObj[key].length > 0) {
          newObj.valid = false;
        }
      }
      return newObj;
    },
    initialErrState ? initialErrState : { valid: true }
  );
  return errors;
};

export const checkErrors = (errors) => {
  let valid = true;
  for (let v of Object.values(errors)) {
    if (Array.isArray(v)) {
      for (let elm of Object.values(v)) {
        if (typeof elm === "object") {
          if (!checkErrors(elm)) {
            valid = false 
            break
          }
        } else if (elm !== undefined && elm.length > 0) {
          valid = false;
          break
        }
      }
    } else {
      if (typeof v === "object") {
        if (!checkErrors(v)){
          valid = false 
          break
        }
      } else if (v !== undefined && v.length > 0) {
        valid = false;
        break
      }
    }
  }
  return valid;
};

export const getErrorString = (err) => {
  if (typeof err === "string") {
    return err;
  }
  
  if (typeof err === "object" && err !== null) {
    if ('message' in err && typeof err.message === 'string') {
      return err.message
    } else {
      return JSON.stringify(err);
    }
  }

  if (!(err == null)) {
    console.log({ err });
    return "See console log.";
  }

  return ""
}
