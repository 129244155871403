export const SET_LIST = "SET_ANALYTICAL_DIST_PARAMS_LIST";
export const SET_LIST_ERROR = "SET_ANALYTICAL_DIST_PARAMS_LIST_ERROR";
export const SET_ITEM = "SET_ANALYTICAL_DIST_PARAMS";
export const SET_ITEM_ERROR = "SET_ANALYTICAL_DIST_PARAMS_ERROR";
export const UPDATE_ITEM = "UPDATE_ANALYTICAL_DIST_PARAMS";
export const UPDATE_ITEM_ERROR = "UPDATE_ANALYTICAL_DIST_PARAMS_ERROR";
export const CREATE_ITEM = "CREATE_ANALYTICAL_DIST_PARAMS";
export const CREATE_ITEM_ERROR = "CREATE_ANALYTICAL_DIST_PARAMS_ERROR";
export const HANDLE_FORM_CHANGE = "HANDLE_ANALYTICAL_DIST_PARAMS_FORM_CHANGE";

export function setList(list) {
  return {
    type: SET_LIST,
    list: list,
  };
}

export function setListError(error) {
  return {
    type: SET_LIST_ERROR,
    error: error,
  };
}

export function setItem(item) {
  return {
    type: SET_ITEM,
    item: item,
  };
}

export function setItemError(error) {
  return {
    type: SET_ITEM_ERROR,
    error: error,
  };
}

export function updateItem(item) {
  return {
    type: UPDATE_ITEM,
    item: item,
  };
}

export function updateItemError(error) {
  return {
    type: UPDATE_ITEM_ERROR,
    error: error,
  };
}

export function createItem(itemId) {
  return {
    type: CREATE_ITEM,
    itemId: itemId,
  };
}

export function createItemError(error) {
  return {
    type: CREATE_ITEM_ERROR,
    error: error,
  };
}

export function handleFormChange(e) {
  e.persist();
  return {
    type: HANDLE_FORM_CHANGE,
    event: e,
  };
}

export const analyticalDistParamsReducer = (state, action) => {
  switch (action.type) {
    case SET_LIST:
      return {
        ...state,
        list: action.list,
      };
    case SET_ITEM:
      if (!action.item.errors) {
        action.item.errors = analyticalDistParamsInitialState.item.errors;
      }
      return {
        ...state,
        item: action.item,
      };
    case UPDATE_ITEM:
      action.item.errors = analyticalDistParamsInitialState.item.errors;
      return {
        ...state,
        item: action.item,
      };
    case CREATE_ITEM:
      const id = action.id;
      const newItem = { ...state.item, id: id };
      newItem.errors = analyticalDistParamsInitialState.item.errors;
      return {
        ...state,
        item: newItem,
      };
    case HANDLE_FORM_CHANGE:
      const { name, value } = action.event.target;
      let errors = state.item.errors;
      errors[name] = value.length < 1 ? "Required." : "";
      const changedItem = { ...state.item, [name]: value, errors };
      return {
        ...state,
        item: changedItem,
      };
    case SET_LIST_ERROR:
    case SET_ITEM_ERROR:
    case UPDATE_ITEM_ERROR:
    case CREATE_ITEM_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export const analyticalDistParamsInitialState = {
  list: [],
  item: {
    probabilityDistribution: "",
    location: "",
    shape: "",
    scale: "",
    realizations: "",
    sampleSize: "",
    minOutputProbability: "",
    maxOutputProbability: "",
    outputIncrements: "",
    errors: {
      probabilityDistribution: "",
      location: "",
      shape: "",
      scale: "",
      realizations: "",
      sampleSize: "",
      minOutputProbability: "",
      maxOutputProbability: "",
      outputIncrements: "",
    },
  },
  error: null,
};
