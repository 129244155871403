import { getErrorString } from "../util/validation";
export const SET_LIST = "SET_PRECIP_FREQUENCY_LIST";
export const SET_LIST_ERROR = "SET_PRECIP_FREQUENCY_LIST_ERROR";
export const UPDATE_LIST_ON_UPDATE = "UPDATE_PRECIP_FREQUENCY_LIST_ON_UPDATE";
export const UPDATE_LIST_ON_CREATE = "UPDATE_PRECIP_FREQUENCY_LIST_ON_CREATE";
export const SET_ITEM = "SET_PRECIP_FREQUENCY";
export const MERGE_ITEM = "MERGE_PRECIP_ITEM";
export const SET_ITEM_ERROR = "SET_PRECIP_FREQUENCY_ERROR";
export const DELETE_ITEM = "DELETE_PRECIP_FREQUENCY";
export const DELETE_ITEM_ERROR = "DELETE_PRECIP_FREQUENCY_ERROR";
export const HANDLE_CHANGE = "HANDLE_PRECIP_FREQUENCY_CHANGE";
export const SET_CURVES = "SET_PRECIP_FREQUENCY_CURVES";
export const ADD_CURVE = "ADD_EMPIRICAL_CURVE";
export const EDIT_CURVE = "EDIT_EMPIRICAL_CURVE";
export const DELETE_CURVE = "DELETE_CURVE";
export const FILE_UPLOAD_ERROR = "FILE_UPLOAD_ERROR";
export const HANDLE_FREQUENCY_TYPE_CHANGE = "HANDLE_FREQUENCY_TYPE_CHANGE";
export const SET_SAVEPOINT = "SET_PRECIP_SAVEPOINT"

export const A14_SET_DURATION_AND_UNIT = "A14_SET_DURATION_AND_UNIT";
export const A14_HANDLE_CHANGE = "A14_HANDLE_CHANGE";
export const A14_SET_FEATURE = "A14_SET_FEATURE";
export const PROCESS_A14_GRIDS = "PROCESS_A14_GRIDS";
export const BOOTSTRAP_HANDLE_CHANGE = "BOOTSTRAP_HANDLE_CHANGE";
export const BOOTSTRAP_SET_DEFAULT = "BOOTSTRAP_SET_DEFAULT";
export const BOOTSTRAP_SET_DISTRIBUTION = "BOOTSTRAP_SET_DISTRIBUTION";
export const BOOTSTRAP_HANDLE_PARAMETER_CHANGE =
  "BOOTSTRAP_HANDLE_PARAMETER_CHANGE";
export const HANDLE_SPATIAL_TYPE_CHANGE = "HANDLE_SPATIAL_TYPE_CHANGE";

export const Empirical = "Empirical Expected CDF";
export const A14 = "NOAA A14 GIS Data";
export const Analytical =
  "Analytical Distribution Parameters (e.g., from L-RAP)";
export const SET_USE_ARF_LIST = "SET_USE_ARF_LIST";
export const SET_AREAL_REDUCTION_FACTOR_LIST = "SET_AREAL_REDUCTION_FACTOR_LIST";
export const ADD_AREAL_REDUCTION_FACTOR = "ADD_AREAL_REDUCTION_FACTOR";
export const EDIT_AREAL_REDUCTION_FACTOR = "EDIT_AREAL_REDUCTION_FACTOR";
export const DELETE_AREAL_REDUCTION_FACTOR = "DELETE_AREAL_REDUCTION_FACTOR";
export const RESET_STATE = "RESET_PRECIPITATION_STATE"

export const precipFrequencyActions = {
  fileUploadError(error) {
    return {
      type: FILE_UPLOAD_ERROR,
      error: error,
    };
  },
  setCurves(curves) {
    return {
      type: SET_CURVES,
      curves: curves,
    };
  },
  addCurve(curve) {
    return {
      type: ADD_CURVE,
      curve: curve,
    };
  },
  editCurve(curve, index) {
    return {
      type: EDIT_CURVE,
      curve: curve,
      index: index,
    }
  },
  deleteCurve(index) {
    return {
      type: DELETE_CURVE,
      index: index,
    };
  },
  setList(list) {
    return {
      type: SET_LIST,
      list: list,
    };
  },
  setListError(error) {
    return {
      type: SET_LIST_ERROR,
      error: error,
    };
  },
  updateListOnUpdate(item) {
    return {
      type: UPDATE_LIST_ON_UPDATE,
      item: item,
    };
  },
  updateListOnCreate(item) {
    return {
      type: UPDATE_LIST_ON_CREATE,
      item: item,
    };
  },
  setItem(item) {
    return {
      type: SET_ITEM,
      item: item,
    };
  },
  mergeItem(item) {
    return {
      type: MERGE_ITEM,
      item: item,
    }
  },
  setItemError(error) {
    return {
      type: SET_ITEM_ERROR,
      error: error,
    };
  },
  deleteItem(id) {
    return {
      type: DELETE_ITEM,
      id: id,
    };
  },
  deleteItemError(error) {
    return {
      type: DELETE_ITEM_ERROR,
      error: error,
    };
  },
  handleSpatialTypeChange(e) {
    e.persist();
    const { value } = e.target;
    return {
      type: HANDLE_SPATIAL_TYPE_CHANGE,
      spatialType: value,
    };
  },
  handleFrequencyTypeChange(e) {
    e.persist();
    return {
      type: HANDLE_FREQUENCY_TYPE_CHANGE,
      frequencyType: e.target.value,
    }
  },
  handleChange(e) {
    e.persist();
    return {
      type: HANDLE_CHANGE,
      event: e,
    };
  },
  handleUseArfChange(e) {
    e.persist();
    const checked = e.target.checked
    return {
      type: SET_USE_ARF_LIST,
      useArealReductionFactorList: checked
    };
  },
  setArealReductionFactorList(data) {
    return {
      type: SET_AREAL_REDUCTION_FACTOR_LIST,
      arealReductionFactorList: data
    };
  },
  addArf(arf) {
    return {
      type: ADD_AREAL_REDUCTION_FACTOR,
      arf: arf,
    };
  },
  editArf(arf, index) {
    return {
      type: EDIT_AREAL_REDUCTION_FACTOR,
      arf: arf,
      index: index,
    }
  },
  deleteArf(index) {
    return {
      type: DELETE_AREAL_REDUCTION_FACTOR,
      index: index,
    };
  },
  resetState() {
    return {
      type: RESET_STATE,
    };
  },
  setSavepoint(item) {
    return {
      type: SET_SAVEPOINT,
      item: item,
    }
  },
  a14: {
    handleChange(e) {
      e.persist();
      return {
        type: A14_HANDLE_CHANGE,
        event: e,
      };
    },
    setDuration(d) {
      return {
        type: A14_SET_DURATION_AND_UNIT,
        duration: d,
      };
    },
    setFeature(feature) {
      return {
        type: A14_SET_FEATURE,
        feature: feature,
      };
    }
  },
  bootstrap: {
    handleChange(e) {
      e.persist();
      return {
        type: BOOTSTRAP_HANDLE_CHANGE,
        event: e,
      };
    },
    setDistribution(distribution) {
      return {
        type: BOOTSTRAP_SET_DISTRIBUTION,
        distribution: distribution,
      };
    },
    setBootstrapDefault(field) {
      return {
        type: BOOTSTRAP_SET_DEFAULT,
        field: field,
      }
    },
    handleParameterChange(e) {
      e.persist();
      return {
        type: BOOTSTRAP_HANDLE_PARAMETER_CHANGE,
        event: e,
      };
    },
    processA14Grids(item) {
      return {
        type: PROCESS_A14_GRIDS,
        item: item,
      }
    },
  },
};

export const precipFrequencyReducer = (state, action) => {
  let newState = JSON.parse(JSON.stringify(state))
  let newItem = JSON.parse(JSON.stringify(state.item))
  switch (action.type) {
    case SET_LIST:
      newState.list = action.list 
      return newState
    case UPDATE_LIST_ON_UPDATE:
      let updateList = newState.list.filter((i) => i.id !== action.item.id);
      updateList.push({
        id: action.item.id,
        name: action.item.name,
        description: action.item.description,
        spatialType: action.item.spatialType,
        frequencyType: action.item.frequencyType,
        arealReductionFactor: action.item.arealReductionFactor,
      });
      newState.list = updateList
      return newState
    case UPDATE_LIST_ON_CREATE:
      let { list: createList } = newState;
      createList.push({
        id: action.item.id,
        name: action.item.name,
        description: action.item.description,
        spatialType: action.item.spatialType,
        frequencyType: action.item.frequencyType,
        arealReductionFactor: action.item.arealReductionFactor,
      });
      return newState
    case SET_ITEM:
      if (!action.item.errors) {
        action.item.errors = JSON.parse(JSON.stringify(validationErrorsValidState))
      }
      // bootstrap default checkbox state is not saved in the backend - need to add this into app state on every item load 
      if (action.item.bootstrap) {
        action.item.defaultsChecked = {
          parameters: Object.keys(bootstrapParameterDefault).every(key => bootstrapParameterDefault[key] === action.item.bootstrap[key]),
          aep: Object.keys(bootstrapAepDefault).every(key => bootstrapAepDefault[key] === action.item.bootstrap[key]),
          prng: Object.keys(bootstrapPrngDefault).every(key => bootstrapPrngDefault[key] === action.item.bootstrap[key]),
        }
      }
      newState.item = action.item
      newState.error = null
      return newState
    case MERGE_ITEM:
      newState.item = {...newItem, ...action.item}
      newState.item.errors = {...newItem.errors, ...action.item.errors}
      return newState
    case HANDLE_SPATIAL_TYPE_CHANGE:
      newState.item.spatialType = action.spatialType 
      newState.item.arealReductionFactor = action.spatialType === "Point" ? "1.0" : null 
      newState.item.errors.spatialType = ""
      newState.item.errors.arealReductionFactor = ""
      return newState
    case HANDLE_FREQUENCY_TYPE_CHANGE:
      switch (action.frequencyType){
        case Empirical:
          newState.item = JSON.parse(JSON.stringify(empiricalFrequencyInitialState))
          newState.savepoint = JSON.parse(JSON.stringify(empiricalFrequencyInitialState))
          break
        case Analytical:
          newState.item = JSON.parse(JSON.stringify(analyticalFrequencyInitialState))
          newState.savepoint = JSON.parse(JSON.stringify(analyticalFrequencyInitialState))
          break
        case A14:
          newState.item = JSON.parse(JSON.stringify(a14FrequencyInitialState))
          newState.savepoint = JSON.parse(JSON.stringify(a14FrequencyInitialState))
          break
        default:
          break
      }
      newState.item.frequencyType = action.frequencyType
      newState.item.errors.frequencyType = ""
      newState.error = null
      return newState
    case SET_CURVES:
      newState.item.curves = action.curves
      newState.item.errors.curves = ""
      return newState
    case ADD_CURVE:
      let { curves: addCurves } = newState.item
      addCurves.push(action.curve);
      newState.item.errors.curves = "";
      return newState
    case EDIT_CURVE:
      let { curves: editCurves = [] } = newState.item;
      newState.item.curves?.length ? (editCurves[action.index] = action.curve) : editCurves.push(action.curve);
      newState.item.errors.curves = "";
      return newState;
    case DELETE_CURVE:
      let { curves: deleteCurves } = newState.item;
      deleteCurves.splice(action.index, 1);
      newState.item.errors.curves = "";
      return newState
    case DELETE_ITEM:
      let deleteList = newState.list.filter((i) => i.id !== action.id);
      newState.list = deleteList
      return newState
    case A14_SET_DURATION_AND_UNIT:
    case A14_SET_FEATURE:
    case A14_HANDLE_CHANGE:
      newState.item = a14Reducer(state, action)
      return newState
    case SET_USE_ARF_LIST:
      newState.item.useArealReductionFactorList = action.useArealReductionFactorList
      return newState
    case SET_AREAL_REDUCTION_FACTOR_LIST:
      newState.item.arealReductionFactorList = action.arealReductionFactorList
      return newState
    case ADD_AREAL_REDUCTION_FACTOR:
      let { arealReductionFactorList: newArfList } = newState.item
      newArfList.push(action.arf);
      newState.item.errors.arealReductionFactorList = "";
      return newState
    case EDIT_AREAL_REDUCTION_FACTOR:
      let { arealReductionFactorList: editArfList = [] } = newState.item
      editArfList[action.index] = action.arf || editArfList.push(action.arf);
      newState.item.errors.arealReductionFactorList = "";
      return newState
    case DELETE_AREAL_REDUCTION_FACTOR:
      let { arealReductionFactorList: deleteArfList } = newState.item;
      deleteArfList.splice(action.index, 1);
      newState.item.errors.arealReductionFactorList = "";
      return newState
    case PROCESS_A14_GRIDS:
    case BOOTSTRAP_HANDLE_CHANGE:
    case BOOTSTRAP_SET_DEFAULT:
    case BOOTSTRAP_SET_DISTRIBUTION:
    case BOOTSTRAP_HANDLE_PARAMETER_CHANGE:
      newState.item = bootstrapReducer(state, action)
      return newState
    case HANDLE_CHANGE:
      const { name, value } = action.event.target;
      let { errors } = newState.item;
      if (typeof errors[name] !== 'undefined') {
        errors[name] = value.length < 1 ? "Required." : "";
      }
      newState.item[name] = value
      return newState
    case FILE_UPLOAD_ERROR:
    case SET_LIST_ERROR:
    case SET_ITEM_ERROR:
    case DELETE_ITEM_ERROR:
      newState.error = getErrorString(action.error)
      return newState
    case RESET_STATE:
      return JSON.parse(JSON.stringify(precipFrequencyInitialState))
    case SET_SAVEPOINT:
      newState.savepoint = action.item
      return newState
    default:
      return state;
  }
};

export function a14Reducer(state, action) {
  let newItem = JSON.parse(JSON.stringify(state.item))
  switch (action.type) {
    case A14_SET_DURATION_AND_UNIT:
      const { duration } = action;
      newItem.duration = duration.duration
      newItem.durationUnit = duration.unit
      newItem.errors.duration = isNaN(duration.duration) ? "Required." : "";
      newItem.errors.durationUnit = "";
      return newItem
    case A14_HANDLE_CHANGE:
      const { name, value } = action.event.target;
      newItem.a14[name] = value
      newItem.errors.a14[name] = value.length < 1 ? "Required." : "";
      return newItem
    case A14_SET_FEATURE:
      newItem.a14.feature = action.feature;
      newItem.errors.a14.features = "";
      newItem.errors.a14.series = "";
      return newItem
    default:
      return state;
  }
}

export function bootstrapReducer(state, action) {
  let newItem = JSON.parse(JSON.stringify(state.item))
  switch (action.type) {
    case BOOTSTRAP_HANDLE_CHANGE:
      let { name, value } = action.event.target;
      newItem.bootstrap[name] = value
      newItem.errors.bootstrap[name] = value.length < 1 ? "Required." : "";
      if (name === 'minAep' && parseFloat(value) < 1e-10) {
        newItem.errors.bootstrap[name] = "Minimum AEP limit is 1E-10."
      }
      if (name === 'maxAep' && parseFloat(value) > 0.999) {
        newItem.errors.bootstrap[name] = "Maximum AEP limit is 0.999."
      }
      if (Object.keys(bootstrapAepDefault).includes(name)) newItem.defaultsChecked.aep = false
      if (Object.keys(bootstrapParameterDefault).includes(name)) newItem.defaultsChecked.parameters = false
      if (Object.keys(bootstrapPrngDefault).includes(name)) newItem.defaultsChecked.prng = false
      return newItem
    case BOOTSTRAP_SET_DISTRIBUTION:
      const paramErrs = new Array(action.distribution.parameters.length).fill({ value: "Required." });
      newItem.bootstrap.distributionType = action.distribution.abbrev
      newItem.bootstrap.distributionParameters = action.distribution.parameters
      newItem.errors.bootstrap.distributionParameters = paramErrs
      return newItem
    case BOOTSTRAP_HANDLE_PARAMETER_CHANGE:
      let { target } = action.event;
      // index in the array is the target.id
      newItem.bootstrap.distributionParameters[target.id].value = target.value;
      newItem.errors.bootstrap.distributionParameters[target.id].value = target.value.length < 1 ? "Required." : "";
      return newItem
    case BOOTSTRAP_SET_DEFAULT:
      let bootstrap = {}
      let bootstrapErrors = {}
      switch (action.field) {
        case 'parameters':
          bootstrap = Object.assign({}, bootstrapParameterDefault)
          bootstrapErrors = Object.assign({}, bootstrapParameterDefaultError)
          newItem.defaultsChecked.parameters = true
          break 
        case 'aep':
          bootstrap = Object.assign({}, bootstrapAepDefault)
          bootstrapErrors = Object.assign({}, bootstrapAepDefaultError)
          newItem.defaultsChecked.aep = true
          break 
        case 'prng':
          bootstrap = Object.assign({}, bootstrapPrngDefault)
          bootstrapErrors = Object.assign({}, bootstrapPrngDefaultError)
          newItem.defaultsChecked.prng = true
          break
        default:
          break
      }
      newItem.bootstrap = {...newItem.bootstrap, ...bootstrap}
      newItem.errors.bootstrap = {...newItem.errors.bootstrap, ...bootstrapErrors}
      return newItem
    case PROCESS_A14_GRIDS:
      newItem.curves = action.item.curves
      newItem.bootstrap.erl = action.item.bootstrap.erl
      newItem.bootstrap.distributionParameters = action.item.bootstrap.distributionParameters
      newItem.errors.curves = ""
      newItem.errors.bootstrap.erl = ""
      newItem.errors.bootstrap.distributionParameters = [...validationErrorsValidState.bootstrap.distributionParameters]
      return newItem
    default:
      return newItem;
  }
}

export const validationErrorsValidState = {
  name: "",
  description: "",
  spatialType: "",
  arealReductionFactor: "",
  frequencyType: "",
  duration: "",
  durationUnit: "",
  curves: "",
  useArealReductionFactorList: "",
  arealReductionFactorList: "",
  a14: {
    region: "",
    series: "",
    features: "",
  },
  bootstrap: {
    erl: "",
    distributionType: "",
    distributionParameters: [{ value: "" }, { value: "" }, { value: "" }],
    minAep: "",
    maxAep: "",
    numRealizations: "",
    numIncrements: "",
    upperConfidence: "",
    lowerConfidence: "",
    rngSeed: "",
  },
};

export const a14InitialState = {
  region: "",
  series: "Annual maximum",
  feature: null,
};

const bootstrapParameterDefault = {
  numRealizations: 1000,
  numIncrements: 100,
  upperConfidence: 95,
  lowerConfidence: 5,
}
const bootstrapPrngDefault = {
  rngSeed: 12345,
}
const bootstrapPrngDefaultError = {
  rngSeed: "",
}
const bootstrapParameterDefaultError = {
  numRealizations: "",
  numIncrements: "",
  upperConfidence: "",
  lowerConfidence: "",
}
const bootstrapAepDefault = {
  minAep: 1E-8,
  maxAep: 0.99,
}

const bootstrapAepDefaultError = {
  minAep: "",
  maxAep: "",
}

export const bootstrapInitialState = {
  erl: "",
  distributionType: "GEV",
  distributionParameters: [
    {
      variableName: "xi",
      commonName: "location",
      value: "",
    },
    {
      variableName: "alpha",
      commonName: "scale",
      value: "",
    },
    {
      variableName: "k",
      commonName: "shape",
      value: "",
    },
  ],
  ...bootstrapParameterDefault,
  ...bootstrapAepDefault,
  ...bootstrapPrngDefault,
};
const A14ErrorState = {
  region: "Required.",
  series: "Required.",
  features: "Required.",
};

const BootstrapErrorState = {
  erl: "Required.",
  distributionType: "",
  distributionParameters: [
    { value: "Required." },
    { value: "Required." },
    { value: "Required." },
  ],
  ...bootstrapParameterDefaultError,
  ...bootstrapAepDefaultError,
  ...bootstrapParameterDefaultError,
};

export const precipFrequencyInitialState = {
  list: [],
  item: {
    name: "",
    description: "",
    spatialType: "",
    arealReductionFactor: "",
    frequencyType: "",
    duration: "",
    durationUnit: "",
    curves: [],
    useArealReductionFactorList: false,
    arealReductionFactorList: [],
    errors: {
      name: "Required.",
      spatialType: "Required.",
      arealReductionFactor: "Required.",
      frequencyType: "Required.",
      description: "",
      duration: "Required.",
      durationUnit: "Required.",
      curves: "Required.",
      a14: {
        region: "Required.",
        series: "Required.",
        features: "Required.",
      },
      bootstrap: {
        erl: "Required.",
        distributionType: "",
        distributionParameters: [
          { value: "Required." },
          { value: "Required." },
          { value: "Required." },
        ],
        minAep: "",
        maxAep: "",
        numRealizations: "",
        numIncrements: "",
        upperConfidence: "",
        lowerConfidence: "",
        rngSeed: "",
      },
    },
  },
  error: null,
  savepoint: {
    name: "",
    description: "",
    spatialType: "",
    arealReductionFactor: "",
    frequencyType: "",
    duration: "",
    durationUnit: "",
    curves: [],
    useArealReductionFactorList: false,
    arealReductionFactorList: [],
    errors: {
      name: "Required.",
      spatialType: "Required.",
      arealReductionFactor: "Required.",
      frequencyType: "Required.",
      duration: "Required.",
      durationUnit: "Required.",
      curves: "Required.",
      a14: {
        region: "Required.",
        series: "Required.",
        features: "Required.",
      },
      bootstrap: {
        erl: "Required.",
        distributionType: "",
        distributionParameters: [
          { value: "Required." },
          { value: "Required." },
          { value: "Required." },
        ],
        minAep: "",
        maxAep: "",
        numRealizations: "",
        numIncrements: "",
        upperConfidence: "",
        lowerConfidence: "",
        rngSeed: "",
      },
    },
  },
};

const empiricalFrequencyInitialState = {
  arealReductionFactor: null,
  arealReductionFactorList: [],
  bootstrap: null,
  curves: [],
  description: "",
  duration: "",
  durationUnit: "",
  errors: {
    arealReductionFactor: "",
    curves: "Required.",
    description: "",
    duration: "Required.",
    durationUnit: "Required.",
    frequencyType: "",
    name: "Required.",
    spatialType: "",
  },
  frequencyType: Empirical,
  name: "",
  spatialType: "Areal",
  useArealReductionFactorList: false,
}

const analyticalFrequencyInitialState = {
  arealReductionFactor: "1.0",
  arealReductionFactorList: [],
  bootstrap: bootstrapInitialState,
  defaultsChecked: {
    aep: true,
    parameters: true,
    prng: true,
  },
  curves: [],
  description: "",
  duration: "",
  durationUnit: "",
  errors: {
    arealReductionFactor: "",
    bootstrap: BootstrapErrorState,
    curves: "",
    description: "",
    duration: "Required.",
    durationUnit: "Required.",
    frequencyType: "",
    name: "Required.",
    spatialType: "Required.",
  },
  frequencyType: Analytical,
  name: "",
  spatialType: "",
  useArealReductionFactorList: false,
}

const a14FrequencyInitialState = {
  a14: a14InitialState,
  arealReductionFactor: "1.0",
  arealReductionFactorList: [],
  bootstrap: bootstrapInitialState,
  defaultsChecked: {
    aep: true,
    parameters: true,
    prng: true,
  },
  curves: [],
  description: "",
  duration: "",
  durationUnit: "",
  errors: {
    a14: A14ErrorState,
    arealReductionFactor: "",
    bootstrap: BootstrapErrorState,
    curves: "",
    description: "",
    duration: "Required.",
    durationUnit: "Required.",
    frequencyType: "",
    name: "Required.",
    spatialType: "",
  },
  frequencyType: A14,
  name: "",
  spatialType: "Point",
  useArealReductionFactorList: false,
}

const convertBoolean = (value) => {
  return Boolean(value)
}

export const flexibleDatatypes = (value) => {
  if (typeof value === 'object') {
    for (const property in value) {
      // console.log(`${property}: ${object[property]}`);
      const valueFloat = parseFloat(value[property])
      value[property] = valueFloat
    }
    return value
  } else {
    const valueFloat = parseFloat(value)
    return valueFloat
  }
}

export const normalizer = {
  duration: parseInt,
  arealReductionFactor: flexibleDatatypes,
  useArealReductionFactorList: convertBoolean,
  bootstrap: {
    erl: parseInt,
    distributionParameters: { value: parseFloat },
    numIncrements: parseInt,
    numRealizations: parseInt,
    minAep: parseFloat,
    maxAep: parseFloat,
    upperConfidence: parseFloat,
    lowerConfidence: parseFloat,
    rngSeed: parseFloat
  },
};