import { MainMenu } from "./components/MainMenu";
import { useUsers } from "./hooks/userApi";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

function RootLayout() {
    const { getMe, token } = useUsers();
    useEffect(() => {
      getMe(token);
      // eslint-disable-next-line
    }, [token]);
    return (
        <>
            <header><MainMenu /></header>
            <main><Outlet /></main>
        </>
    )
}

export default RootLayout