import React, { useContext, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";
import classnames from "classnames";
import { ButtonNavigation } from "./ButtonNavigation";
import { ExecuteSingleList } from "./ExecuteSingleList";
import { ExecuteSampledList } from "./ExecuteSampledList";
import { StateStore } from "../store/GlobalStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const webRoot = process.env.REACT_APP_WEB_CONTEXT === "/" ? "" : process.env.REACT_APP_WEB_CONTEXT

export const Execute = () => {
  const { state } = useContext(StateStore);
  const analysis = state.analysis.savepoint;

  const [activeTab, setActiveTab] = useState("sampled");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <div className="form">
                <fieldset>
                  <legend>Execute</legend>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "sampled",
                        })}
                        onClick={() => {
                          toggleTab("sampled");
                        }}
                      >
                        Sampled Execution
                        <span id={'sampledExecutionInfo'}>
                          <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                        </span>
                        <UncontrolledTooltip target="sampledExecutionInfo" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
                          <div className="text-start">
                            A Sampled Execution is used run multiple simulations at the same time,
                            scaling the precipitation for each simulation based on the selected
                            precipitation-frequency sampling.
                          </div>
                        </UncontrolledTooltip>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "single",
                        })}
                        onClick={() => {
                          toggleTab("single");
                        }}
                      >
                        Single Execution
                        <span id={'singleExecutionInfo'}>
                          <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                        </span>
                        <UncontrolledTooltip target="singleExecutionInfo" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
                          <div className="text-start">
                            A Single Execution is used to test executing a model before creating a Sampled Execution.
                            A Single Execution scales the precipitation to a single precipitation depth from the selected
                            Precipitation-Frequency Sample.
                          </div>
                        </UncontrolledTooltip>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="single">
                      <Row>
                        <Col md="12">
                          {activeTab === "single" && (<ExecuteSingleList analysisId={analysis.id} />)}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="sampled">
                      <Row>
                        <Col md="12">
                           {activeTab === "sampled" && (<ExecuteSampledList analysisId={analysis.id} />)}
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </fieldset>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ButtonNavigation
        prevLink={`${webRoot}/analysis/${analysis.id}/model`}
        prevLinkTooltip="Go back to HEC-HMS Model tab"
        nextLink={`${webRoot}/analysis/${analysis.id}/results`}
        nextLinkTooltip="Go to Results tab"
      />
    </div>
  );
};
