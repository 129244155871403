import { useContext } from "react";
import { DispatchStore, StateStore } from "../store/GlobalStore";
import { apiRoot } from "../services/api";
import { userActions } from "../reducers/userReducer";
import {
  analysisActions,
  analysisInitialState,
} from "../reducers/analysisReducer";
import {normalize} from "../util/normalize";

export const useAnalysisAPI = () => {
  const { state } = useContext(StateStore);
  const { dispatch } = useContext(DispatchStore);
  const { item, list, error, savepoint } = state.analysis;
  const fetchList = async () => {
    try {
        
      const res = await fetch(`${apiRoot}/analyses/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(analysisActions.setListError(json.error));
      }
      dispatch(analysisActions.setList(json.data));
    } catch (error) {
      dispatch(analysisActions.setListError(error));
    }
    finally {
        
    }
  };

  const fetchItem = async (id) => {
    try {
      const res = await fetch(`${apiRoot}/analyses/${id}/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(analysisActions.setItemError(json.error));
      }
      dispatch(analysisActions.setItem(json.data));
    } catch (error) {
      dispatch(analysisActions.setItemError(error));
    }
  };

  const deleteItem = async (id) => {
    dispatch(analysisActions.deleteItemError(null));
    try {
      const res = await fetch(`${apiRoot}/analyses/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(analysisActions.deleteItemError(json.error));
      } else {
        dispatch(analysisActions.deleteItem());
        await fetchList();
      }
    } catch (error) {
      dispatch(analysisActions.deleteItemError(error));
    }
  };

  const updateItem = async (id) => {
    if (validateItem(item.errors)) {
      dispatch(analysisActions.setItemError(null));
      try {
        const normalizer = {
          decimalDigitsGeneral: parseInt,
          decimalDigitsAep: parseInt,
        };
        let data = normalize(normalizer, item);
        delete data.errors;
        const res = await fetch(`${apiRoot}/analyses/${id}/`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
          body: JSON.stringify(data),
        });
        const json = await res.json();
        if (res.status === 401) {
          window.localStorage.removeItem("rrftToken");
          dispatch(userActions.userError("Incorrect email or password"));
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
        } else if (res.status === 403) {
          dispatch(
            userActions.userError(
              "You do not have the appropriate permissions to view / use this resource."
            )
          );
        } else if (json.error) {
          dispatch(analysisActions.setItemError(json.error));
        } else {
          dispatch(analysisActions.setItem(json.data));
        }
      } catch (error) {
        dispatch(analysisActions.setItem(error));
      }
    }
  };

  const createItem = async () => {
    if (validateItem(item.errors)) {
      dispatch(analysisActions.setItemError(null));
      try {
        let data = JSON.parse(JSON.stringify(item));
        delete data.errors;
        const res = await fetch(`${apiRoot}/analyses/`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
          body: JSON.stringify(data),
        });
        const json = await res.json();
        if (res.status === 401) {
          window.localStorage.removeItem("rrftToken");
          dispatch(userActions.userError("Incorrect email or password"));
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
        } else if (res.status === 403) {
          dispatch(
            userActions.userError(
              "You do not have the appropriate permissions to view / use this resource."
            )
          );
        } else if (json.error) {
          dispatch(analysisActions.setItemError(json.error));
        } else {
          dispatch(analysisActions.setItem(json.data));
          if (!item.id) {
            item.id = json.data.id
          }
        }
      } catch (error) {
        dispatch(analysisActions.setItemError(error));
      }
    }
  };

  const onChange = (e) => {
    dispatch(analysisActions.handleChange(e))
  }

  const newItem = () =>
    dispatch(
      analysisActions.setItem(
        JSON.parse(JSON.stringify(analysisInitialState.item))
      )
    );

  const validateItem = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  const resetState = () => {
    dispatch(analysisActions.resetState())
  };

  return {
    item,
    list,
    error,
    savepoint,
    fetchList,
    fetchItem,
    deleteItem,
    createItem,
    updateItem,
    onChange,
    newItem,
    resetState,
  };
};
