import { LowerCI, UpperCI, CI } from "../hooks/precipFrequencyAPI";

function sortMapKnownKeysToStart(keysToMoveToStart) {
    return function([key1, value1], [key2, value2]) {
      const key1Index = keysToMoveToStart.indexOf(key1);
      const key2Index = keysToMoveToStart.indexOf(key2);
  
      if (key1Index !== -1 && key2Index !== -1) {
        return key2Index - key1Index;
      } else if (key1Index !== -1) {
        return -1;
      } else if (key2Index !== -1) {
        return 1;
      } else {
        try {
            return key1.localeCompare(key2);
        } catch (error) {
            return key1 - key2;
        }
      }
    }
  }

export function shadeBetweenConfidenceTraces(seriesMap) {
    if(seriesMap.has(LowerCI) && seriesMap.has(UpperCI)) {
      seriesMap = new Map([...seriesMap.entries()].sort(sortMapKnownKeysToStart([UpperCI, LowerCI])));
      seriesMap.get(UpperCI)["fill"] = "tonexty";
      seriesMap.get(UpperCI)["fillcolor"] = "rgba(173, 216, 230,0.4)";
      seriesMap.get(UpperCI)["showlegend"] = true;
      seriesMap.get(UpperCI)["mode"] = "lines";
      seriesMap.get(UpperCI)["name"] = CI;
      seriesMap.get(UpperCI)["line"] = {color: "transparent"};
      // seriesMap.get(UpperCI)["line"] = {color: "blue"};
      seriesMap.get(LowerCI)["mode"] = "lines";
      seriesMap.get(LowerCI)["line"] = {color: "transparent"};
      // seriesMap.get(LowerCI)["line"] = {color: "blue"};
      seriesMap.get(LowerCI)["showlegend"] = false;
    }
    return seriesMap
}