export const SET_COMPONENT_TYPES = "SET_DOMAIN_COMPONENT_TYPES";
export const SET_CURVE_TYPES = "SET_DOMAIN_CURVE_TYPES";
export const SET_DAYS = "SET_DOMAIN_DAYS";
export const SET_DURATIONS = "SET_DOMAIN_DURATIONS";
export const SET_EXEC_DURATIONS = "SET_DOMAIN_EXEC_DURATIONS";
export const SET_FREQUENCY_TYPES = "SET_DOMAIN_FREQUENCY_TYPES";
export const SET_HOURS = "SET_DOMAIN_HOURS";
export const SET_IMPORTANCE_DISTRIBUTIONS =
  "SET_DOMAIN_IMPORTANCE_DISTRIBUTIONS";
export const SET_MINUTES = "SET_DOMAIN_MINUTES";
export const SET_SPATIAL_TYPES = "SET_DOMAIN_SPATIAL_TYPES";
export const SET_STATISTIC_TYPES = "SET_DOMAIN_STATISTIC_TYPES";
export const SET_TEMPLATE_SCALING_TYPES = "SET_DOMAIN_TEMPLATE_SCALING_TYPES";
export const SET_UNIT_SYSTEMS = "SET_DOMAIN_UNIT_SYSTEMS";
export const SET_VARIABLE_STATISTICS = "SET_DOMAIN_VARIABLE_STATISTICS";
export const SET_DISTRIBUTION_TYPES = "SET_DISTRIBUTION_TYPES";
export const SET_LIST_ERROR = "SET_DOMAIN_LIST_ERROR";
export const SET_ROLES = "SET_ROLES";
export const SET_NODE_LEVELS = "SET_NODE_LEVELS"
export const SET_MODEL_SAVE_OPTIONS = "SET_MODEL_SAVE_OPTIONS";
export const SET_SAMPLE_TYPE_FREQUENCY_TYPES = "SET_SAMPLE_TYPE_FREQUENCY_TYPES";

export const domainListActions = {
  setComponentTypes(list) {
    return {
      type: SET_COMPONENT_TYPES,
      list: list,
    };
  },
  setCurveTypes(list) {
    return {
      type: SET_CURVE_TYPES,
      list: list,
    };
  },
  setDays(list) {
    return {
      type: SET_DAYS,
      list: list,
    };
  },
  setDistributionTypes(list) {
    return {
      type: SET_DISTRIBUTION_TYPES,
      list: list,
    };
  },
  setRoles(roles) {
    return {
      type: SET_ROLES,
      list: roles,
    };
  },
  setDurations(list) {
    return {
      type: SET_DURATIONS,
      list: list,
    };
  },
  setExecDurations(list) {
    return {
      type: SET_EXEC_DURATIONS,
      list: list,
    };
  },
  setFrequencyTypes(list) {
    return {
      type: SET_FREQUENCY_TYPES,
      list: list,
    };
  },
  setHours(list) {
    return {
      type: SET_HOURS,
      list: list,
    };
  },
  setImportanceDistributions(list) {
    return {
      type: SET_IMPORTANCE_DISTRIBUTIONS,
      list: list,
    };
  },
  setMinutes(list) {
    return {
      type: SET_MINUTES,
      list: list,
    };
  },
  setSpatialTypes(list) {
    return {
      type: SET_SPATIAL_TYPES,
      list: list,
    };
  },
  setStatisticTypes(list) {
    return {
      type: SET_STATISTIC_TYPES,
      list: list,
    };
  },
  setTemplateScalingTypes(list) {
    return {
      type: SET_TEMPLATE_SCALING_TYPES,
      list: list,
    };
  },
  setUnitSystems(list) {
    return {
      type: SET_UNIT_SYSTEMS,
      list: list,
    };
  },
  setVariableStatistics(list) {
    return {
      type: SET_VARIABLE_STATISTICS,
      list: list,
    };
  },
  setNodeLevels(list){
    return {
      type: SET_NODE_LEVELS,
      list: list
    }
  },
  setModelSaveOptions(list) {
    return {
      type: SET_MODEL_SAVE_OPTIONS,
      list: list
    }
  },
  setSampleTypeFrequencyTypes(list) {
    return {
      type: SET_SAMPLE_TYPE_FREQUENCY_TYPES,
      list: list
    }
  },
  setListError(error) {
    return {
      type: SET_LIST_ERROR,
      error: error,
    };
  },
};

export const domainListReducer = (state, action) => {
  switch (action.type) {
    case SET_COMPONENT_TYPES:
      return {
        ...state,
        componentTypes: action.list,
      };
    case SET_CURVE_TYPES:
      return {
        ...state,
        curveTypes: action.list,
      };
    case SET_DAYS:
      return {
        ...state,
        days: action.list,
      };
    case SET_DURATIONS:
      return {
        ...state,
        durations: action.list,
      };
    case SET_EXEC_DURATIONS:
      return {
        ...state,
        execDurations: action.list,
      };
    case SET_FREQUENCY_TYPES:
      return {
        ...state,
        frequencyTypes: action.list,
      };
    case SET_HOURS:
      return {
        ...state,
        hours: action.list,
      };
    case SET_IMPORTANCE_DISTRIBUTIONS:
      return {
        ...state,
        importanceDistributions: action.list,
      };
    case SET_MINUTES:
      return {
        ...state,
        minutes: action.list,
      };
    case SET_SPATIAL_TYPES:
      return {
        ...state,
        spatialTypes: action.list,
      };
    case SET_STATISTIC_TYPES:
      return {
        ...state,
        statisticTypes: action.list,
      };
    case SET_TEMPLATE_SCALING_TYPES:
      return {
        ...state,
        templateScalingTypes: action.list,
      };
    case SET_VARIABLE_STATISTICS:
      return {
        ...state,
        variableStatistics: action.list,
      };
    case SET_UNIT_SYSTEMS:
      return {
        ...state,
        unitSystems: action.list,
      };
    case SET_DISTRIBUTION_TYPES:
      return {
        ...state,
        distributionTypes: action.list,
      };
    case SET_ROLES:
      return {
        ...state,
        roles: action.list,
      };
    case SET_NODE_LEVELS:
      return {
        ...state,
        nodeLevels: action.list
      }
    case SET_MODEL_SAVE_OPTIONS:
      return {
        ...state,
        modelSaveOptions: action.list
      }
    case SET_SAMPLE_TYPE_FREQUENCY_TYPES:
      return {
        ...state,
        sampleTypeFrequencyTypes: action.list
      }
    case SET_LIST_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export const domainListInitialState = {
  componentTypes: [],
  curveTypes: [],
  days: [],
  durations: [],
  execDurations: [],
  frequencyTypes: [],
  hours: [],
  importanceDistributions: [],
  minutes: [],
  spatialTypes: [],
  statisticTypes: [],
  templateScalingTypes: [],
  variableStatistics: [],
  unitSystems: [],
  distributionTypes: [],
  roles: [],
  nodeLevels:[],
  modelSaveOptions: [],
  sampleTypeFrequencyTypes: [],
  error: null,
};
