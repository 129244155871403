import React, {useEffect} from 'react';
import {Outlet, useParams} from "react-router-dom";
import {Container, Row, Col} from 'reactstrap';
import {AnalysisMenu} from "./AnalysisMenu";
import {useDomainListAPI} from "../hooks/domainListAPI";
import {useAnalysisAPI} from "../hooks/analysisAPI";

export const Analysis = (props) => {
    const {analysisId} = useParams();
    const {fetchLists} = useDomainListAPI();
    const {savepoint, newItem, fetchItem} = useAnalysisAPI();

    useEffect(() => {
        fetchLists();
        if (analysisId === "new") {
            newItem();
        } else {
            fetchItem(analysisId);
        }
        // eslint-disable-next-line
    }, [analysisId]);

    return (
        <Container className="pt-5 pb-5">
            <Row>
                <Col md="12" className="pt-2 pb-2">
                    <h2>Analysis: {
                        savepoint.id ?
                        savepoint.name : "New Analysis"
                    }
                    </h2>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col md="12">
                    <AnalysisMenu />                    
                </Col>
            </Row>
            <Outlet />
        </Container>
    )
};