import React, { useContext } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { ButtonNavigation } from "./ButtonNavigation";
import { SamplingList } from "./SamplingList";
import { StateStore } from "../store/GlobalStore";

const webRoot = process.env.REACT_APP_WEB_CONTEXT === "/" ? "" : process.env.REACT_APP_WEB_CONTEXT

export const Sampling = () => {
  const { state } = useContext(StateStore);
  const analysis = state.analysis.savepoint;

  return (
    <div>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <div className="form">
                <fieldset>
                  <legend>Sampling</legend>
                  <SamplingList analysisId={analysis.id} />
                </fieldset>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ButtonNavigation
        prevLink={`${webRoot}/analysis/${analysis.id}/precipfrequency`}
        prevLinkTooltip="Go back to Precipitation Frequency tab"
        nextLink={`${webRoot}/analysis/${analysis.id}/model`}
        nextLinkTooltip="Go to HEC-HMS Model tab"
      />
    </div>
  );
};
