import "leaflet/dist/leaflet.css";
import "../css/Map.css";
import React, { useState, useEffect, useMemo } from "react";
// import AepChart from "./AepReactChart";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload, faTable } from "@fortawesome/free-solid-svg-icons";
import { usePrecipFrequencyAPI } from "../hooks/precipFrequencyAPI";
import { BootstrapParameters } from "./BootstrapParams";
import { NumericFormat } from "react-number-format";
import { useLoading } from "../hooks/Loading";
import Loader from "./Loader";
import { EditTable } from "./EditTable";
import { Modal, ModalBody, Button } from "reactstrap";
import { NoaaA14GisMap } from "./NoaaA14GisMap"
import isEqual from 'lodash.isequal'

export const NoaaA14GisData = ({ precipFrequencyId }) => {
  const [isLoading, load] = useLoading();
  const {
    item,
    onA14Change,
    onChange,
    shapeFileUpload,
    availableA14,
    onA14DurationChange,
    // transformDataToSeries,
    error,
    onUseArfListCheckboxChange,
    addArf,
    editArf,
    deleteArf,
  } = usePrecipFrequencyAPI();
  // eslint-disable-next-line

  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState({ durations: [] });
  const [basin, setBasin] = useState(null)
  const [gdata, setData] = useState(item.arealReductionFactorList)
  const toast = (msg, msgClass) => {
    const el = document.getElementById("toast");
    el.innerHTML = msg;
    el.className = el.className.replace("invisible", "visible " + msgClass);
    setTimeout(() => {
      el.className = el.className.replace("visible " + msgClass, "invisible");
    }, 5000);
  };
  const uploadFile = async (e) => {
    if (e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const validFileTypes = [
        "application/x-zip",
        "application/zip",
        "application/x-zip-compressed",
        "application/octet-stream",
      ];
      if (validFileTypes.indexOf(selectedFile.type) === -1) {
        toast("File type must be ZIP.", "text-danger");
      } else if (selectedFile.size > 2000000000) {
        toast("Maximum file size is 20 megabytes.", "text-danger");
      } else {
        await load(shapeFileUpload(selectedFile));
        if (!error) {
          toast("File has been uploaded.", "text-success");
        }
      }
      e.target.value = null;
    }
  };

  const onRegionChange = (e) => {
    const index = e.target.selectedIndex;
    const region = regions[index - 1];
    setSelectedRegion(region);
    onA14Change(e);
  };
  const onDurationChange = (e) => {
    e.persist();
    const { value } = e.target;
    let parts = value.split("|");
    onA14DurationChange({
      duration: parseInt(parts[0]),
      unit: parts[1],
    });
  };

  const columns = [
    {
      name: "probability",
      label: "Annual Exceedance Probability",
    },
    {
      name: "arealReductionFactor",
      label: "Areal Reduction Factor",
      min: 0,
      step: 1,
    },
  ];
  const emptyRow = { arealReductionFactor: "", probability: "" };

  useEffect(() => {
    availableA14().then((r) => {
      setRegions(r);
      if (item.a14 && item.a14.region) {
        let reg = r.find((re) => re.name === item.a14.region);
        setSelectedRegion(reg);
      }
    });
    // eslint-disable-next-line
  }, [precipFrequencyId]);

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const closeModal = () => {
    setModal(false);
  };
  const modalStyle = {
    border: "0",
    borderRadius: "4px",
    bottom: "auto",
    minHeight: "10rem",
    left: "50%",
    padding: "2rem",
    position: "fixed",
    right: "auto",
    top: "50%",
    height: "50%",
    transform: "translate(-50%,-50%)",
    minWidth: "10rem",
    width: "50%",
    maxWidth: "40rem",
  };

  // TODO: when loader is fixed for asynchronous operations, then set basin in uploadFile()
  // instead of comparing item.a14.feature and basin
  useEffect(() => {
    if (!isEqual(basin, item.a14.feature)) {
      setBasin(item.a14.feature)
    }
  }, [basin, item.a14.feature])

  useEffect(() => {
    setData(item.arealReductionFactorList)
  }, [item.arealReductionFactorList])

  const Map = useMemo(() => {
    return <NoaaA14GisMap basin={basin}/>
  }, [basin])

  return (
    <React.Fragment>
      <Row className="mt-3">
        <Col md="12">
          <legend>NOAA A14 GIS Data</legend>
          <Row className="mt-3 mb-3">
            <Col md="6">
              <Row>
                <Col md="12" className="mb-1">
                  Area shapefile (.zip) (20 Mb Size Limit)
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <label htmlFor="upload" className="custom-upload w-100">
                    <FontAwesomeIcon icon={faFileUpload} className="me-2" />{" "}
                    Select file to upload
                  </label>
                  <Input id="upload" type="file" onChange={uploadFile} />
                </Col>
                <Col md="6" className="mt-1">
                  <span id="toast" className="invisible"></span>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup className="mt-3">
                    <Label for="region">Region</Label>
                    <select
                      className="form-control"
                      name="region"
                      value={item.a14.region}
                      onChange={onRegionChange}
                    >
                      <option value="" />
                      {regions &&
                        regions.map((r, index) => (
                          <option key={index} value={r.name}>
                            {`${r.displayName}`}
                          </option>
                        ))}
                    </select>
                    {item.errors.a14.region.length > 0 && (
                      <span className="text-danger">
                        <b>{item.errors.a14.region}</b>
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mt-3">
                    <Label for="duration">Duration</Label>
                    <select
                      type="number"
                      className="form-control"
                      name="duration"
                      value={
                        item.duration &&
                        item.duration.toString() +
                          "|" +
                          item.durationUnit.toString()
                      }
                      onChange={onDurationChange}
                    >
                      <option value="" />
                      {selectedRegion &&
                        selectedRegion.durations.map((d, index) => (
                          <option key={index} value={d.duration + "|" + d.unit}>
                            {d.duration} {d.unit}
                          </option>
                        ))}
                    </select>
                    {item.errors.duration.length > 0 && (
                      <span className="text-danger">
                        <b>{item.errors.duration}</b>
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup className="mt-3">
                    <Label>Spatial type</Label>
                    <Input
                      type="text"
                      className="form-control"
                      name="spatialType"
                      readOnly="readOnly"
                      value={item.spatialType.toString()}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <Label>
                    Areal Reduction Factor &nbsp;&nbsp;
                    <Input
                      name="useArealReductionFactorList"
                      type="checkbox"
                      checked={item.useArealReductionFactorList}
                      onChange={(e) => {
                        if (!item.useArealReductionFactorList) {
                          toggle();
                        }
                        onUseArfListCheckboxChange(e);
                      }}
                    />
                    &nbsp; Use Multiple
                  </Label>
                  <Row>
                    {!item.useArealReductionFactorList && (
                      <FormGroup>
                        <NumericFormat
                          className="form-control mt-3"
                          name="arealReductionFactor"
                          onChange={onChange}
                          value={item.arealReductionFactor?.toString()}
                          decimalScale={2}
                          isAllowed={(values) => {
                            const { formattedValue, floatValue } = values;
                            if (floatValue == null) {
                              return formattedValue === "";
                            } else {
                              return floatValue <= 1 && floatValue >= 0;
                            }
                          }}
                          disabled={item.useArealReductionFactorList}
                        />
                        {item.errors.arealReductionFactor.length > 0 && (
                          <span className="text-danger">
                            <b>{item.errors.arealReductionFactor}</b>
                          </span>
                        )}
                      </FormGroup>
                    )}
                    {item.useArealReductionFactorList && ( 
                      <Col md="11">
                      <Button
                        color="light"
                        size="sm"
                        className="custom-upload w-100 mt-2"
                        onClick={toggle}
                      >
                        <FontAwesomeIcon icon={faTable} className="me-2" />
                        <span>Edit Multiple ARFS</span>
                      </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
              {item.useArealReductionFactorList && (
                <Row>
                  <Col md="12">
                    <Modal
                      isOpen={modal}
                      toggle={toggle}
                      style={modalStyle}
                      scrollable={true}
                    >
                      <ModalBody>
                        <EditTable
                          title="Multiple Areal Reduction Factors"
                          gdata={gdata}
                          columns={columns}
                          emptyRowObj={emptyRow}
                          onAdd={addArf}
                          onEdit={editArf}
                          onDelete={deleteArf}
                        />
                        <Button
                          color="dark"
                          size="sm"
                          className="me-2"
                          onClick={closeModal}
                          style={{ marginTop: "10px" }}
                        >
                          <span>Close</span>
                        </Button>
                      </ModalBody>
                    </Modal>
                  </Col>
                </Row>
              )}
            </Col>
            <Col md="6">
              {Map}
            </Col>
          </Row>
          <BootstrapParameters readOnly />
        </Col>
      </Row>
      <Loader loading={isLoading} />
    </React.Fragment>
  );
};
