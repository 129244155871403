import { useState } from "react";

export const useLoading = () => {
  const [isLoading, setState] = useState(false);
  const load = async (aPromise) => {
    setState(true);
    const p = Promise.resolve(aPromise);
    try {
      try {
        const args = await p;
        return args;
      } catch (args_1) {
        return args_1;
      }
    } finally {
      setState(false);
    }
  };
  const sleep = (milliseconds) => new Promise((res) => setTimeout(() => res(true), milliseconds))
  return [isLoading, load, sleep];
};
