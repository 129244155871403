import React, { useContext, useEffect, useState } from "react";
import {
  createColumnHelper
} from '@tanstack/react-table';
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { ListTable } from "./ListTable";
import { ActionModal } from "./ActionModal";
import { StateStore } from "../store/GlobalStore";
import { useAnalysisAPI } from "../hooks/analysisAPI";
import { useLoading } from "../hooks/Loading";
import Loader from "./Loader";

const webRoot = process.env.REACT_APP_WEB_CONTEXT === "/" ? "" : process.env.REACT_APP_WEB_CONTEXT;

export const AnalysisList = () => {
  const [isLoading, load] = useLoading();
  const { list, error, newItem, deleteItem, fetchList } = useAnalysisAPI();
  const { state } = useContext(StateStore);
  const { username } = state.user;
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [itemToDelete, setItemToDelete] = useState();
  


  const promptToDelete = (row) => {
    setItemToDelete(row.original.id);
    setModalShow(!modalShow);
  };

  const callDelete = async () => {
    await load(deleteItem(itemToDelete));
    
    if (!error) {
      setModalShow(!modalShow);
    }
  };
  useEffect(() => {
    load(fetchList());
    // eslint-disable-next-line
  }, [username]);
  useEffect(() => {
    newItem()
    // eslint-disable-next-line
  }, [])
  // console.log(list);
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => (
        <Link to={`${webRoot}/analysis/${info.row.original.id}/general`} title={"Edit analysis"}>
          {info.getValue()}
        </Link>
      ),
      header: 'Name',
    }),
    columnHelper.accessor('description', {
      header: 'Description',
    }),
    columnHelper.accessor('units', {
      header: 'Units System',
    }),
    columnHelper.accessor('ownedBy', {
      header: 'Owner',
    }),
    columnHelper.accessor('id', {
      cell: (info) => (
        <button className="hyperlinkButton float-end" title="Delete analysis" onClick={() => promptToDelete(info.row)}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      ),
      header: '',
    }),
  ];

  return (
    <React.Fragment>
      <Container className="pt-5 pb-5">
        <Row>
          <Col md="12" className="pt-2">
            <h2>Analyses</h2>
          </Col>
        </Row>
        <Row>
          <Col md="12 text-end" className="mb-2">
            <button
              className="btn btn-default btn-sm"
              title="Create a new analysis"
              onClick={() => navigate(`${webRoot}/analysis/new/general`)}
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              <span>New Analysis</span>
            </button>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <ListTable data={list} columns={columns} />

            {/* <ReactTable
              data={list}
              columns={columns}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id])
                  .toLocaleLowerCase()
                  .includes(filter.value.toLocaleLowerCase())
              }
              defaultPageSize={10}
            /> */}
          </Col>
        </Row>
      </Container>
      <ActionModal
        isOpen={modalShow}
        toggle={() => setModalShow(!modalShow)}
        header={"Delete Analysis"}
        body={
          "Are you sure you wish to continue?  All data for the analysis will be deleted from the database."
        }
        action={() => callDelete()}
      />
      <Loader loading={isLoading} />
    </React.Fragment>
  );
};
