import React, { useEffect, useState } from 'react'
import { SSEProvider } from "react-hooks-sse";
import { useSSE } from "react-hooks-sse";
import { events } from "../services/api";
import { eventRoot } from "../services/api";
import { useExecuteSampledAPI } from "../hooks/executeSampledAPI";

const Status = ({updateListFunction}) => {
  const status = useSSE(
    events.Promoted,
    {
      value: { id: "", status: "" }
    },
    {
      stateReducer(state, changes) {
        // console.log(changes);
        let newState = {
          ...state,
          value: changes.data,
        };
        return newState;
      },
      parser(input) {
        return JSON.parse(input);
      },
    }
  );
  useEffect(() => {
    if (status.value.status !== "") {
      updateListFunction(status.value)
    }
  // eslint-disable-next-line 
  }, [status.value.status])
}

export const AllExecutionsSSEProvider = () => {
  const {
    sampledActiveList,
    updateListStatus,
  } = useExecuteSampledAPI();
  const [sseProviders, setSSEProviders] = useState([]);

  useEffect(() => {
    // When the Execution component mounts, create SSEProvider components for all actively running execution IDs. Runs once
    const initialExecutionIds = sampledActiveList.map((exec) => exec.id);
    const initialSSEProviders = initialExecutionIds.map((id) => (
      <SSEProvider
        key={id}
        executionId={id}
        endpoint={`${eventRoot}/executions/${id}/start`}
      >
        <Status execution={id} updateListFunction={updateListStatus} />
      </SSEProvider>
    ));
    setSSEProviders(initialSSEProviders);
  // eslint-disable-next-line
  }, []); 

  useEffect(() => {
     // When a new execution ID is added, create a new SSEProvider component for it
    const handleExecutionAdded = (id) => {
      const newSSEProvider = (
        <SSEProvider
          key={id}
          executionId={id}
          endpoint={`${eventRoot}/executions/${id}/start`}
        >
          <Status execution={id} updateListFunction={updateListStatus} />
        </SSEProvider>
      );
      setSSEProviders((prevSSEProviders) => prevSSEProviders.concat(newSSEProvider));
    };
    // Determine if a new execution SSEProvider needs to be added
    const newExecutionIds = sampledActiveList.map((exec) => exec.id);
    const existingExecutionIds = sseProviders.map((s) => s.props.executionId);
    const addedExecutionIds = newExecutionIds.filter(
      (id) => !existingExecutionIds.includes(id)
    );
    addedExecutionIds.forEach((id) => handleExecutionAdded(id));
    // TODO: Remove SSEProvider where sampledItem.id === execution id? 
    // Current state at any time has two duplicative SSEProviders due to ProgressBar need
  // eslint-disable-next-line
  }, [sampledActiveList, sseProviders]);

  return <>{sseProviders}</>;
};
