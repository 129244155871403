import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export const InfoModal = (props) => {
  const actionOnClose = props.actionOnClose;
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} centered>
      <ModalHeader toggle={props.toggle}>{props.header}</ModalHeader>
      <ModalBody>{props.body}</ModalBody>
      <ModalFooter>
        {actionOnClose ? (
          <Button color="primary" size="sm" onClick={props.actionOnClose}>
            OK
          </Button>
        ) : (
          <Button color="primary" size="sm" onClick={props.toggle}>
            OK
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
