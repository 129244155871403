import React, { useState, useEffect } from 'react';
import {
    createColumnHelper
} from '@tanstack/react-table';
import { 
    Row, 
    Col, 
    Button 
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faPlus, 
    faTrashAlt 
} from '@fortawesome/free-solid-svg-icons';
import { ListTable } from "./ListTable";
import { ActionModal } from './ActionModal';
import { ExecuteSampledForm } from './ExecuteSampledForm';
import { useExecuteSampledAPI } from "../hooks/executeSampledAPI";
import { useLoading } from "../hooks/Loading";
import Loader from "./Loader";
import isEqual from "lodash.isequal"
import { Prompt } from './Prompt';

export const ExecuteSampledList = ({ analysisId }) => {
    const [isLoading, load] = useLoading();
    const {
      sampledItem,
      sampledList,
      sampledError,
      savepoint,
      fetchList,
      deleteItem,
      fetchItem,
      newItem,
      resetState,
    } = useExecuteSampledAPI();
    const [ modalShow, setModalShow ] = useState(false);
    const [ itemToDelete, setItemToDelete ] = useState();
    const [ hasMounted, setHasMounted ] = useState(false);
    const promptTitle = "Unsaved Changes"
    const promptMessage = "Are you sure you wish to leave without saving?  All unsaved data from the Execute page will be deleted."
    const promptToDelete = (row) => {
        setItemToDelete(row.original.id);
        setModalShow(!modalShow);
    };
    const callDelete = async() => {
        await load(deleteItem(itemToDelete));
        if (!sampledError) {
            setModalShow(!modalShow);
            await load(fetchList(analysisId));
            newItem();
        }
    };
    const callFetch = async(row) => {
        if (row.original.id) {
            await load(fetchItem(row.original.id));
        }
    };
    const cleanItem = (item) => {
      if (item == null) return null
      const newItem = JSON.parse(JSON.stringify(item))
      delete newItem.startTime
      delete newItem.status
      delete newItem.statusUpdatedAt
      return newItem
    }
    useEffect(() => {
        if (analysisId) {
            newItem();
            load(fetchList(analysisId));
        }
        // eslint-disable-next-line
    }, [analysisId]);
    useEffect(() => {
        newItem()
        setHasMounted(true)
        return () => resetState()
        // eslint-disable-next-line
    }, []);

    const columnHelper = createColumnHelper();
    const columns = [
      columnHelper.accessor("name", {
        cell: (info) => (
          <button
            className="hyperlinkButton"
            title="View execution configuration"
            onClick={() => callFetch(info.row)}
          >
            {info.getValue()}
          </button>
        ),
        header: "Name",
      }),
      columnHelper.accessor("description", {
        header: "Description",
      }),
      columnHelper.accessor("modelName", {
        header: "Model",
      }),
      columnHelper.accessor("sampleName", {
        header: "Sampling",
      }),
      columnHelper.accessor("templateScalingType", {
        header: "Storm template scaling",
      }),
      columnHelper.accessor("status", {
        header: "Status",
      }),
      columnHelper.accessor("statusUpdatedAt", {
        cell: (info) => (
          <span>
            {info.getValue() !== null
              ? new Date(info.getValue()).toLocaleString()
              : info.getValue()}
          </span>
        ),
        header: "Status updated at",
      }),
      columnHelper.accessor("id", {
        cell: (info) => (
          <button
            className="hyperlinkButton"
            title="Delete execution configuration"
            onClick={() => promptToDelete(info.row)}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        ),
        header: "",
      }),
    ];

    // const sampledListExists = sampledList && sampledList.length > 0;
    return (
        <React.Fragment>
            <Row>
                <Col md="12" className="mb-2 text-end">
                    <Button color="default" size="sm" title="Add a new execution configuration" onClick={newItem}>
                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                        <span>Add New</span>
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                <ListTable data={sampledList} columns={columns} />
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    {hasMounted && (<ExecuteSampledForm analysisId={analysisId} />)}
                </Col>
            </Row>
            <ActionModal 
                isOpen={modalShow}
                toggle={() => setModalShow(!modalShow)} 
                header={'Delete Execution'} 
                body={'Are you sure you wish to continue?  All configuration and execution data will be deleted from the database.'} 
                action={callDelete}
            />
            <Loader loading={isLoading} />
            <Prompt header={promptTitle} message={promptMessage} shouldPrompt={!isEqual(cleanItem(savepoint), cleanItem(sampledItem))} />
        </React.Fragment>
    );
}