import React from 'react';
import { Link } from "react-router-dom";
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const webRoot = process.env.REACT_APP_WEB_CONTEXT === "/" ? "" : process.env.REACT_APP_WEB_CONTEXT

export const ButtonNavigation = (props) => {

  return (
    <Row className="mt-2">
      <Col md="6">
        <Link className="btn btn-sm btn-default" to={`${webRoot}/analysisList`} title="Go back to Analyses list">
          <FontAwesomeIcon icon={faAngleDoubleLeft} className="me-2" />
          <span>Back to list</span>
        </Link>
      </Col>
      <Col md="6" className="text-end">
        {props.prevLink &&
          <Link className="btn btn-sm btn-default" to={props.prevLink} title={props.prevLinkTooltip}>
            <FontAwesomeIcon icon={faAngleLeft} className="me-2" />
            <span>Back</span>
          </Link>}
        {props.nextLink &&
          <Link className="btn btn-sm btn-default" to={props.nextLink} title={props.nextLinkTooltip}>
            <span>Next</span>
            <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
          </Link>}
      </Col>
    </Row>
  );
}