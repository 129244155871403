import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Row, Col, FormGroup, Label, Input, Button, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faSave, faCheck } from '@fortawesome/free-solid-svg-icons';
import { AepChart } from "./AepPlotlyChart";
import { useDomainListAPI } from '../hooks/domainListAPI';
import { useSamplingAPI } from '../hooks/samplingAPI';
import { StateStore } from "../store/GlobalStore";
import { useLoading } from "../hooks/Loading";
import Loader from "./Loader";
import {Stratified, Quantile, FullUncertainty} from '../reducers/samplingReducer'
import { SamplingStratified } from "./SamplingStratified";
import { SamplingFullUncertainty } from "./SamplingFullUncertainty";
import { checkErrors } from "../util/validation";
import { BootstrapParametersView } from './BootstrapParamsView';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { NumericFormat } from "react-number-format";
import { HistChart } from "./HistChart"

export const SamplingForm = ({ analysisId }) => {
    // eslint-disable-next-line
    const [isLoading, load, sleep] = useLoading();
    const { state } = useContext(StateStore);
    const analysis = state.analysis.savepoint;
    const sampling = state.sampling;
    const { sampleTypeFrequencyTypes } = useDomainListAPI();
    const {
        analysisCurves,
        item,
        list,
        error,
        binsError,
        bootstrap,
        fetchAnalysisCurves,
        fetchValidFrequencies,
        fetchBins,
        transformDataToSeriesPlotly,
        createItem,
        updateItem,
        newItem,
        onChange,
        fetchList,
        setList,
        setAnalysisCurves,
        fetchValidSampleTypes,
        fetchBootstrapForSample,
        onSampleTypeChange,
        onFrequencyIdChange,
        setAep,
    } = useSamplingAPI();
    const [modalShow, setModalShow] = useState(false);
    const [saveAsModalShow, setSaveAsModalShow] = useState(false);
    const [newName, setNewName] = useState('');
    const [savedName, setSavedName] = useState('');
    const [saveAsError, setSaveAsError] = useState('');
    const [createdSamples, setCreatedSamples] = useState(false);
    const callFetchBins = async () => {
        await load(fetchBins());
    };
    const isValid = useMemo(() => checkErrors(item.errors), [item.errors]);

    // eslint-disable-next-line
    const chartData = useMemo(
        () => transformDataToSeriesPlotly(item.bins, item.realizations, item.sampleTypeName),
        // eslint-disable-next-line
        [item.bins, item.sampleTypeName]
    );

    const FullUncertaintyChart = useMemo(
        () => (
            <AepChart
            yAxisText={"Precipitation Depth (inches)"}
            dataSeries={chartData}
            decimalDigitsGeneral={analysis.decimalDigitsGeneral}
            decimalDigitsAep={analysis.decimalDigitsAep}
            allowChangeTraceMode={true}
        />
        ), [chartData, analysis.decimalDigitsGeneral, analysis.decimalDigitsAep]
    );

    const validSampleTypes = useMemo(
        () => fetchValidSampleTypes(analysisCurves, sampleTypeFrequencyTypes),
        // eslint-disable-next-line
        [analysisCurves, sampleTypeFrequencyTypes]
    );
    const validFrequencies = useMemo(
        () => fetchValidFrequencies(analysisCurves, item.sampleTypeName, sampleTypeFrequencyTypes),
        // eslint-disable-next-line
        [analysisCurves, item.sampleTypeName, sampleTypeFrequencyTypes]
      );
    const save = async () => {
        if (item.id) {
            await load(updateItem(item.id));
            if (!error) {
                setModalShow(true);
                load(fetchList(analysisId));
                await sleep(2000)
                setModalShow(false);
                load(fetchList(analysisId));
                fetchBootstrapForSample(item.frequencyId);
            }
        }
        else {
            await load(createItem());
            if (!error) {
                setModalShow(true);
                load(fetchList(analysisId));
                await sleep(2000)
                setModalShow(false);
                load(fetchList(analysisId));
                fetchBootstrapForSample(item.frequencyId);
            }
        }
    };
    const changeName = (e) => {
        e.persist();
        setSaveAsError('');
        setNewName(e.target.value);
    };
    const saveAs = async () => {
        setSaveAsError('');
        if (newName === "" || list.some(item => item.name === newName)) {
            setSaveAsError('Please enter a new name.');
          }
        else {
            await load(createItem(newName));
            if (!error) {
                setSavedName(newName.toString());
                setSaveAsModalShow(true);
                load(fetchList(analysisId));
                await sleep(2000)
                setSaveAsModalShow(false);
                setNewName('');
                setSaveAsError('');
            }
            else {
                setSaveAsError(error);
            }
        }
    };
    useEffect(() => {
        if (analysisId) {
            fetchAnalysisCurves(analysisId);
        } else {
            setList([])
            setAnalysisCurves([])
        }
        // eslint-disable-next-line
    }, [analysisId]);
    useEffect(() => {
        if (item.frequencyId) {
            fetchBootstrapForSample(item.frequencyId)
            setCreatedSamples(true);
        }
        // eslint-disable-next-line
    }, [item.frequencyId]);
    useEffect(() => {
        if (item.sampleTypeName === 'Quantile' && !item.id) {
           setAep(); 
        }
        setCreatedSamples(false);
      // eslint-disable-next-line
      }, [item.frequencyId, item.sampleTypeName])
    useEffect(() => {
        newItem();
        setSaveAsError('');
        setCreatedSamples(false);
    // eslint-disable-next-line
    }, []);
    useEffect(() => {
        setNewName('')
        setSaveAsError('')
        }, [item.id])
    
    return (
        <React.Fragment>
            <div className="mt-3">
                <h3 style={{ fontWeight: "normal" }}>Configure Sampling</h3>
                <Row>
                    <Col md="12">
                        <Row>
                            <Col md="6">
                                <FormGroup className="mt-3">
                                        <Label for="sampleType">Sample type</Label>
                                        <select
                                            className="form-control"
                                            name="sampleTypeId"
                                            value={JSON.stringify({name: item.sampleTypeName, id: item.sampleTypeId})}
                                            onChange={onSampleTypeChange}
                                            disabled={item.id}
                                            >
                                            <option value={JSON.stringify({name: "", id: ""})}></option>
                                            {validSampleTypes.map((s) => <option key={s.id} value={JSON.stringify({name: s.name, id: s.id})} disabled={!s.valid}>{s.name}</option>)}
                                        </select>
                                        {
                                            item.errors.sampleTypeId.length > 0 &&
                                            <span className='text-danger'>
                                                {item.errors.sampleTypeId}
                                            </span>
                                        }
                                    </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {item.sampleTypeName &&
                    <Row>
                        <Col md="12">
                            <Row>
                                <Col md="6">
                                    <FormGroup className="mt-3">
                                        <Label for="name">Name</Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter sample name"
                                            name="name"
                                            value={item.name}
                                            onChange={onChange} />
                                        {
                                            item.errors.name.length > 0 &&
                                            <span className='text-danger'>
                                                {item.errors.name}
                                            </span>
                                        }
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mt-3">
                                        <Label for="name">Description</Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter sample description"
                                            name="description"
                                            value={item.description}
                                            onChange={onChange} />
                                        {
                                            item.errors.description.length > 0 &&
                                            <span className='text-danger'>
                                                {item.errors.description}
                                            </span>
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup className="mt-3">
                                        <Label for="frequencyId">Precipitation frequency</Label>
                                        <select
                                            className="form-control"
                                            name="frequencyId"
                                            value={item.frequencyId}
                                            onChange={e => onFrequencyIdChange(e, validFrequencies)}>
                                            <option value=""></option>
                                            {validFrequencies.map((s) => <option key={s.id} value={s.id} disabled={!s.valid}>{s.name}</option>)}
                                        </select>
                                        {
                                            item.errors.frequencyId.length > 0 &&
                                            <span className='text-danger'>
                                                {item.errors.frequencyId}
                                            </span>
                                        }
                                    </FormGroup>
                                </Col>
                                {item.sampleTypeName === Quantile && ( 
                                <Col md="6">
                                    <FormGroup className="mt-3">
                                    <Label for="minBinProb">Probability
                                    <span id={'infoIconRealization'}>
                                        <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                                    </span>
                                    <UncontrolledTooltip target="infoIconRealization" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
                                        <div className="text-start">
                                        <p>
                                        Provide value as exceedance probability (AEP).
                                        </p>
                                        </div>
                                    </UncontrolledTooltip>
                                    </Label>
                                    <NumericFormat
                                    className="form-control"
                                    placeholder="Enter probability"
                                    name="aep"
                                    value={item.aep}
                                    onChange={(e) => setAep(e.target.value)}
                                    decimalScale={analysis.decimalDigitsAep}
                                    isAllowed={(values) => {
                                        const { formattedValue, floatValue } = values;
                                        if (floatValue == null) {
                                        return formattedValue === "";
                                        } else {
                                        return floatValue <= 1 && floatValue >= 0;
                                        }
                                    }}
                                    />
                                    <span className="text-danger">{item.errors.aep}</span>
                                </FormGroup>
                                </Col>
                                )}
                                {item.sampleTypeName === FullUncertainty && ( 
                                    <Col md="6">
                                        <FormGroup className="mt-3">
                                            <Label for="numSamples">Events per bin
                                            <span id={'infoIconEvents'}>
                                                <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                                            </span>
                                            <UncontrolledTooltip target="infoIconEvents" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
                                                <div className="text-start">
                                                <p>
                                                The number of simulated precipitation events per stratification bin. It is recommended to simulate at least 50 events per bin. However, it is preferable to use 100 bins with 100 events each, for a total of 10,000 stochastic flood events.
                                                </p>
                                                </div>
                                            </UncontrolledTooltip>
                                            </Label>
                                            <Input
                                                type="number"
                                                min="1"
                                                max="1000"
                                                step="1"
                                                className="form-control"
                                                placeholder="Enter number of events per bin"
                                                name="numSamples"
                                                value={item.numSamples}
                                                onChange={onChange}
                                            />
                                            <span className="text-danger">{item.errors.numSamples}</span>
                                        </FormGroup>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                }
                { item.sampleTypeName === Stratified &&
                    <SamplingStratified frequencies={validFrequencies}/>
                }
                { item.sampleTypeName === FullUncertainty &&
                    <SamplingFullUncertainty frequencies={validFrequencies}/>
                }
                <Col md="6">
                    {item.sampleTypeName && item.sampleTypeName !== Stratified && bootstrap && !(Object.keys(bootstrap).length === 0) && (
                        <React.Fragment>
                        <Row>
                            <Col md="12">
                                <Label for="bootstrapTable" className="mt-3">Bootstrap Configuration from Precipitation Frequency</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <BootstrapParametersView item={item} sampleType={item.sampleTypeName}/>    
                            </Col>
                        </Row>
                        </React.Fragment>
                    )} 
                </Col>
                <Row>
                    <Col md="12" className="mt-3">
                        <Button onClick={(e) => {callFetchBins(e); setCreatedSamples(true)}} color="dark" size="sm" title="Create samples" className="me-2" disabled={!isValid}>
                            <FontAwesomeIcon icon={faPlay} className="me-2" />
                            <span>Create Samples</span>
                        </Button>
                        {
                            !createdSamples && !item.id &&
                            <span className="text-danger ms-2">
                                Required.
                            </span>
                        }
                        {
                            (item.bins && item.bins.length > 0 && !sampling.binsError ) &&
                            <span className="text-success ms-2">
                                <FontAwesomeIcon icon={faCheck} className="me-2" />Samples created.
                            </span>
                        }
                        {
                            binsError &&
                            <span className="text-danger ms-2">
                                {binsError}
                            </span>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <div className="border shadow-sm mt-3" style={{ width: "100%" }}>
                            {((item.bins && item.bins.length > 0) || (item.realizations && item.realizations.length>0)) ?
                                <div>
                                    {(item.sampleTypeName === Stratified) &&
                                        <AepChart
                                            yAxisText={"Precipitation Depth (inches)"}
                                            dataSeries={chartData}
                                            decimalDigitsGeneral={analysis.decimalDigitsGeneral}
                                            decimalDigitsAep={analysis.decimalDigitsAep}
                                        />
                                    }
                                    { item.sampleTypeName === FullUncertainty ? FullUncertaintyChart : null }
                        
                                    {item.sampleTypeName === Quantile &&
                                        <HistChart
                                            title={"Precipitation Depth at " + item.aep}
                                            yAxisText={"Relative Frequency"}
                                            xAxisText={"Depth (inches)"}
                                            dataSeries={chartData}
                                            decimalDigitsGeneral={analysis.decimalDigitsGeneral}
                                            decimalDigitsAep={analysis.decimalDigitsAep}
                                        />
                                    }
                                </div>
                                :
                                <div className="p-5 text-center">No plot data available.</div>
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className="mt-3">
                        <Button
                            onClick={save}
                            color="dark"
                            size="sm"
                            title="Save"
                            className="me-2"
                            disabled={!isValid}
                        >
                            <FontAwesomeIcon icon={faSave} className="me-2" />
                            <span>Save</span>
                        </Button>
                        <Button
                            color="default"
                            size="sm"
                            title="Reset form"
                            onClick={newItem}
                            className="me-2"
                        >
                            <span>Reset Form</span>
                        </Button>
                        {(modalShow && !error) && (
                            <span className="text-success">
                                <FontAwesomeIcon icon={faCheck} className="me-2" />Sampling saved!
                            </span>
                        )}
                        {error && (
                            <span className="text-danger">
                                <b>{error}</b>
                            </span>
                        )}
                    </Col>
                </Row>
                {item.id &&
                    <Row className="mt-3">
                        <Col className="col-auto pe-2">
                            <Label for="newName">Save as</Label>
                        </Col>
                        <Col className="col-auto pe-2">
                            <Input
                                type="text"
                                name="newName"
                                placeholder="Enter new sampling configuration name"
                                onChange={changeName}
                                style={{ minWidth: '300px' }}
                                value={newName}
                                disabled={saveAsModalShow}
                            />
                        </Col>
                        <Col className="col-auto pe-2">
                            <Button color="dark" size="sm" title="Save As" onClick={saveAs} disabled={saveAsModalShow}>
                                <FontAwesomeIcon icon={faSave} className="me-2" />
                                <span>Save</span>
                            </Button>
                        </Col>
                        {saveAsError !== "" && (
                            <Col className="col-auto">
                                <span className="text-danger">
                                    <b>{saveAsError}</b>
                                </span>
                            </Col>
                        )}
                        {(saveAsModalShow && !saveAsError) && (
                            <Col className="col-auto">
                                <span className="text-success">
                                    <FontAwesomeIcon icon={faCheck} className="me-2" />
                                    {`Sampling saved as: ${savedName}`}
                                </span>
                            </Col>
                        )}
                    </Row>
                }
            </div>
            <Loader loading={isLoading} />
        </React.Fragment>
    );
};
