import "../css/AnalysisMenu.css";
import React from 'react';
import { NavLink } from 'react-router-dom';

export const AnalysisMenu = () => {
    return (
        <div className="arrowmenu mb-2" style={{padding:'0px'}}>
            <NavLink 
                to="general" 
                title="Enter general information about the analysis"
                className={({ isActive }) => isActive ? "active" : ""}>
                <span className="arrowmenu-inner">
                    <span className="arrowmenu-title">General</span>
                </span>    
            </NavLink>
            <NavLink 
                to="precipfrequency" 
                title="Define precipitation frequencies for the analysis"
                className={({ isActive }) => isActive ? "active" : ""}>
                <span className="arrowmenu-inner">
                    <span className="arrowmenu-title">Precipitation Frequency</span>
                </span>    
            </NavLink>
            <NavLink 
                to="sampling"
                title="Define sampling for the analysis"
                className={({ isActive }) => isActive ? "active" : ""}>
                <span className="arrowmenu-inner">
                    <span className="arrowmenu-title">Sampling</span>
                </span>    
            </NavLink>
            <NavLink
                to="model"
                title="Upload and verify HEC-HMS models for the analysis"
                className={({ isActive }) => isActive ? "active" : ""}>
                <span className="arrowmenu-inner">
                    <span className="arrowmenu-title">Model</span>
                </span>
            </NavLink>
            <NavLink 
                to="execute" 
                title="Execute the analysis and view execution progress and summary information"
                className={({ isActive }) => isActive ? "active" : ""}>
                <span className="arrowmenu-inner">
                    <span className="arrowmenu-title">Execute</span>
                </span>
            </NavLink>
            <NavLink 
                to="results" 
                title="View completed execution information and download results"
                className={({ isActive }) => isActive ? "active" : ""}>
                <span className="arrowmenu-inner">
                    <span className="arrowmenu-title">Results</span>
                </span>
            </NavLink>
        </div>
    )
}