import React, { useEffect, useState, useMemo, useContext, useRef } from 'react';
import { Row, Col, FormGroup, Label, Input, Button, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faDownload, faPlay, faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useDomainListAPI } from '../hooks/domainListAPI';
import { useSamplingAPI } from "../hooks/samplingAPI";
import { useModelAPI } from "../hooks/modelAPI";
import { useExecuteSingleAPI } from '../hooks/executeSingleAPI';
import { SSEProvider } from "react-hooks-sse";
import { eventRoot } from "../services/api";
import { ProgressBar } from "./ProgressBar";
import { StateStore } from "../store/GlobalStore";
import { useResultsAPI } from "../hooks/resultsAPI";
import { useLoading } from "../hooks/Loading";
import Loader from "./Loader";
import { AllExecutionsSSEProvider } from "./AllExecutionsSSEProvider";
// const replaceDownload = process.env.REACT_APP_REPLACE_DOWNLOAD_WITH_CONTEXT
// const webRoot = process.env.REACT_APP_WEB_CONTEXT === "/" ? "" : process.env.REACT_APP_WEB_CONTEXT

export const ExecuteSingleForm = ({ analysisId }) => {
  const [isLoading, load, sleep] = useLoading();
  const { state } = useContext(StateStore);
  const analysis = state.analysis.savepoint;
  const { templateScalingTypes } = useDomainListAPI();
  const { downloadSingleExecutionModel } = useResultsAPI();
  const { analysisCurves, fetchFrequencies, fetchCurveTypes, fetchCurves } = useSamplingAPI();
  const {
    singleItem, singleList, singleError, analysisTemplates, updateStatus, newItem, createItem, updateItem,
    executeItem, fetchList, onChange, fetchAnalysisTemplates, fetchModelTemplates
  } = useExecuteSingleAPI();

  const [modalShow, setModalShow] = useState(false);
  const [newName, setNewName] = useState('');
  const [saveAsError, setSaveAsError] = useState('');
  const [disableExecute, setDisableExecute] = useState(false);
  const errorRef = useRef(singleError)
  const fetchModels = useModelAPI().fetchList;
  const models = useModelAPI().list;

  // eslint-disable-next-line
  const modelTemplates = useMemo(() => fetchModelTemplates(analysisTemplates, singleItem.modelId), [analysisTemplates, singleItem.modelId]);
  // eslint-disable-next-line
  const frequencies = useMemo(() => fetchFrequencies(analysisCurves), [analysisCurves]);
  // eslint-disable-next-line
  const curveTypes = useMemo(() => fetchCurveTypes(analysisCurves, singleItem.frequencyId), [analysisCurves, singleItem.frequencyId]);
  // eslint-disable-next-line
  const curves = useMemo(() => fetchCurves(analysisCurves, singleItem.frequencyId, singleItem.curveTypeId), [analysisCurves, singleItem.frequencyId, singleItem.curveTypeId]);

  const decimalDigitsGeneral = analysis.decimalDigitsGeneral
  const decimalDigitsAep = analysis.decimalDigitsAep

  const countDecimals = function (value) {
    const match = (''+value).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) { return 0; }
    return Math.max(
         0,
         // Number of digits right of decimal point.
         (match[1] ? match[1].length : 0)
         // Adjust for scientific notation.
         - (match[2] ? +match[2] : 0));
  }
  const formattedCurves = curves.map((s) => {
    let xValue, yValue

    if (s.probability && countDecimals(s.probability) > decimalDigitsAep) {
      xValue = parseFloat(s.probability.toFixed(decimalDigitsAep))
    } else {
      xValue = s.probability
    }
    if (s.value && countDecimals(s.value) > decimalDigitsGeneral) {
      yValue = parseFloat(s.value.toFixed(decimalDigitsGeneral))
    } else {
      yValue = s.value
    }
    return {
      id: s.id,
      probability: xValue,
      value: yValue
    }
  });
  const save = async () => {
    if (singleItem.id) {
      await load(updateItem(singleItem.id));
    } else {
      await load(createItem());
    }
    if (!errorRef.current) {
      setModalShow(true);
      setDisableExecute(true);
      await load(fetchList(analysisId));
      setDisableExecute(false);
      await sleep(2000)
      setModalShow(false);
    }
  };
  const changeName = (e) => {
    e.persist();
    setSaveAsError('');
    setNewName(e.target.value);
  };
  const saveAs = async () => {
    setSaveAsError('');
    if ((newName === "") || singleList.some(item => item.name === newName.toString())) {
      setSaveAsError('Please enter a new name.');
    }
    else {
      await load(createItem(newName));
      if (!errorRef.current) {
        setModalShow(true);
        setDisableExecute(true);
        await load(fetchList(analysisId));
        setDisableExecute(false);
        await sleep(2000)
        setModalShow(false);
        setNewName("");
        setSaveAsError("");
      }
      else {
        setSaveAsError(singleError);
      }
    }
  };
  const execute = async () => {
    if (singleItem.id) {
      await executeItem(singleItem.id);
    }
  };
  const handleModelResponse = (response) => {
    console.log(response)
  };
  useEffect(() => {
    if (analysisId) {
      load(fetchModels(analysisId));
      load(fetchAnalysisTemplates(analysisId));
    }
    // eslint-disable-next-line
  }, [analysisId]);
  useEffect(() => {
    setNewName('')
    setSaveAsError('')
  }, [singleItem.id])


  return (
    <React.Fragment>
      <div className="mt-3">
        <h3 style={{ fontWeight: "normal" }}>Single Execution Configuration</h3>
        <Row className="p-3">
          <Col md="6" className="shadow-sm pt-1 ps-4 pe-4 pb-4">
            <Row>
              <Col md="12">
                <FormGroup className="mt-3">
                  <Label for="name">Name</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter execution configuration name"
                    name="name"
                    value={singleItem.name}
                    onChange={onChange} />
                  {
                    singleItem.errors.name.length > 0 &&
                    <span className='text-danger'>
                      {singleItem.errors.name}
                    </span>
                  }
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className="mt-3">
                  <Label for="description">Description</Label>
                  <Input
                    type="textarea"
                    rows="2"
                    className="form-control"
                    placeholder="Enter description"
                    name="description"
                    value={singleItem.description}
                    onChange={onChange} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className="mt-3">
                  <Label for="templateScalingTypeId">Storm template scaling</Label>
                  <span id={'templateScalingInfo2'}>
                    <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                  </span>
                  <UncontrolledTooltip target="templateScalingInfo2" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
                    <div className="text-start">
                      Scaling choices:
                      <ul>
                        <li>
                          Critical Duration Only: This choice limits the precipitation scaling to the maximum 'X' hours,
                          where 'X' is the duration associated with the Precipitation-Frequency Curve being used for
                          this Execution.
                        </li>
                        <li>
                          Entire timeseries:  If this choice is selected the entire timeseries is scaled. This can result
                          in unrealistically large volumes if the hyetograph tails are long.
                        </li>
                      </ul>
                    </div>
                  </UncontrolledTooltip>
                  <select
                    className="form-control"
                    name="templateScalingTypeId"
                    value={singleItem.templateScalingTypeId}
                    onChange={onChange}>
                    <option value=""></option>
                    {templateScalingTypes.map((s) => <option key={s.id} value={s.id}>{s.name}</option>)}
                  </select>
                  {
                    singleItem.errors.templateScalingTypeId.length > 0 &&
                    <span className='text-danger'>
                      {singleItem.errors.templateScalingTypeId}
                    </span>
                  }
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md="6" className="shadow-sm pt-1 ps-4 pe-4 pb-4">
            <Row>
              <Col md="12">
                <FormGroup className="mt-3">
                  <Label>Model</Label>
                  <select
                    className="form-control"
                    name="modelId"
                    value={singleItem.modelId}
                    onChange={onChange}>
                    <option value="" />
                    {models.map((s) => <option key={s.id} value={s.id}>{s.name}</option>)}
                  </select>
                  {
                    singleItem.errors.modelId.length > 0 &&
                    <span className='text-danger'>
                      {singleItem.errors.modelId}
                    </span>
                  }
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className="mt-3">
                  <Label>Storm template</Label>
                  <select
                    className="form-control"
                    name="templateId"
                    value={singleItem.templateId}
                    onChange={onChange}>
                    <option value="" />
                    {modelTemplates.map((s) => <option key={s.id} value={s.id}>{s.name}</option>)}
                  </select>
                  {
                    singleItem.errors.templateId?.length > 0 &&
                    <span className='text-danger'>
                      {singleItem.errors.templateId}
                    </span>
                  }
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className="mt-3">
                  <Label>Precipitation frequency</Label>
                  <select
                    className="form-control"
                    name="frequencyId"
                    value={singleItem.frequencyId}
                    onChange={onChange}>
                    <option value="" />
                    {frequencies.map((s) => <option key={s.id} value={s.id}>{s.name}</option>)}
                  </select>
                  {
                    //singleItem.errors.frequencyId.length > 0 &&
                    <span className='text-danger'>
                      {singleItem.errors.frequencyId}
                    </span>
                  }
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className="mt-3">
                  <Label>Curve</Label>
                  <select
                    className="form-control"
                    name="curveTypeId"
                    value={singleItem.curveTypeId}
                    onChange={onChange}>
                    <option value="" />
                    {curveTypes.map((s) => <option key={s.id} value={s.id}>{s.name}</option>)}
                  </select>
                  {
                    //singleItem.errors.curveTypeId.length > 0 &&
                    <span className='text-danger'>
                      {singleItem.errors.curveTypeId}
                    </span>
                  }
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className="mt-3">
                  <Label>Probability (Depth)</Label>
                  <select
                    className="form-control"
                    name="curveId"
                    value={singleItem.curveId}
                    onChange={onChange}>
                    <option value="" />
                    {formattedCurves.map((s) => <option key={s.id} value={s.id}>{s.probability + ' (' + s.value + ')'}</option>)}
                  </select>
                  {
                    //singleItem.errors.curveId.length > 0 &&
                    <span className='text-danger'>
                      {singleItem.errors.curveId}
                    </span>
                  }
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md="6" className="mt-3">
            {(singleItem.status && singleItem.status === 'Executed') &&
              <Row>
                <Col className="col-auto pe-2">
                  <Label for="newName">Save as</Label>
                </Col>
                <Col className="col-auto pe-2">
                  <Input
                    type="text"
                    name="newName"
                    placeholder="Enter new execution configuration name"
                    onChange={changeName}
                    style={{ minWidth: '300px' }}
                    value={newName}
                    disabled={modalShow}
                  />
                </Col>
                <Col className="col-auto pe-2">
                  <Button color="dark" size="sm" title="Save As" onClick={saveAs} disabled={modalShow}>
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    <span>Save</span>
                  </Button>
                </Col>
                {saveAsError !== "" && (
                  <Col className="col-auto">
                    <span className="text-danger">
                      <b>{saveAsError}</b>
                    </span>
                  </Col>
                )}
              </Row>
            }
            {(!singleItem.status || singleItem.status !== 'Executed') &&
              <React.Fragment>
                <Button color="dark" size="sm" className="me-2" title="Save" onClick={save} disabled={singleItem.status && singleItem.status !== 'Pending'}>
                  <FontAwesomeIcon icon={faSave} className="me-2" />
                  <span>Save</span>
                </Button>
                <Button
                  color="default"
                  size="sm"
                  title="Reset form"
                  onClick={newItem}
                  className="me-2"
                >
                  <span>Reset Form</span>
                </Button>
              </React.Fragment>
            }
            {(singleItem.status && singleItem.status !== 'Executed') &&
              <React.Fragment>
                <Button 
                  color="primary" 
                  size="sm" 
                  className="me-2" 
                  title="Execute" 
                  onClick={execute} 
                  disabled={singleItem.status !== 'Pending' || disableExecute}>
                  <FontAwesomeIcon icon={faPlay} className="me-2" />
                  <span>Execute</span>
                </Button>
              </React.Fragment>
            }
            {singleError && singleError !== '' &&
              <span className="text-danger"><b>{singleError}</b></span>
            }
            {(modalShow && !singleError) && (
              <span className="text-success">
                <FontAwesomeIcon icon={faCheck} className="me-2" />Execution configuration saved!
              </span>
            )}
          </Col>
          <Col md="6" className="mt-3">
            {(singleItem.id && singleItem.status) &&
              <React.Fragment>
                <Row>
                  <Col md="12" className="pe-2 mb-1">
                    <SSEProvider key={singleItem.id} endpoint={`${eventRoot}/executions/${singleItem.id}/start`}>
                      <ProgressBar updateStatusFunction={updateStatus}
                        initialProgress={singleItem.status === 'Executed' ? '100' : '0'}
                        executionType="single"
                        modelResponseHandler={handleModelResponse} />
                    </SSEProvider>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    {singleItem.status !== 'Executed' &&
                      <span>Execution status: <span className="text-primary">{singleItem.status}</span></span>
                    }
                    {singleItem.status === 'Executed' &&
                      <React.Fragment>
                        <span className='text-success'>
                          <FontAwesomeIcon icon={faCheck} className="me-2" />Execution {singleItem.name} completed successfully.
                        </span>
                        <Button
                          color="dark"
                          size="sm"
                          title="Download"
                          disabled={isLoading}
                          onClick={() => load(downloadSingleExecutionModel(singleItem.id).catch(err => console.log(err)))}>
                          <FontAwesomeIcon icon={faDownload} className="me-2" />
                          Click to Download Model
                        </Button>
                      </React.Fragment>
                    }
                  </Col>
                </Row>
              </React.Fragment>
            }
          </Col>
        </Row>
      </div>
      <Loader loading={isLoading} />
      <AllExecutionsSSEProvider />
    </React.Fragment>
  );
};
