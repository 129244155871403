import "../css/MainMenu.css";
import React, { useState } from "react";
import { NavLink } from 'react-router-dom';
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav
} from "reactstrap";
import { WhoAmI } from "./WhoAmI";
import { useUsers } from "../hooks/userApi";

const webRoot = process.env.REACT_APP_WEB_CONTEXT === "/" ? "" : process.env.REACT_APP_WEB_CONTEXT

export const MainMenu = () => {
  const { isAdmin, isAuthenticated } = useUsers();
  const [toggle, setToggle] = useState(false);

  return (
    <Navbar color="dark" dark expand="lg" fixed="top">
      <NavbarBrand
        href="https://www.usace.army.mil/"
        target="_blank"
        className="me-5"
      >
        <img
          src={`${webRoot}/img/logo.png`}
          alt="USACE logo"
          className="me-2 float-start"
          height="50"
        />
        <span className="float-start" style={{ lineHeight: "0.8em" }}>
          <span style={{ fontSize: "0.6em" }}>US Army Corps of Engineers</span>
          <br />
          <span style={{ fontSize: "0.8em" }}>
            Rainfall Runoff Frequency Tool
          </span>
        </span>
      </NavbarBrand>
      <NavbarToggler onClick={() => setToggle(!toggle)} />
      <Collapse isOpen={toggle} navbar>
        <Nav navbar className="mx-auto my-auto" style={{ fontSize: "1.5em" }}>
          <NavLink
            to={`${webRoot}/`}
            className={({ isActive }) => isActive ? "active" : ""}
            end>
            Home
          </NavLink>
          {isAuthenticated() && (
            <NavLink
              to={`${webRoot}/analysisList`}
              className={({ isActive }) => isActive ? "active" : ""}>
              Analyses
            </NavLink>
          )}
          {isAuthenticated() && isAdmin() && (
            <NavLink
              to={`${webRoot}/admin`}
              className={({ isActive }) => isActive ? "active" : ""}>
              Users
            </NavLink>
          )}
        </Nav>
        <WhoAmI />
      </Collapse>
    </Navbar>
  );
};
