import "../css/BootstrapConfiguration.css";
import React from "react";
import { useSamplingAPI } from "../hooks/samplingAPI";
import { Table } from "reactstrap";

export const BootstrapParametersView = ({ item, sampleType}) => {
  const { bootstrap } = useSamplingAPI();
  const columns = ["Realizations", "Output increments", "Lower confidence interval", "Upper confidence interval", "Minimum output AEP", "Maximum output AEP"];
  const fields = ["numRealizations", "numIncrements", "lowerConfidence", "upperConfidence", "minAep", "maxAep"];
  return (
    <React.Fragment>
      {item.sampleTypeName && Object.keys(bootstrap).length !== 0 && (<Table bordered>
        <thead>
        </thead>
        <tbody>
          {columns.map((column, index) => {
              return <tr key={index}>
                <td style={{width: '50%'}}>{column}</td>
                <td style={{width: '50%', textAlign: 'center'}}>{bootstrap[fields[index]]}</td>
              </tr>;
            })}
      </tbody>
      </Table>)}
    <span className="text-danger">{item.errors ? item.errors.bootstrap : ""}</span>
    <br></br>
    </React.Fragment>
  );
};
