import { useContext } from "react";
import { DispatchStore, StateStore } from "../store/GlobalStore";
import { apiRoot, pfToolsRoot } from "../services/api";
import { userActions } from "../reducers/userReducer";
import {
  resultsActions,
  resultsInitialState,
} from "../reducers/resultsReducer";
import { shadeBetweenConfidenceTraces } from "../util/plotlytrace";
import { Expected } from "./precipFrequencyAPI";

export const getSampleSpace = (item) => {
  if (
    (item.variableName === "Outflow" || item.variableName === "Inflow") &&
    (item.statisticName === "Peak" || item.statisticName === "Volume")
  ) {
    return "Log";
  }
  return "Normal";
};

export const useResultsAPI = () => {
  const { state } = useContext(StateStore);
  const { dispatch } = useContext(DispatchStore);
  const {
    executionsList,
    simulationsList,
    resultsList,
    failedSimulationsList,
    item,
    totalProbabilityCurve,
    error,
    simulationBap,
    simulationMetaInfo,
  } = state.results;

  const fetchExecution = async (id) => {
    try {
      const res = await fetch(`${apiRoot}/executions/sampled/${id}/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error || !json.data) {
        dispatch(resultsActions.setItemError(json.error));
        if (json.error) {
          console.log(json.error);
        }
      } else {
        dispatch(resultsActions.setExecution(json.data));
      }
    } catch (err) {
      dispatch(resultsActions.setItemError(err));
      console.log(err);
    }
  };

  const fetchSample = async (sampleId) => {
    try {
      const res = await fetch(`${apiRoot}/samples/${sampleId}/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error || !json.data) {
        dispatch(resultsActions.setItemError(json.error));
        if (json.error) {
          console.log(json.error);
        }
      } else {
        dispatch(resultsActions.setSample(json.data));
      }
    } catch (err) {
      dispatch(resultsActions.setItemError(err));
      console.log(err);
    }
  };

  const fetchExecutionsList = async (analysisId) => {
    try {
      const res = await fetch(
        `${apiRoot}/analyses/${analysisId}/executions/sampled/?status=Executed`,
        {
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        }
      );
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(resultsActions.setListError("An error occurred."));
        console.log(json.error);
      }
      dispatch(resultsActions.setExecutionsList(json.data));
    } catch (err) {
      dispatch(resultsActions.setListError("An error occurred."));
      console.log(err);
    }
  };
  const calculateTotalProbability = async (numIncrements, results) => {
    const payload = {
      totalProbability: {
        numOutputIncrements: numIncrements,
        thresholdSampleSpace: getSampleSpace(item),
      },
      data: results.bins ? results.bins.filter(bin => bin.data) : results.realizations
    };
    try {
      const res = await fetch(`${pfToolsRoot}/total_probability/`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: `Bearer ${state.user.token}`,
          "Content-Type": "application/json",
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.detail) {
        dispatch(resultsActions.setItemError("An error occurred."));
        console.log(json.detail);
      }
      let tp = []
      let seriesMap = new Map();
      if (json.output.length > 0 && json.output[0].probability) {
        tp.push(
          {
            name: "Total probability",
            type: "scatter",
            mode: "lines",
            x: json.output.map((d) => d.probability),
            y: json.output.map((d) => d.value),
          }
        )
      } else if (json.output.length > 0 && json.output[0].output) {
        json.output.forEach(realization => {
          seriesMap.set(realization.realizationNumber,
            {
              name: "R" +realization.realizationNumber + " - Total probability",
              type: "scatter",
              mode: "lines",
              x: realization.output.map((d) => d.probability),
              y: realization.output.map((d) => d.value),
            }
          )
        });
        seriesMap = shadeBetweenConfidenceTraces(seriesMap)
        if(seriesMap.has(Expected)) {
          seriesMap.get(Expected)["mode"] = "lines";
          seriesMap.get(Expected)["line"] = {color: "black", width: 2, dash: "dash"};
        }
        tp = [...seriesMap.values()];
      }
      dispatch(
        resultsActions.setTotalProbability(tp)
      );
    } catch (error) {
      console.log(error);
      dispatch(resultsActions.setItemError(error));
    }
  };
  const fetchSimulationsList = async (executionId) => {
    try {
      const res = await fetch(
        `${apiRoot}/executions/${executionId}/simulations/`,
        {
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        }
      );
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(resultsActions.setListError("An error occurred."));
        console.log(json.error);
      }
      dispatch(resultsActions.setSimulationsList(json.data));
    } catch (err) {
      dispatch(resultsActions.setListError("An error occurred."));
      console.log(err);
    }
  };

  const fetchResultsList = async (outputId) => {
    try {
      const res = await fetch(`${apiRoot}/results/${outputId}/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(resultsActions.setListError("An error occurred."));
        console.log(json.error);
      }
      dispatch(resultsActions.setResultsList(json.data));
      return json.data;
    } catch (err) {
      dispatch(resultsActions.setListError("An error occurred."));
      console.log(err);
    }
  };

  const fetchSimulationBap = async (simulationId) => {
    try {
      const res = await fetch(`${apiRoot}/results/${simulationId}/bap/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(resultsActions.setItemError("An error occurred."));
        console.log(json.error);
      }
      dispatch(resultsActions.setSimulationBapItem(json.data));
      return json.data;
    } catch (err) {
      dispatch(resultsActions.setItemError("An error occurred."));
      console.log(err);
    }
  };
  const fetchSimulationMetaInfo = async (simulationId) => {
    try {
      const res = await fetch(`${apiRoot}/results/${simulationId}/modelMetaInfo/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(resultsActions.setItemError("An error occurred."));
        console.log(json.error);
      }
      dispatch(resultsActions.setSimulationMetaInfoItem(json.data));
      return json.data;
    } catch (err) {
      dispatch(resultsActions.setItemError("An error occurred."));
      console.log(err);
    }
  };
  const fetchFailedSimulationsList = async (executionId) => {
    try {
      const res = await fetch(
        `${apiRoot}/executions/${executionId}/simulations/?status=Error`,
        {
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        }
      );
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(resultsActions.setListError("An error occurred."));
        console.log(json.error);
      }
      dispatch(resultsActions.setFailedSimulationsList(json.data));
    } catch (err) {
      dispatch(resultsActions.setListError("An error occurred."));
      console.log(err);
    }
  };
  const downloadModel = async (simulationId) => {
    try {
      const res = await fetch(`${apiRoot}/results/${simulationId}/download/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else {
        const json = await res.json();
        window.open(json.data.url, '_blank')
      }
    } catch (err) {
      dispatch(resultsActions.setItemError(err.error));
    }
  };
  const downloadSingleExecutionModel = async (executionId) => {
    try {
      const res = await fetch(`${apiRoot}/results/execution/${executionId}/download/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else {
        const json = await res.json();
        window.open(json.data.url, '_blank')
      }
    } catch (err) {
      dispatch(resultsActions.setItemError(err.error));
    }
  };
  const newItem = () => {
    dispatch(
      resultsActions.setItem(
        JSON.parse(JSON.stringify(resultsInitialState.item))
      )
    );
  };

  const onChange = (e) =>
    dispatch(resultsActions.setItem(JSON.parse(e.target.value)));

  const clearResults = () => {
    dispatch(resultsActions.setResultsList([]));
  };

  const clearBap = () => {
    dispatch(resultsActions.setSimulationBapItem(null));
  };

  const handleChange = (e) => {
    dispatch(resultsActions.handleChange(e))
  }

  const resetState = () => {
    dispatch(resultsActions.resetState())
  }

  const setOutputItem = (item) => {
    dispatch(resultsActions.setOutput(item))
  }

  return {
    executionsList,
    simulationsList,
    resultsList,
    failedSimulationsList,
    item,
    totalProbabilityCurve,
    error,
    fetchExecutionsList,
    calculateTotalProbability,
    fetchSimulationsList,
    fetchResultsList,
    fetchFailedSimulationsList,
    newItem,
    onChange,
    downloadModel,
    downloadSingleExecutionModel,
    fetchSimulationBap,
    simulationBap,
    fetchSimulationMetaInfo,
    simulationMetaInfo,
    clearResults,
    clearBap,
    fetchExecution,
    fetchSample,
    handleChange,
    resetState,
    setOutputItem,
  };
};
