import React, { useState, useMemo, useCallback, useEffect } from "react";
import "leaflet/dist/leaflet.css";
import "../css/Map.css";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  LayersControl,
  Marker,
  Tooltip,
  FeatureGroup,
} from "react-leaflet";
import regions from "../data/regions_dissolved_simplified.json";
import { Button } from "reactstrap";
import leafletPip from "@mapbox/leaflet-pip";
import icon from "leaflet/dist/images/marker-icon.png";

const L = window.L;
const tinyIcon = L.icon({
  iconUrl: icon,
  iconSize: [2, 5],
  iconAnchor: [1, 5],
});

const hash = (object) => {
  let str = JSON.stringify(object);
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    let ascii = str.charCodeAt(i);

    hash = (hash << 5) - hash + ascii;
    hash = hash & hash;
  }
  return hash;
};

export const NoaaA14GisMap = ({ basin }) => {
  const [map, setMap] = useState(null);
  const center = useMemo(() => [37.8, -97.5], []);
  const zoom = 3.75;
  const volumeCenters = {
    // add coordinates here to override bbox centers from geojson file
    "Volume 9": [32.51, -87.32],
    "Volume 7": [65.5, -151],
    "Volume 5": [-14, -170.1],
  };

  const BasinPolygon = () => {
    return basin ? (
      <GeoJSON key={hash(basin)} data={basin} pathOptions={{ color: "blue" }} />
    ) : null;
  };

  const RegionsPolygon = () => {
    return regions ? (
      <GeoJSON
        key={hash(regions)}
        data={regions}
        pathOptions={{ color: "grey" }}
        style={regionStyle}
        onEachFeature={getVolumeCenters}
      />
    ) : null;
  };

  const RegionMarkers = () => {
    return (
      <React.Fragment>
        <div>
          {Object.keys(volumeCenters).map((volume, idx) => (
              <Marker
                position={volumeCenters[volume]}
                opacity={0}
                key={idx}
                icon={tinyIcon}
              >
                <Tooltip
                  permanent={true}
                  direction={"center"}
                  offset={[0, 0]}
                  className={"leaflet-tooltip"}
                  key={hash(idx)}
                >
                  {volume}
                </Tooltip>
              </Marker>
          ))}
        </div>
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (map && basin) {
      const regionLayer = L.geoJSON(regions);
      const basinLayer = L.geoJSON(basin);
      const overlapRegion = leafletPip.pointInLayer(
        basinLayer.getBounds().getCenter(),
        regionLayer,
        true
      );

      map.fitBounds([
        overlapRegion[0]._bounds._southWest,
        overlapRegion[0]._bounds._northEast,
      ]);
    }
  }, [basin, map]);

  const ViewButtons = ({ map, basin }) => {
    const onClickConus = useCallback(() => {
      map.setView(center, zoom);
    }, [map]);

    const onClickBasinRegion = useCallback(() => {
      const regionLayer = L.geoJSON(regions);
      const basinLayer = L.geoJSON(basin);
      const overlapRegion = leafletPip.pointInLayer(
        basinLayer.getBounds().getCenter(),
        regionLayer,
        true
      );

      map.fitBounds([
        overlapRegion[0]._bounds._southWest,
        overlapRegion[0]._bounds._northEast,
      ]);
    }, [basin, map]);

    const onClickBasin = useCallback(() => {
      const basinBounds = [
        [basin.bbox[1], basin.bbox[0]],
        [basin.bbox[3], basin.bbox[2]],
      ];
      map.fitBounds(basinBounds);
    }, [basin, map]);

    return (
      <p>
        {map && (
          <Button
            color="default"
            size="sm"
            className="me-2"
            title="CONUS View"
            onClick={onClickConus}
            id="conusView"
          >
            <span>CONUS View</span>{" "}
          </Button>
        )}
        {basin && (
          <Button
            color="default"
            size="sm"
            className="me-2"
            title="Basin A14 View"
            onClick={onClickBasinRegion}
            id="basinA14View"
          >
            <span>Basin A14 View</span>{" "}
          </Button>
        )}
        {basin && (
          <Button
            color="default"
            size="sm"
            className="me-2"
            title="Basin View"
            onClick={onClickBasin}
            id="basinView"
          >
            <span>Basin View</span>{" "}
          </Button>
        )}
      </p>
    );
  };

  const getVolumeCenters = (feature, layer) => {
    if (feature.properties.VOLUME) {
      if (!Object.keys(volumeCenters).includes(feature.properties.VOLUME)) {
        volumeCenters[feature.properties.VOLUME] = layer
          .getBounds()
          .getCenter();
      }
    }
  };

  const regionStyle = (feature, layer) => {
    let defaultStyle = {
      fillColor: "black",
      fillOpacity: 0.5,
      color: "black",
      weight: 1,
      opacity: 1,
    };

    if (feature.properties.VOLUME === "Volume 1") {
      defaultStyle.fillColor = "#3F7FBF";
    } else if (feature.properties.VOLUME === "Volume 2") {
      defaultStyle.fillColor = "#3FBFBF";
    } else if (feature.properties.VOLUME === "Volume 3") {
      defaultStyle.fillColor = "#E59800";
    } else if (feature.properties.VOLUME === "Volume 4") {
      defaultStyle.fillColor = "#FFCC65";
    } else if (feature.properties.VOLUME === "Volume 5") {
      defaultStyle.fillColor = "#7F3FBF";
    } else if (feature.properties.VOLUME === "Volume 6") {
      defaultStyle.fillColor = "#7F6699";
    } else if (feature.properties.VOLUME === "Volume 7") {
      defaultStyle.fillColor = "#CECF65";
    } else if (feature.properties.VOLUME === "Volume 8") {
      defaultStyle.fillColor = "#6B4747";
    } else if (feature.properties.VOLUME === "Volume 9") {
      defaultStyle.fillColor = "#BFBF3F";
    } else if (feature.properties.VOLUME === "Volume 10") {
      defaultStyle.fillColor = "#79D601";
    } else if (feature.properties.VOLUME === "Volume 11") {
      defaultStyle.fillColor = "#C14242";
    } else if (feature.properties.VOLUME === "Volume 12") {
      defaultStyle.fillColor = "#7F3FBF";
    } else if (feature.properties.VOLUME === "") {
      defaultStyle.fillColor = "transparent";
      defaultStyle.opacity = 0;
    }
    return defaultStyle;
  };

  const displayMap = useMemo(
    () => (
      <MapContainer center={center} zoom={3.5} ref={setMap} zoomSnap={0.1}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LayersControl position="topright">
          <LayersControl.Overlay checked name="A14 Regions">
            <FeatureGroup>
              <RegionsPolygon />
              <RegionMarkers />
            </FeatureGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay checked name="Basin">
            {basin && <BasinPolygon />}
          </LayersControl.Overlay>
        </LayersControl>
      </MapContainer>
    ),
    [center, basin]
  );

  return (
    <div
      id="mapButtons"
      style={{
        textAlign: "center",
      }}
    >
      {map ? <ViewButtons map={map} basin={basin} /> : null}
      <div
        id="mapContainer"
        className="shadow-sm"
        style={{
          backgroundColor: "#CCE9FF",
          border: "1px solid #B2DFFF",
          minHeight: "375px",
          textAlign: "left",
        }}
      >
        {displayMap}
      </div>
    </div>
  );
};
