import { useContext } from "react";
import { DispatchStore, StateStore } from "../store/GlobalStore";
import { apiRoot } from "../services/api";
import {
  executeActions,
  singleItemInitialState,
} from "../reducers/executeReducer";
import { userActions } from "../reducers/userReducer";

export const useExecuteSingleAPI = () => {
  const { state } = useContext(StateStore);
  const { dispatch } = useContext(DispatchStore);
  const {
    list: singleList,
    item: singleItem,
    error : singleError,
    savepoint,
    analysisTemplates,
    analysisTemplatesError,
  } = state.execute;

  const fetchList = async (analysisId) => {
    try {
      const res = await fetch(
        `${apiRoot}/analyses/${analysisId}/executions/single/`,
        {
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        }
      );
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error || !json.data) {
        dispatch(executeActions.setListError("single", json.error));
        if (json.error) {
          console.log(json.error);
        }
      } else {
        dispatch(executeActions.setList("single", json.data));
      }
    } catch (err) {
      dispatch(executeActions.setListError("single", err));
      console.log(err);
    }
  };

  const fetchItem = async (curves, id) => {
    try {
      const res = await fetch(`${apiRoot}/executions/single/${id}/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error || !json.data) {
        dispatch(executeActions.setItemError("single", json.error));
        if (json.error) {
          console.log(json.error);
        }
      } else {
        let itemData = json.data;
        //const curve = fetchCurveInfo(curves, itemData.curveId);
        //itemData.frequencyId = curve.frequencyId;
        //itemData.curveTypeId = curve.curveTypeId;
        dispatch(executeActions.setItem("single", itemData));
      }
    } catch (err) {
      dispatch(executeActions.setItemError("single", err));
      console.log(err);
    }
  };

  const newItem = () => {
    dispatch(
      executeActions.setItem(
        "single",
        JSON.parse(JSON.stringify(singleItemInitialState.item))
      )
    );
  };

  const deleteItem = async (id) => {
    dispatch(executeActions.setItemError("single", null));
    try {
      const res = await fetch(`${apiRoot}/executions/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(
          executeActions.deleteItemError("single", json.error)
        );
        console.log(json.error);
      } else {
        dispatch(executeActions.deleteItem("single", id));
      }
    } catch (err) {
      dispatch(executeActions.setListError("single", err));
      console.log(err);
    }
  };

  const createItem = async (newName) => {
    if (validateItem(singleItem.errors)) {
      dispatch(executeActions.setItemError("single", null));
      try {
        let data = JSON.parse(JSON.stringify(singleItem));
        delete data.errors;
        delete data.frequencyId;
        delete data.curveTypeId;
        data.duration = parseInt(data.duration);
        if (newName) {
          delete data.id;
          data.name = newName;
        }
        const res = await fetch(`${apiRoot}/executions/single/`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        });
        const json = await res.json();
        if (res.status === 401) {
          window.localStorage.removeItem("rrftToken");
          dispatch(userActions.userError("Incorrect email or password"));
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
        } else if (res.status === 403) {
          dispatch(
            userActions.userError(
              "You do not have the appropriate permissions to view / use this resource."
            )
          );
        } else if (json.error || !json.data) {
          dispatch(executeActions.setItemError("single", json.error));
          if (json.error) {
            console.log(json.error);
          }
        } else {
          dispatch(executeActions.setItem("single", json.data));
        }
      } catch (err) {
        dispatch(executeActions.setListError("single", err));
        console.log(err);
      }
    } else {
      dispatch(
        executeActions.setItemError("single", "Missing required fields.")
      );
    }
  };

  const updateItem = async (id) => {
    if (validateItem(singleItem.errors)) {
      dispatch(executeActions.setItemError("single", null));
      try {
        let data = JSON.parse(JSON.stringify(singleItem));
        delete data.errors;
        const res = await fetch(`${apiRoot}/executions/single/`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        });
        const json = await res.json();
        if (res.status === 401) {
          window.localStorage.removeItem("rrftToken");
          dispatch(userActions.userError("Incorrect email or password"));
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
        } else if (res.status === 403) {
          dispatch(
            userActions.userError(
              "You do not have the appropriate permissions to view / use this resource."
            )
          );
        } else if (json.error || !json.data) {
          dispatch(executeActions.setItemError("single", json.error));
          if (json.error) {
            console.log(json.error);
          }
        } else {
          dispatch(executeActions.setItem("single", json.data));
        }
      } catch (err) {
      dispatch(executeActions.setListError("single", err));
      console.log(err);
      }
    } else {
      dispatch(
        executeActions.setItemError("single", "Missing required fields.")
      );
    }
  };

  const executeItem = async (id) => {
    dispatch(executeActions.setItemError("single", null));
    try {
      updateStatus({id, status:'Sending HTTP request'})
      const res = await fetch(`${apiRoot}/executions/${id}/start/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(executeActions.setItemError("single", json.error));
        console.log(json.error);
      }
      if (!res.ok) {
        updateStatus({ id, status:'HTTP request failed'});
      }
    } catch (err) {
      dispatch(executeActions.setListError("single", err));
      updateStatus({id, status:'HTTP request failed'})
      console.log(err);
    }
  };

  const onChange = (e) => {
    dispatch(executeActions.handleChange("single", e));
  };

  const validateItem = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  const fetchAnalysisTemplates = async (analysisId) => {
    try {
      const res = await fetch(`${apiRoot}/analyses/${analysisId}/templates/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error || !json.data) {
        dispatch(
          executeActions.setAnalysisTemplatesError(json.error)
        );
        if (json.error) {
          console.log(json.error);
        }
      }
      dispatch(executeActions.setAnalysisTemplates(json.data));
    } catch (err) {
      dispatch(executeActions.setListError("single", err));
      console.log(err);
    }
  };

  const fetchModelTemplates = (templates, modelId) => {
    let result = [];
    if (modelId) {
      for (let template of templates) {
        if (template.modelId === modelId) {
          result.push({
            id: template.id,
            name: template.name,
          });
        }
      }
    }
    return result;
  };

  const updateStatus = (statusInfo) => {
    dispatch(executeActions.setListStatus("single", statusInfo));
    dispatch(executeActions.setItemStatus("single", statusInfo));
  };

  const setSingleItemError = (error) => {
    dispatch(executeActions.setItemError("single", error));
  }

  const resetState = () => {
    dispatch(executeActions.resetState());
  }

  return {
    singleList,
    singleItem,
    singleError,
    analysisTemplates,
    analysisTemplatesError,
    savepoint,
    fetchList,
    newItem,
    fetchItem,
    deleteItem,
    createItem,
    updateItem,
    executeItem,
    onChange,
    updateStatus,
    fetchAnalysisTemplates,
    setSingleItemError,
    fetchModelTemplates,
    resetState,
  };
};
