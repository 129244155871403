import { getErrorString } from "../util/validation";
export const SET_EXECUTION = "SET_RESULTS_EXECUTION";
export const SET_SAMPLE = "SET_RESULTS_SAMPLE";
export const SET_EXECUTIONS_LIST = "SET_RESULTS_EXECUTIONS_LIST";
export const SET_SIMULATIONS_LIST = "SET_RESULTS_SIMULATIONS_LIST";
export const SET_RESULTS_LIST = "SET_RESULTS_LIST";
export const SET_FAILED_SIMULATIONS_LIST =
  "SET_RESULTS_FAILED_SIMULATIONS_LIST";
export const SET_LIST_ERROR = "SET_RESULTS_LIST_ERROR";
export const SET_ITEM = "SET_RESULTS_ITEM";
export const SET_ITEM_ERROR = "SET_RESULTS_ITEM_ERROR";
export const HANDLE_CHANGE = "HANDLE_RESULTS_CHANGE";
export const SET_TOTAL_PROBABILITY = "SET_TOTAL_PROBABILITY";
export const SET_SIMULATION_BAP_ITEM = "SET_SIMULATION_BAP_ITEM"
export const SET_SIMULATION_METAINFO_ITEM = "SET_SIMULATION_METAINFO_ITEM"
export const RESET_STATE = "RESET_RESULTS_STATE"
export const SET_OUTPUT = "SET_RESULTS_OUTPUT"

export const resultsActions = {
  setExecution(execution) {
    return {
      type: SET_EXECUTION,
      execution: execution,
    };
  },
  setSample(sample) {
    return {
      type: SET_SAMPLE,
      sample: sample,
    };
  },
  setExecutionsList(list) {
    return {
      type: SET_EXECUTIONS_LIST,
      list: list,
    };
  },
  setTotalProbability(list) {
    return {
      type: SET_TOTAL_PROBABILITY,
      list: list,
    };
  },
  setSimulationsList(list) {
    return {
      type: SET_SIMULATIONS_LIST,
      list: list,
    };
  },
  setResultsList(list) {
    return {
      type: SET_RESULTS_LIST,
      list: list,
    };
  },
  setFailedSimulationsList(list) {
    return {
      type: SET_FAILED_SIMULATIONS_LIST,
      list: list,
    };
  },
  setListError(error) {
    return {
      type: SET_LIST_ERROR,
      error: error,
    };
  },
  setItem(item) {
    return {
      type: SET_ITEM,
      item: item,
    };
  },
  setSimulationBapItem(item) {
    return {
      type: SET_SIMULATION_BAP_ITEM,
      item: item,
    };
  },
  setSimulationMetaInfoItem(item) {
    return {
      type: SET_SIMULATION_METAINFO_ITEM,
      item: item,
    };
  },
  setItemError(error) {
    return {
      type: SET_ITEM_ERROR,
      error: error,
    };
  },
  handleChange(e) {
    e.persist();
    return {
      type: HANDLE_CHANGE,
      event: e,
    };
  },
  resetState() {
    return {
      type: RESET_STATE,
    }
  },
  setOutput(item) {
    return {
      type: SET_OUTPUT,
      item: item,
    }
  }
};

export const resultsReducer = (state, action) => {
  let newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case SET_EXECUTION:      
      return {
        ...state,
        item: {
          ...state.item,
          execution: action.execution,
        }
      }
    case SET_SAMPLE:
      const samp = {
        ...state,
        item: {
          ...state.item,
          sample: action.sample,
        }
      }
      return samp
    case SET_EXECUTIONS_LIST:
      return {
        ...state,
        executionsList: action.list,
      };
    case SET_SIMULATIONS_LIST:
      return {
        ...state,
        simulationsList: action.list,
      };
    case SET_RESULTS_LIST:
      return {
        ...state,
        resultsList: action.list,
      };
    case SET_TOTAL_PROBABILITY:
      return {
        ...state,
        totalProbabilityCurve: action.list,
      };
    case SET_FAILED_SIMULATIONS_LIST:
      return {
        ...state,
        failedSimulationsList: action.list,
      };
    case SET_ITEM:
      return {
        ...state,
        item: {
          ...state.item,
          ...action.item,
        }
      };
    case SET_SIMULATION_BAP_ITEM:
      return {
        ...state,
        simulationBap: action.item,
      };
    case SET_SIMULATION_METAINFO_ITEM:
      return {
        ...state,
        simulationMetaInfo: action.item,
      }
    case HANDLE_CHANGE:
      const { name, value } = action.event.target;
      newState.item[name] = value
      return newState
    case SET_LIST_ERROR:
    case SET_ITEM_ERROR:
      newState.error = getErrorString(action.error)
      return newState
    case RESET_STATE:
      return JSON.parse(JSON.stringify(resultsInitialState))
    case SET_OUTPUT:
      newState.item.output = action.item
      return newState
    default:
      return state;
  }
};

export const resultsInitialState = {
  executionsList: [],
  simulationsList: [],
  resultsList: [],
  failedSimulationsList: [],
  totalProbabilityCurve: [],
  item: {
    output: {},
  },
  error: null,
  simulationBap: null,
  simulationMetaInfo: {},
};
