import React, { useState, useEffect, useRef } from 'react';
import jStat from 'jstat'
import { Row, Col, FormGroup, Label } from "reactstrap";
import Plot from 'react-plotly.js';
import { UpperCI, CI } from '../hooks/precipFrequencyAPI';

export const AepChart = (
  {
    title,
    dataSeries,
    xAxisText,
    xAxisMin,
    xAxisMax,
    yAxisText,
    yAxisMin,
    yAxisMax,
    decimalDigitsGeneral,
    decimalDigitsAep,
    setSelectedId,
    downloadFileName,
    showLegend,
    allowChangeTraceMode
  }) => {
  const [data, setData] = useState([]);
  const [layout, setLayout] = useState({});
  const [yAxisType, setYAxisType] = useState("linear");
  const [xAxisType, setXAxisType] = useState("aep");
  const [traceMode, setTraceMode] = useState("lines");
  const fileNameRef = useRef(downloadFileName)
  // const [tooltipOpen, setTooltipOpen] = useState(false);

  var disk = {
    // Plotly disk: https://github.com/plotly/plotly.js/blob/master/src/fonts/ploticon.js
    'width': 857.1,
    'height': 1000,
    'path': 'm214-7h429v214h-429v-214z m500 0h72v500q0 8-6 21t-11 20l-157 156q-5 6-19 12t-22 5v-232q0-22-15-38t-38-16h-322q-22 0-37 16t-16 38v232h-72v-714h72v232q0 22 16 38t37 16h465q22 0 38-16t15-38v-232z m-214 518v178q0 8-5 13t-13 5h-107q-7 0-13-5t-5-13v-178q0-8 5-13t13-5h107q7 0 13 5t5 13z m357-18v-518q0-22-15-38t-38-16h-750q-23 0-38 16t-16 38v750q0 22 16 38t38 16h517q23 0 50-12t42-26l156-157q16-15 27-42t11-49z',
    'transform': 'matrix(1 0 0 -1 0 850)'
  };
  const computeAepPlotlines = () => {
    const labels = ['1E-10', '1E-9', '1E-8', '1E-7', '1E-6', '1E-5', '1E-4',
      '0.001', '0.01', '0.1', '0.5', '0.9', '0.99', '0.999'];
    return labels.map(x => jStat.normal.inv(x, 0, 1));
  };

  const computeAEPTics = () => {
    const tics = [2E-9, 3E-9, 4E-9, 5E-9, 6E-9, 7E-9, 8E-9, 9E-9,
      2E-8, 3E-8, 4E-8, 5E-8, 6E-8, 7E-8, 8E-8, 9E-8,
      2E-7, 3E-7, 4E-7, 5E-7, 6E-7, 7E-7, 8E-7, 9E-7,
      2E-6, 3E-6, 4E-6, 5E-6, 6E-6, 7E-6, 8E-6, 9E-6,
      2E-5, 3E-5, 4E-5, 5E-5, 6E-5, 7E-5, 8E-5, 9e-5,
      2E-4, 3E-4, 4E-4, 5E-4, 6E-4, 7E-4, 8E-4, 9e-4,
      0.002, 0.003, 0.004, 0.005, 0.006, 0.007, 0.008, 0.009,
      0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09,
      0.2, 0.3, 0.4, 0.6, 0.7, 0.8,
      0.91, 0.92, 0.93, 0.94, 0.95, 0.96, 0.97, 0.98];
    return tics.map(x => jStat.normal.inv(x, 0, 1));
  };

  const computeAEPLabels = () => {
    const labels = ['1E-10', '1E-9', '1E-8', '1E-7', '1E-6', '1E-5', '1E-4',
      '0.001', '0.01', '0.1', '0.5', '0.9', '0.99', '0.999'];
    return labels
  };

  const changeXAxisType = (e) => {
    const index = e.target.options.selectedIndex;
    const value = e.target.options[index].value;
    setXAxisType(value)
  };

  const changeYAxisType = (e) => {
    const index = e.target.options.selectedIndex;
    const value = e.target.options[index].value;
    setYAxisType(value)
  };

  const normalizeAepData = (inSeries) => {
    let copySeries = JSON.parse(JSON.stringify(inSeries));
    let outSeries = inSeries;
    switch (xAxisType) {
      case "aep":
        outSeries = copySeries.map((series) => {
          series.xorg = JSON.parse(JSON.stringify(series.x));
          series.x = series.x.map(x => jStat.normal.inv(x, 0, 1))
          return series;
        })
        return outSeries;
      default:
        outSeries = copySeries.map((series) => {
          series.xorg = JSON.parse(JSON.stringify(series.x));
          return series;
        })
        return outSeries;
    }
  };

  const addHoverTextToSeries = (inSeries) => {
    let copySeries = JSON.parse(JSON.stringify(inSeries));
    let outSeries = copySeries.map((series) => {
      series.hoverinfo = "text"
      let hoverText = []
      for (var i = 0; i < series.x.length; i++) {
        let str = series.name + "<br>x: " + series.x[i].toFixed(decimalDigitsAep) + "<br>y: " + series.y[i].toFixed(decimalDigitsGeneral)
        hoverText.push(str)
      };
      series.hovertext = hoverText
      return series
    })
    return outSeries
  };

  const updateXAxis = () => {
    switch (xAxisType) {
      case "aep":
        // code block
        // console.log("changing x scale to " + xAxisType)
        return {
          title: xAxisText ? xAxisText : null,
          tickmode: "array",
          tickvals: computeAepPlotlines(),
          ticktext: computeAEPLabels(),
          gridcolor: "grey",
          zeroline: false,
          mirror: "all",
          showgrid: true,
          showline: true,
          autorange: (xAxisMin || xAxisMax) ? null : "reversed",
          range: [
            xAxisMin ? xAxisMin : null,
            xAxisMax ? xAxisMax : null,
          ],
          minor: {
            showgrid: true,
            gridcolor: "lightgrey",
            tickmode: "array",
            tickvals: computeAEPTics()
          }
        }
      case "logarithmic":
        // code block
        // console.log("changing x scale to " + xAxisType)
        return {
          title: xAxisText ? xAxisText : null,
          type: "log",
          gridcolor: "grey",
          zeroline: false,
          mirror: "all",
          showgrid: true,
          showline: true,
          autorange: (xAxisMin || xAxisMax) ? null : "reversed",
          range: [
            xAxisMin ? xAxisMin : null,
            xAxisMax ? xAxisMax : null,
          ],
          minor: {
            showgrid: true,
            gridcolor: "lightgrey",
          }
        }
      case "linear":
        // code block
        // console.log("changing x scale to " + xAxisType)
        return {
          title: xAxisText ? xAxisText : null,
          gridcolor: "grey",
          zeroline: false,
          mirror: "all",
          showgrid: true,
          showline: true,
          autorange: (xAxisMin || xAxisMax) ? null : "reversed",
          range: [
            xAxisMin ? xAxisMin : null,
            xAxisMax ? xAxisMax : null,
          ],
          minor: {
            showgrid: true,
            gridcolor: "lightgrey",
          }
        }
      default:
        console.log("unknown axis type " + xAxisType)
    }
  };

  const updateYAxis = () => {
    switch (yAxisType) {
      case "logarithmic":
        // code block
        // console.log("changing x scale to " + xAxisType)
        return {
          type: "log",
          title: yAxisText ? yAxisText : null,
          zeroline: true,
          mirror: "all",
          showgrid: true,
          showline: true,
          autorange: (yAxisMin || yAxisMax) ? null : "true",
          range: [
            yAxisMin ? yAxisMin : null,
            yAxisMax ? yAxisMax : null,
          ],
        }
      case "linear":
        // code block
        // console.log("changing x scale to " + xAxisType)
        return {
          title: yAxisText ? yAxisText : null,
          zeroline: true,
          mirror: "all",
          showgrid: true,
          showline: true,
          autorange: (yAxisMin || yAxisMax) ? null : "true",
          range: [
            yAxisMin ? yAxisMin : null,
            yAxisMax ? yAxisMax : null,
          ],
        }
      default:
        console.log("unknown axis type " + yAxisType)
    }
  };

  const updateData = () => {
    switch (xAxisType) {
      case "aep":
        return normalizeAepData(addHoverTextToSeries(dataSeries))
      case "logarithmic":
        return normalizeAepData(addHoverTextToSeries(dataSeries))
      case "linear":
        return normalizeAepData(addHoverTextToSeries(dataSeries))
      default:
        console.log("unknown axis type " + xAxisType)
    }
  };

  const changeTraceMode = (e) => {
    const index = e.target.options.selectedIndex;
    const value = e.target.options[index].value;
    setTraceMode(value)
    let newData = data.map((trace) => {
      trace["mode"] = value
      return trace
    })
    setData(newData)
  }

  const onClickHandler = (data) => {
    // console.log(data);
    let pt = data.points[0];
    let pt_idx = pt.pointIndex;
    let id = pt.data.id[pt_idx]
    // let x = pt.data.x[pt_idx]
    // let xn = jStat.normal.cdf(x, 0, 1)
    // let y = pt.data.y[pt_idx]
    // let name = pt.data.name
    // console.log("Clicked: ", name, id, x, y, xn)
    if (setSelectedId) {
      setSelectedId(id)
    }
  };

  const cleanFileName = (filename) => {
    let cleanedTitle = filename
      .replace(/<\/?[^>]+(>|$)/g, "_")
      .replace(/[^a-zA-Z0-9]/g, "_")
      .replace(" ", "_")
      .replace(/_+/g, "_");
    if (cleanedTitle.endsWith("_")) {
      cleanedTitle = cleanedTitle.substring(0, cleanedTitle.length - 1);
    }
    return cleanedTitle;
  };

  const csvDownload = {
    name: 'downloadCsv',
    title: 'Download data as csv',
    icon: disk,
    click: function (d) {
      // Borrowed some from here https://github.com/plotly/plotly.js/issues/2171
      var filename = fileNameRef.current ? fileNameRef.current : "AEP_SamplingData";
      var data = d.data;
      var csvData = [];
      // Create header
      var header = ['AEP']
      let xArr = [];
      data.forEach((trace) => {
        if (trace.name === CI){
          header.push(UpperCI)
        } else {
          header.push(trace.name);
        }
        xArr = xArr.concat(trace.xorg);
      });
      csvData.push(header);

      // Remove duplicate x values
      let xArrNoDups = [...new Set([...xArr])];

      // Create rows
      xArrNoDups.forEach((x) => {
        let rowArr = [];
        rowArr.push(x)
        data.forEach((trace) => {
          rowArr.push(trace.y[trace.xorg.indexOf(x)]);
        });
        csvData.push(rowArr);
      });
      var csvFile = csvData.map(e => e.map(a => '"' + ((a ?? "").toString().replace(/"/gi, '""')) + '"').join(",")).join("\r\n");
      var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
      var link = document.createElement("a");
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", cleanFileName(filename) ? cleanFileName(filename) + ".csv" : "AEP_Plot_Data.csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    const updatePlot = () => {
      let layout = {
        title: title ? title : null,
        margin: { t: 50 },
        showlegend: showLegend,
        xaxis: updateXAxis(),
        yaxis: updateYAxis()
      }
      let data = updateData()
      setData(data)
      setLayout(layout)
    }
    updatePlot()
    // eslint-disable-next-line
  }, [
    title,
    dataSeries,
    xAxisType,
    xAxisText,
    xAxisMin,
    xAxisMax,
    yAxisType,
    yAxisText,
    yAxisMin,
    yAxisMax,
    decimalDigitsGeneral,
    decimalDigitsAep,
    showLegend,
    allowChangeTraceMode
  ]);

  useEffect(() => {
    fileNameRef.current = downloadFileName
  }, [downloadFileName])

  return (
    <div>
      <Plot
        data={data}
        layout={layout}
        style={{ "width": "100%", "height": "100%" }}
        onClick={(d) => { onClickHandler(d) }}
        // onLegendDoubleClick={(l) => { onLegendDoubleClickHandler(l) }}
        // onLegendClick={(l) => { onLegendClickHandler(l) }}
        // divId={"plotly"}
        useResizeHandler
        config={{
          displaylogo: false,
          toImageButtonOptions: {
            filename: downloadFileName ? downloadFileName : "AEP_Plot",
            height: 500,
            width: 700,
          },
          modeBarButtons: [
            [csvDownload],
            [
              'toImage',
              'zoom2d',
              'pan2d',
              'autoScale2d',
              'resetScale2d',
            ]
          ]
        }}
      />
      <Row>
        <Col md="3"></Col>
        <Col md="6">
          <Row>
            <Col>
              <FormGroup className="p-3">
                <Label for="xAxisType">X Axis Type</Label>
                <select
                  className="form-control"
                  name="xAxisType"
                  defaultValue={xAxisType}
                  onChange={changeXAxisType}>
                  <option key="0" value="aep">
                    Exceedance Probability
                  </option>
                  <option key="1" value="logarithmic">
                    Logarithmic
                  </option>
                  <option key="2" value="linear">
                    Linear
                  </option>
                </select>

              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="p-3">

                <Label for="yAxisType">Y Axis Type</Label>
                <select
                  className="form-control"
                  name="yAxisType"
                  defaultValue={yAxisType}
                  onChange={changeYAxisType}>
                  <option key="0" value="linear">
                    Linear
                  </option>
                  <option key="1" value="logarithmic">
                    Logarithmic
                  </option>
                </select>

              </FormGroup>
            </Col>
            {(allowChangeTraceMode) &&
            
            <Col>
              <FormGroup className="p-3">

                <Label for="traceMode">Trace Mode</Label>
                <select
                  className="form-control"
                  name="traceMode"
                  defaultValue={traceMode}
                  onChange={changeTraceMode}>
                  <option key="0" value="lines">
                    Lines
                  </option>
                  <option key="1" value="markers">
                    Markers
                  </option>
                  <option key="2" value="lines+markers">
                    Lines + Markers
                  </option>
                </select>

              </FormGroup>
            </Col>
            }
          </Row>

        </Col>
        <Col md="3"></Col>
      </Row>
    </div>

  );
}
