import { getErrorString } from "../util/validation";
export const SET_LIST = "SET_ANALYSIS_LIST";
export const SET_LIST_ERROR = "SET_ANALYSIS_LIST_ERROR";
export const SET_ITEM = "SET_ANALYSIS";
export const SET_ITEM_ERROR = "SET_ANALYSIS_ERROR";
export const DELETE_ITEM = "DELETE_ANALYSIS";
export const DELETE_ITEM_ERROR = "DELETE_ANALYSIS_ERROR";
export const HANDLE_CHANGE = "HANDLE_ANALYSIS_CHANGE";
export const RESET_STATE = "RESET_ANALYSIS_STATE"

export const analysisActions = {
  setList(list) {
    return {
      type: SET_LIST,
      list: list,
    };
  },
  setListError(error) {
    return {
      type: SET_LIST_ERROR,
      error: error,
    };
  },
  setItem(item) {
    return {
      type: SET_ITEM,
      item: item,
    };
  },
  setItemError(error) {
    return {
      type: SET_ITEM_ERROR,
      error: error,
    };
  },
  deleteItem() {
    return {
      type: DELETE_ITEM,
    };
  },
  deleteItemError(error) {
    return {
      type: DELETE_ITEM_ERROR,
      error: error,
    };
  },
  handleChange(e) {
    e.persist();
    return {
      type: HANDLE_CHANGE,
      event: e,
    };
  },
  resetState() {
    return {
      type: RESET_STATE,
    }
  },
};

export const analysisReducer = (state, action) => {
  let newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case SET_LIST:
      return {
        ...state,
        id: "",
        list: action.list,
      };
    case SET_ITEM:
      if (!action.item.errors) {
        action.item.errors = {}
        Object.keys(analysisInitialState.item.errors).forEach(key => action.item.errors[key] = "") 
      }
      newState.item = action.item
      newState.savepoint = action.item
      newState.error = null
      return newState
    case DELETE_ITEM:
      return {
        ...state,
      };
    case HANDLE_CHANGE:
      const { name, value, type } = action.event.target;
      newState.item[name] = type === "number" && value ? parseFloat(value) : value
      let errors = newState.item.errors
      if (typeof errors[name] !== 'undefined') {
        errors[name] = value.length < 1 ? "Required." : "";
      }
      return newState
    case SET_LIST_ERROR:
    case SET_ITEM_ERROR:
    case DELETE_ITEM_ERROR:
      newState.error = getErrorString(action.error)
      return newState
    case RESET_STATE:
      newState = JSON.parse(JSON.stringify(analysisInitialState))
      newState.savepoint = JSON.parse(JSON.stringify(state.savepoint))
      return newState
    default:
      return state;
  }
};

export const analysisInitialState = {
  list: [],
  item: {
    name: "",
    description: "",
    units: "",
    errors: {
      name: "Required.",
      units: "Required.",
      decimalDigitsGeneral: "",
      decimalDigitsAep: "",
    },
    decimalDigitsGeneral: 2,
    decimalDigitsAep: 8,
  },
  error: null,
  savepoint: {
    name: "",
    description: "",
    units: "",
    errors: {
      name: "Required.",
      units: "Required.",
      decimalDigitsGeneral: "",
      decimalDigitsAep: "",
    },
    decimalDigitsGeneral: 2,
    decimalDigitsAep: 8,
  },
};
