import React, { useContext, useEffect, useState } from "react";
import { Row, Col, FormGroup, Label, Input, UncontrolledTooltip } from "reactstrap";
import { useDomainListAPI } from "../hooks/domainListAPI";
import { useSamplingAPI } from "../hooks/samplingAPI";
import { StateStore } from "../store/GlobalStore";
import { NumericFormat } from "react-number-format";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FullUncertainty } from "../reducers/samplingReducer";

export const SamplingStratified = ({ frequencies }) => {
  // eslint-disable-next-line
  const { state } = useContext(StateStore);
  const { importanceDistributions } = useDomainListAPI();
  const {
    analysisCurves,
    item,
    savepoint,
    fetchCurveTypes,
    onChange,
    setStratifiedDefault,
    setCurveDefault,
    setProbability,
  } = useSamplingAPI();
  const [ distributionId, setDistributionId ]  = useState()
  const analysis = state.analysis.savepoint;
  const onChangeEnforceInteger = (e) => {
    if (!Number.isInteger(e.target.value)) {
      e.target.value = parseInt(e.target.value);
    }
    onChange(e);
  };
  const switchElement = (e) => {
    const id = e.target.id
    const idArray = id.split("-")
    const isFormattedElement = idArray.length === 2 ? true : false
    if (isFormattedElement) {
      const elInputName = idArray[1]
      const elInput = document.getElementById(elInputName)
      elInput.style.display = "inline";
      elInput.focus()
      const elFormatted = e.target
      elFormatted.style.display = "none";
    } else {
      const id = e.target.id
      const elInput = e.target
      elInput.style.display = "none";
      elInput.blur()
      // example: "#formatted-minAep"
      const elFormattedName = "formatted-" + id
      const elFormatted = document.getElementById(elFormattedName)
      elFormatted.style.display = "inline";
    }
  }
  useEffect(() => {
    if (importanceDistributions){
      setDistributionId(importanceDistributions.find((distribution) => distribution["name"] === "Extreme Value Type 1")["id"])
    }
  // eslint-disable-next-line
  }, [importanceDistributions])

  // set defaults changing precipitation frequency
  useEffect(() => {
    if (!item.id && item.frequencyId) {
      const freqType = frequencies.find((freq) => freq["id"] === item.frequencyId)["frequencyTypeName"];
      setStratifiedDefault(freqType, distributionId)
    }
    // eslint-disable-next-line
  }, [item.frequencyId]);
  useEffect(() => {
    // if it is a saved item from the backend, set defaults only when user is editing form
    if (item.curveTypeId && (!item.id || (savepoint.curveTypeId !== item.curveTypeId))){
      setCurveDefault(item.curveTypeId, item.frequencyId)
    }
  // eslint-disable-next-line
  }, [item.curveTypeId])
  return (
    <React.Fragment>
      <Row>
        <Col md="6">
          <Row>
            <Col md="12">
              {item.sampleTypeName !== FullUncertainty && (
                <FormGroup className="mt-3">
                  <Label for="curveTypeId">Precipitation frequency curve
                    <span id={'infoIconCurveType'}>
                      <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                    </span>
                    <UncontrolledTooltip target="infoIconCurveType" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
                      <div className="text-start">
                        <p>
                        Choose the precipitation frequency curve to sample from the selected precipitation frequency analysis.
                        </p>
                      </div>
                    </UncontrolledTooltip>
                  </Label>
                  <select
                    className="form-control"
                    name="curveTypeId"
                    value={item.curveTypeId}
                    onChange={onChange}
                  >
                    <option value=""></option>
                    {fetchCurveTypes(analysisCurves, item.frequencyId).map(
                      (s) => (
                        <option key={s.id} value={s.id}>
                          {s.name}
                        </option>
                      )
                    )}
                  </select>
                  <span className="text-danger">{item.errors.curveTypeId}</span>
                </FormGroup>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup className="mt-3">
                <Label for="distributionTypeId">Importance distribution
                  <span id={'infoIconDistribution'}>
                    <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                  </span>
                  <UncontrolledTooltip target="infoIconDistribution" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
                    <div className="text-start">
                      <p>
                      The importance distribution used to stratify exceedance probabilities. It is recommended to use the Extreme Value Type I (Gumbel) distribution because it will result in more samples in the tails of the precipitation-frequency curve. 
                      </p>
                    </div>
                  </UncontrolledTooltip>
                </Label>
                <select
                  className="form-control"
                  name="distributionTypeId"
                  value={item.distributionTypeId}
                  onChange={onChange}
                >
                  <option value=""></option>
                  {importanceDistributions.map((d) => (
                    <option key={d.id} value={d.id}>
                      {d.name}
                    </option>
                  ))}
                </select>
                <span className="text-danger">{item.errors.distributionTypeId}</span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup className="mt-3">
                <Label for="numBins">Number of bins
                  <span id={'infoIconBins'}>
                    <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                  </span>
                  <UncontrolledTooltip target="infoIconBins" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
                    <div className="text-start">
                      <p>
                      The number of stratification bins used in the sample process. It is recommended to use at least 20 bins to provide good coverage and stable results. However, it is preferable to use 100 bins with 100 events each, for a total of 10,000 stochastic flood events.
                      </p>
                    </div>
                  </UncontrolledTooltip>
                </Label>
                <Input
                  type="number"
                  min="1"
                  max="1000"
                  step="1"
                  className="form-control"
                  placeholder="Enter number of bins"
                  name="numBins"
                  value={item.numBins}
                  onChange={onChangeEnforceInteger}
                />
                <span className="text-danger">{item.errors.numBins}</span>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <Row>
            <Col md="12">
              {item.sampleTypeName !== FullUncertainty && (
                <FormGroup className="mt-3">
                  <Label for="numSamples">Events per bin
                  <span id={'infoIconEvents'}>
                      <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                    </span>
                    <UncontrolledTooltip target="infoIconEvents" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
                      <div className="text-start">
                        <p>
                        The number of simulated precipitation events per stratification bin. It is recommended to simulate at least 50 events per bin. However, it is preferable to use 100 bins with 100 events each, for a total of 10,000 stochastic flood events.
                        </p>
                      </div>
                    </UncontrolledTooltip>
                  </Label>
                  <Input
                    type="number"
                    min="1"
                    max="1000"
                    step="1"
                    className="form-control"
                    placeholder="Enter number of events per bin"
                    name="numSamples"
                    value={item.numSamples}
                    onChange={onChangeEnforceInteger}
                  />
                  <span className="text-danger">{item.errors.numSamples}</span>
                </FormGroup>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup className="mt-3">
              <Label for="minBinProb">Minimum bin probability</Label>
                <NumericFormat
                  id="formatted-minBinProb"
                  className="form-control"
                  placeholder="Enter minimum bin probability"
                  value={item.minBinProb}
                  onFocus={switchElement}
                  decimalScale={analysis.decimalDigitsAep}
                  isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    if (floatValue == null) {
                      return formattedValue === "";
                    } else {
                      return floatValue <= 1 && floatValue >= 0;
                    }
                  }}
                />
                <NumericFormat
                  id="minBinProb"
                  className="form-control"
                  placeholder="Enter minimum bin probability"
                  name="minBinProb"
                  value={item.minBinProb}
                  onChange={setProbability}
                  isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    if (floatValue == null) {
                      return formattedValue === "";
                    } else {
                      return floatValue <= 1 && floatValue >= 0;
                    }
                  }}
                  style={{ display: 'none' }}
                  onBlur={switchElement}
                />
                <span className="text-danger">{item.errors.minBinProb}</span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup className="mt-3">
                <Label for="maxBinProb">Maximum bin probability</Label>
                <NumericFormat
                  id="formatted-maxBinProb"
                  className="form-control"
                  placeholder="Enter maximum bin probability"
                  name="maxBinProb"
                  value={item.maxBinProb}
                  onFocus={switchElement}
                  decimalScale={analysis.decimalDigitsAep}
                  isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    if (floatValue == null) {
                      return formattedValue === "";
                    } else {
                      return floatValue <= 1 && floatValue >= 0;
                    }
                  }}
                />
                <NumericFormat
                  name="maxBinProb"
                  id="maxBinProb"
                  className="form-control"
                  placeholder="Enter maximum bin probability"
                  value={item.maxBinProb}
                  isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    if (floatValue == null) {
                      return formattedValue === "";
                    } else {
                      return floatValue <= 1 && floatValue >= 0;
                    }
                  }}
                  style={{ display: 'none' }}
                  onBlur={switchElement}
                  onChange={setProbability}
                />
                <span className="text-danger">{item.errors.maxBinProb}</span>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};
