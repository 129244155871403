export const normalize = (normalizer, object) => {
  if (!object) {
    return undefined;
  }
  let normalizedObj = JSON.parse(JSON.stringify(object));
  if (normalizedObj.errors) delete normalizedObj.errors;
  try {
    Object.keys(normalizer).forEach((k) => {
      if (!object.hasOwnProperty(k)) {
        let err = new Error(
          `key ${k} does not exist in provided object: got ${JSON.stringify(
            object
          )}`
        );
        throw err;
      }
      switch (typeof normalizer[k]) {
        case "object":
          if (Array.isArray(object[k])) {
            object[k].forEach((obj, i) => {
              normalizedObj[k][i] = normalize(normalizer[k], obj);
            });
          } else {
            normalizedObj[k] = normalize(normalizer[k], object[k]);
          }
          break;
        case "function":
          if (Array.isArray(object[k])) {
            object[k].forEach((elm, i) => {
              normalizedObj[k][i] = normalizer[k](elm);
            });
          } else if (object[k]) {
            normalizedObj[k] = normalizer[k](object[k]);
          }
          break;
        default:
          normalizedObj[k] = object[k];
          break;
      }
    });
    return normalizedObj;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
