import React, { useReducer } from "react";
import useCombinedReducers from "use-combined-reducers";
import { userReducer, userInitialState } from "../reducers/userReducer";
import {
  domainListReducer,
  domainListInitialState,
} from "../reducers/domainListReducer";
import {
  analysisReducer,
  analysisInitialState,
} from "../reducers/analysisReducer";
import {
  precipFrequencyReducer,
  precipFrequencyInitialState,
} from "../reducers/precipFrequencyReducer";
import {
  analyticalDistParamsReducer,
  analyticalDistParamsInitialState,
} from "../reducers/analyticalDistParamsReducer";
import {
  samplingReducer,
  samplingInitialState,
} from "../reducers/samplingReducer";
import { modelReducer, modelInitialState } from "../reducers/modelReducer";
import {
  executeReducer,
  executeInitialState,
} from "../reducers/executeReducer";
import {
  resultsReducer,
  resultsInitialState,
} from "../reducers/resultsReducer";

export const StateStore = React.createContext();
export const DispatchStore = React.createContext();

export const Provider = ({ children }) => {
  const [state, dispatch] = useCombinedReducers({
    user: useReducer(userReducer, userInitialState),
    domainList: useReducer(domainListReducer, domainListInitialState),
    analysis: useReducer(analysisReducer, analysisInitialState),
    precipFrequency: useReducer(
      precipFrequencyReducer,
      precipFrequencyInitialState
    ),
    analyticalDistParams: useReducer(
      analyticalDistParamsReducer,
      analyticalDistParamsInitialState
    ),
    sampling: useReducer(samplingReducer, samplingInitialState),
    model: useReducer(modelReducer, modelInitialState),
    execute: useReducer(executeReducer, executeInitialState),
    results: useReducer(resultsReducer, resultsInitialState),
  });
  return (
    <DispatchStore.Provider value={{ dispatch }}>
      <StateStore.Provider value={{ state }}>{children}</StateStore.Provider>
    </DispatchStore.Provider>
  );
};
