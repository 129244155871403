import React, { useContext, useState, useEffect, useMemo } from "react";
import {
  createColumnHelper
} from '@tanstack/react-table';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Badge,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import classnames from "classnames";
import { ButtonNavigation } from "./ButtonNavigation";
import { AepChart } from "./AepPlotlyChart";
// import AepChart from "./AepReactChart";
import { ListTable } from "./ListTable";
import { SimulationPanel } from "./SimulationPanel";
import { StateStore } from "../store/GlobalStore";
import { useSamplingAPI } from "../hooks/samplingAPI";
import { Stratified, Quantile, FullUncertainty } from '../reducers/samplingReducer'
import { useResultsAPI } from "../hooks/resultsAPI";
import { useDomainListAPI } from "../hooks/domainListAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useLoading } from "../hooks/Loading";
import Loader from "./Loader";
import { HistChart } from "./HistChart";
const webRoot = process.env.REACT_APP_WEB_CONTEXT === "/" ? "" : process.env.REACT_APP_WEB_CONTEXT

export const Results = () => {
  const [isLoading, load] = useLoading();
  const { state } = useContext(StateStore);
  const analysis = state.analysis.savepoint
  const {
    executionsList,
    resultsList,
    failedSimulationsList,
    item,
    totalProbabilityCurve,
    fetchExecutionsList,
    fetchResultsList,
    fetchFailedSimulationsList,
    calculateTotalProbability,
    downloadModel,
    newItem,
    clearResults,
    clearBap,
    fetchExecution,
    fetchSample,
    resetState,
    setOutputItem,
  } = useResultsAPI();

  const { transformDataToSeriesPlotly } = useSamplingAPI();
  const { modelSaveOptions } = useDomainListAPI();
  const optionMap = {}
  for (let option of modelSaveOptions) {
    optionMap[option.id] = option.name
  }
  const [saveAll, setSaveAll] = useState(false)
  const [saveFailed, setSaveFailed] = useState(false)
  const [activeTab, setActiveTab] = useState("results");
  const [activeResultsTab, setActiveResultsTab] = useState("results");
  const [simulationId, setSimulation] = useState(null);
  const [showBap, setShowBap] = useState(false);
  const [outputText, setOutputText] = useState("");

  // const chartData = useMemo(() => transformDataToSeriesPlotly(resultsList),
  //   // eslint-disable-next-line
  //   [resultsList,]);

  const chartData = useMemo(
    () => {
      if (resultsList && item.sample) {
        return transformDataToSeriesPlotly(resultsList.bins, resultsList.realizations, item.sample.sampleTypeName)
      }
    },
    // eslint-disable-next-line
    [resultsList, item.sample]
  );

  const fetchExecutionAndSimulations = async (row) => {
    if (row.original.id) {
      await load(fetchExecution(row.original.id));
      clearResults();
      setSimulation(null);
      clearBap();
      newItem();
      await load(fetchFailedSimulationsList(row.original.id));
    }
  };

  const changeOutput = (e) => {
    if (e.target.value) {
      const outputItem = JSON.parse(e.target.value)
      const outputId = outputItem.id
      setOutputItem(outputItem);
      setActiveTab("results");
      clearBap();
      setSimulation(null);
      setOutputText(e.target[e.target.selectedIndex].text);
      fetchResultsList(outputId)
    }
  };

  useEffect(() => {
    if (resultsList.bins || resultsList.realizations) {
      calculateTotalProbability(100, resultsList);
    }
    // eslint-disable-next-line
  }, [resultsList]);

  const callDownloadModel = async (simulationId) => {
    await load(downloadModel(simulationId));
  };

  const columnHelper = createColumnHelper();
  const executionColumns = [
    columnHelper.accessor('name', {
      cell: (info) => (
        <a href="#!" title="View execution results" onClick={() => fetchExecutionAndSimulations(info.row)}>
          {info.getValue()}
        </a>
      ),
      header: 'Name',
    }),
    columnHelper.accessor('description', {
      header: 'Description',
    }),
    columnHelper.accessor('modelName', {
      header: 'Model',
    }),
    columnHelper.accessor('sampleName', {
      header: 'Sampling',
    }),
    columnHelper.accessor('templateScalingType', {
      header: 'Storm template scaling',
    }),
    columnHelper.accessor('status', {
      header: 'Status',
    }),
    columnHelper.accessor('statusUpdatedAt', {
      cell: (info) => (
        <span>{info.getValue() !== null ? (new Date(info.getValue())).toLocaleString() : info.getValue()}</span>
      ),
      header: 'Status updated at',
    }),
  ];
  const executionsListExists = executionsList && executionsList.length > 0;

  const activateErrorLogs = async (row) => {
    setShowBap(false)
    setSimulation(row.original.id)
  };

  const failedSimulationColumns = [
    columnHelper.accessor('probability', {
      cell: (info) => (
        <span>{info.getValue().toFixed(analysis.decimalDigitsAep)}</span>
      ),
      style: {
        textAlign: "center",
      },
      header: 'Probability',
    }),
    columnHelper.accessor('value', {
      cell: (info) => (
        <span>{info.getValue().toFixed(analysis.decimalDigitsAep)}</span>
      ),
      style: {
        textAlign: "center",
      },
      header: 'Value',
    }),
    columnHelper.accessor('template', {
      cell: (info) => (
        <span>{info.getValue()}</span>
      ),
      style: {
        textAlign: "center",
      },
      header: 'Template',
    }),
    columnHelper.accessor('id', {
      style: {
        textAlign: "center",
      },
      cell: (info) => (
        <div>
          <Button
            onClick={() => callDownloadModel(info.row.original.id)}
            color="dark"
            size="sm"
            title={
              (saveAll && saveFailed) ?
                "Download model setup" :
                "Execute model with \"Save All\" or \"Save Failed\" option to enable download."
            }
            disabled={!saveAll && !saveFailed}
          >
            <FontAwesomeIcon icon={faDownload} className="me-2" />
            <span>Download</span>
          </Button>
          <Button
            onClick={() => activateErrorLogs(info.row)}
            color="dark"
            size="sm"
            title="View logs and metainfo"
            className="ms-2"
          >
            <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
            <span>View Logs</span>
          </Button>
        </div>
      ),
      header: 'Actions',
    }),
  ];

  const failedSimulationsListExists = failedSimulationsList && failedSimulationsList.length > 0;

  const onChartPointSelect = (id) => {
    // const onChartPointSelect = (e) => {
    setShowBap(true);
    setSimulation(id);
  };

  const selectTab = (tab) => {
    setActiveTab(tab);
    setSimulation(null);
    clearBap();
  };

  // ComponentWillMount
  useEffect(() => {
    newItem()
    // eslint-disable-next-line
  }, [])

  // componentWillUnmount
  useEffect(() => {
    return () => {
      resetState()
    }
    // eslint-disable-next-line  
  }, []);

  useEffect(() => {
    if (item.execution) {
      setSaveAll(optionMap[item.execution.modelSaveOptionId] === 'All')
      setSaveFailed(optionMap[item.execution.modelSaveOptionId] === 'Failed')
      if (item.execution.sampleId) {
        load(fetchSample(item.execution.sampleId));
      }
    }
    // eslint-disable-next-line
  }, [item.execution])
  useEffect(() => {
    if (analysis.id) {
      load(fetchExecutionsList(analysis.id));
    }
    // eslint-disable-next-line
  }, [analysis.id]);
  return (
    <div>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <div className="form">
                <fieldset>
                  <legend>Results</legend>
                  <Row>
                    <Col md="12" className="mt-3">
                      {executionsListExists ?
                        (<ListTable data={executionsList} columns={executionColumns} />)
                        :
                        (<p>There are no results available to display.</p>)}
                    </Col>
                  </Row>
                  {item.execution && item.execution.id && (
                    <Row className="mt-3">
                      <Col md="12">
                        <h3 style={{ fontWeight: "normal" }}>
                          Results for Execution: {item.execution.name}
                        </h3>
                        <Nav tabs fill>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeResultsTab === "results",
                              })}
                              onClick={() => {
                                setActiveResultsTab("results");
                              }}>
                              Results
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeResultsTab === "failed",
                              })}
                              onClick={() => {
                                setActiveResultsTab("failed");
                              }}>
                              Failed Simulations
                              {(failedSimulationsList && failedSimulationsList.length > 0) &&
                                <Badge color="danger" pill style={{ marginLeft: '.25rem' }}>
                                  {failedSimulationsList.length}
                                </Badge>}
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={activeResultsTab}>
                          <TabPane tabId="results">
                            <Col md="12" className="p-3 border shadow-sm mt-3">
                              <Row>
                                <Col md="6">
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mt-3">
                                        <Label for="distribution">
                                          Select output:
                                        </Label>
                                        <select
                                          className="form-control"
                                          name="outputId"
                                          value={JSON.stringify(item.output)}
                                          onChange={changeOutput}>
                                          <option value=""></option>
                                          {item.execution.outputs.map((o) => (
                                            <option
                                              key={o.id}
                                              value={JSON.stringify(o)}>
                                              {`${o.componentName} (${o.componentType}) - ${o.variableName} - ${o.statisticName} - ${o.value} ${o.unit}`}
                                            </option>
                                          ))}
                                        </select>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md="6"></Col>
                              </Row>
                              <Row>
                                {(item.sample && (item.sample.sampleTypeName === Stratified ||
                                  item.sample.sampleTypeName === FullUncertainty)) &&
                                  <Col md="12">
                                    <Nav tabs>
                                      <NavItem>
                                        <NavLink
                                          className={classnames({
                                            active: activeTab === "results",
                                          })}
                                          onClick={() => {
                                            selectTab("results");
                                          }}>
                                          Show Simulation Results
                                          <span id={'simulationResults'}>
                                            <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                                          </span>
                                          <UncontrolledTooltip target="simulationResults" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
                                            <div className="text-left">
                                              Select this tab to see the simulation results. Click on a result point to see additional information in panel below.
                                            </div>
                                          </UncontrolledTooltip>
                                        </NavLink>
                                      </NavItem>
                                      <NavItem>
                                        <NavLink
                                          className={classnames({
                                            active: activeTab === "probability",
                                          })}
                                          onClick={() => {
                                            selectTab("probability");
                                          }}>
                                          Show Total Probability
                                          <span id={'totalProbability'}>
                                            <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                                          </span>
                                          <UncontrolledTooltip target="totalProbability" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
                                            <div className="text-left">
                                              Select this tab to see the total probability curve calculated based on the total probability theorem.
                                            </div>
                                          </UncontrolledTooltip>
                                        </NavLink>
                                      </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                      <TabPane tabId="results">
                                        <Row>
                                          <Col md="12">
                                            <div className="border shadow-sm mt-3"
                                              style={{ width: "100%" }}>
                                              {((resultsList.bins && resultsList.bins.length > 0) ||
                                                (resultsList.realizations && resultsList.realizations.length > 0)) ?
                                                <div>
                                                  {(resultsList.bins && resultsList.bins.length > 0) &&
                                                    <AepChart
                                                      yAxisText={outputText}
                                                      xAxisText={"Annual Exceedance Probability"}
                                                      dataSeries={chartData}
                                                      showLegend={true ? item.sample.sampleTypeName === FullUncertainty : false}
                                                      setSelectedId={onChartPointSelect}
                                                      decimalDigitsGeneral={analysis.decimalDigitsGeneral}
                                                      decimalDigitsAep={analysis.decimalDigitsAep}
                                                      downloadFileName={outputText}
                                                    />
                                                  }
                                                  {(resultsList.realizations && resultsList.realizations.length > 0) &&
                                                    <AepChart
                                                      yAxisText={outputText}
                                                      xAxisText={"Annual Exceedance Probability"}
                                                      dataSeries={chartData}
                                                      showLegend={true ? item.sample.sampleTypeName === FullUncertainty : false}
                                                      setSelectedId={onChartPointSelect}
                                                      decimalDigitsGeneral={analysis.decimalDigitsGeneral}
                                                      decimalDigitsAep={analysis.decimalDigitsAep}
                                                      downloadFileName={outputText}
                                                      allowChangeTraceMode={true}
                                                    />
                                                  }
                                                </div>
                                                :
                                                <div className="p-5 text-center">
                                                  No plot data available.
                                                </div>
                                              }
                                            </div>
                                          </Col>
                                        </Row>
                                      </TabPane>
                                      <TabPane tabId="probability">
                                        <Row>
                                          <Col md="12">
                                            <div className="border shadow-sm mt-3"
                                              style={{ width: "100%" }}>
                                              {((resultsList.bins && resultsList.bins.length > 0) ||
                                                (resultsList.realizations && resultsList.realizations.length > 0))
                                                && activeTab === "probability" ?
                                                <div>
                                                  {(resultsList.bins && resultsList.bins.length > 0) &&
                                                    <AepChart
                                                      yAxisText={outputText}
                                                      xAxisText={"Annual Exceedance Probability"}
                                                      dataSeries={totalProbabilityCurve}
                                                      showLegend={true}
                                                      decimalDigitsGeneral={analysis.decimalDigitsGeneral}
                                                      decimalDigitsAep={analysis.decimalDigitsAep}
                                                      downloadFileName={"total_probability"}
                                                    />
                                                  }
                                                  {(resultsList.realizations && resultsList.realizations.length > 0) &&
                                                    <AepChart
                                                      yAxisText={outputText}
                                                      xAxisText={"Annual Exceedance Probability"}
                                                      dataSeries={totalProbabilityCurve}
                                                      showLegend={true}
                                                      decimalDigitsGeneral={analysis.decimalDigitsGeneral}
                                                      decimalDigitsAep={analysis.decimalDigitsAep}
                                                      downloadFileName={"total_probability"}
                                                      allowChangeTraceMode={true}
                                                    />
                                                  }
                                                </div>
                                                :
                                                <div className="p-5 text-center">
                                                  No plot data available.
                                                </div>
                                              }
                                            </div>
                                          </Col>
                                        </Row>
                                      </TabPane>
                                    </TabContent>
                                  </Col>
                                }
                                {item.sample && item.sample.sampleTypeName === Quantile &&
                                  <Col md="12">
                                    <div className="border shadow-sm mt-3"
                                      style={{ width: "100%" }}>
                                      {resultsList.bins && resultsList.bins.length > 0 ? (
                                        <HistChart
                                          title={"Precipitation Depth at " + item.sample.aep}
                                          yAxisText={"Relative Frequency"}
                                          xAxisText={outputText}
                                          dataSeries={chartData}
                                          decimalDigitsGeneral={analysis.decimalDigitsGeneral}
                                          decimalDigitsAep={analysis.decimalDigitsAep}
                                        />) : (
                                        <div className="p-5 text-center">
                                          No plot data available.
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                }
                              </Row>
                            </Col>
                          </TabPane>
                          <TabPane tabId="failed">
                            <Row>
                              <Col md="12" className="mt-3">
                                {failedSimulationsListExists ?
                                  (<ListTable data={failedSimulationsList} columns={failedSimulationColumns} />)
                                  :
                                  (<p>There are no failed simulations to display.</p>)}
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>

                        {simulationId && (
                          <Row>
                            <Col md="12">
                              <div
                                className="border shadow-sm mt-3"
                                style={{ width: "100%" }}>
                                <SimulationPanel simulationId={simulationId} showDownload={saveAll} showBap={showBap} />
                              </div>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  )}
                </fieldset>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ButtonNavigation
        prevLink={`${webRoot}/analysis/${analysis.id}/execute`}
        prevLinkTooltip="Go back to Execute tab"
      />
      <Loader loading={isLoading} />
    </div>
  );
};
