import React, { useState } from "react";
import { Row, Col, FormGroup, Label, Input, Button, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload, faSave, faCheck } from "@fortawesome/free-solid-svg-icons";
// import { InfoModal } from "./InfoModal";
import { useModelAPI } from "../hooks/modelAPI";
import { useLoading } from "../hooks/Loading";
import Loader from "./Loader";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export const ModelForm = ({ analysisId }) => {
  const [isLoading, load] = useLoading();
  const {
    item,
    fileItem,
    uploadInfo,
    newItem,
    newItemPersistError,
    onChange,
    onFileChange,
    createItem,
    error,
  } = useModelAPI();
  const [modalShow, setModalShow] = useState(false);

  const toast = (msg, msgClass, keepVisible) => {
    const el = document.getElementById("toast");
    el.innerHTML = msg;
    el.className = el.className.replace("invisible", "visible " + msgClass);
    if (!keepVisible) {
      setTimeout(() => {
        document.getElementById("toast").className.replace("visible", "invisible");
      }, 5000);
    }
  };
  const changeUpload = (e) => {
    if (e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const validFileTypes = ['application/x-zip', 'application/zip', 'application/x-zip-compressed', 'application/octet-stream'];
      if (validFileTypes.indexOf(selectedFile.type) === -1) {
        toast("File type must be ZIP.", "text-danger", false);
      }
      else if (selectedFile.size > 2000000000) {
        toast("Maximum file size is 20 megabytes.", "text-danger", false);
      }
      else {
        toast(`Selected file: ${selectedFile.name}`, "text-primary", true);
        onFileChange(selectedFile);
      }
    }
  };
  const save = async () => {
    await load(createItem(analysisId))
    if (item.id == null) {
      newItemPersistError()
      toast("","text-primary", false)
    }
    if (!error) {
      setModalShow(!modalShow);
      setModalShow(false);
      toast("","text-primary", false)
    }
    
  };

  return (
    <React.Fragment>
      <div className="mt-3">
        {item.id ?
          <h3 style={{ fontWeight: "normal" }}>Edit Model {item.name}</h3>
          :
          <h3 style={{ fontWeight: "normal" }}>Upload Model</h3>
        }
        <Row>
          <Col md="6">
            <Row>
              <Col md="12">
                <FormGroup className="mt-3">
                  <Label for="name">HEC-HMS model name
                </Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter model name"
                    name="name"
                    value={item.name}
                    onChange={onChange}
                  />
                  {item.errors.name.length > 0 && (
                    <span className="text-danger">
                      {item.errors.name}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className="mt-3">
                  <Label for="description">Description</Label>
                  <Input
                    type="textarea"
                    rows="2"
                    className="form-control"
                    placeholder="Enter description"
                    name="description"
                    value={item.description}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md="6">
            <Row>
              <Col md="12" className="mt-3">
                {item.id ?
                  <span>Choose HEC-HMS model file to overwrite</span>
                  : <span>Choose HEC-HMS model file</span>}
                  <span id={'infoIconModel'}>
                    <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                  </span>
                  <UncontrolledTooltip target="infoIconModel" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
                    <div className="text-start">
                      <p>
                        Note: The RRFT uses HEC-HMS version 4.10 to run the simulations, and you should migrate to version 4.10.
                        However, we have found that models created with earlier versions (i.e., 4.9) work, and currently allow users to upload and run versions 4.9 and 4.10.
                      </p>
                    </div>
                  </UncontrolledTooltip>
              </Col>
            </Row>
            <Row>
              <Col md="6" className="mt-1">
                <label htmlFor="upload" className="custom-upload w-100">
                  <FontAwesomeIcon icon={faFileUpload} className="me-2" /> Select file to upload
                </label>
                <Input
                  id="upload"
                  type="file"
                  onChange={changeUpload}
                  onClick={(e) => e.target.value = null} />
              </Col>
              <Col md="6" className="mt-1">
                <span
                  id="toast"
                  className="invisible"
                ></span>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="mt-1">
                {(fileItem === null && (item.id === null || item.id === undefined)) && (
                  <span className="font-italic text-danger">
                    Required.
                  </span>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md="12" className="mt-3">
            <Button
              onClick={save}
              color="dark"
              size="sm"
              title="Save"
              className="me-2"
            >
              <FontAwesomeIcon icon={faSave} className="me-2" />
              <span>Save</span>
            </Button>
            <Button
              color="default"
              size="sm"
              title="Reset form"
              onClick={newItem}
              className="me-2"
            >
              <span>Reset Form</span>
            </Button>
            {error && (
              <span className="text-danger">
                <b>{error}</b>
              </span>
            )}
            {(modalShow && !error) && (
              <span className="text-success ms-2">
                <FontAwesomeIcon icon={faCheck} className="me-2" />Model saved!
              </span>
            )}
          </Col>
          {uploadInfo !== null && uploadInfo !== "" && item.id === null && (
            <Row>
              <Col md="12">
                <span className="text-success">
                  {item.id === null &&
                    <div>
                      <FontAwesomeIcon icon={faCheck} className="me-2" />
                      {`Uploaded verified version ${uploadInfo.version},${uploadInfo.templateCount} template(s), ${uploadInfo.componentCount} component(s)`}
                    </div>
                  }
                </span>
              </Col>
            </Row>
          )}
        </Row>
      </div>
      <Loader loading={isLoading} />
    </React.Fragment>
  );
};
