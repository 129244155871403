import { getErrorString } from "../util/validation";
export const SET_LIST = "SET_EXECUTE_LIST";
export const SET_LIST_ERROR = "SET_EXECUTE_LIST_ERROR";
export const SET_LIST_STATUS = "SET_EXECUTE_LIST_STATUS";
export const SET_ITEM = "SET_EXECUTE";
export const SET_ITEM_ERROR = "SET_EXECUTE_ERROR";
export const SET_ITEM_TEMPLATES = "SET_EXECUTE_ITEM_TEMPLATES";
export const SET_ITEM_OUTPUTS = "SET_EXECUTE_ITEM_OUTPUTS";
export const SET_ITEM_STATUS = "SET_EXECUTE_ITEM_STATUS";
export const SET_OUTPUT_ITEM = "SET_EXECUTE_OUTPUT_ITEM";
export const SET_SAVEPOINT = "SET_EXECUTE_SAVEPOINT";
export const DELETE_ITEM = "DELETE_EXECUTE";
export const DELETE_ITEM_ERROR = "DELETE_EXECUTE_ERROR";
export const HANDLE_CHANGE = "HANDLE_EXECUTE_CHANGE";
export const HANDLE_CHANGE_MODEL = "HANDLE_EXECUTE_CHANGE_MODEL";
export const HANDLE_CHANGE_OUTPUT = "HANDLE_EXECUTE_CHANGE_OUTPUT";
export const SET_ANALYSIS_TEMPLATES = "SET_EXECUTE_ANALYSIS_TEMPLATES";
export const SET_ANALYSIS_TEMPLATES_ERROR =
  "SET_EXECUTE_ANALYSIS_TEMPLATES_ERROR";
export const SET_ANALYSIS_COMPONENTS = "SET_EXECUTE_ANALYSIS_COMPONENTS";
export const SET_ANALYSIS_COMPONENTS_ERROR =
  "SET_EXECUTE_ANALYSIS_COMPONENTS_ERROR";
export const RESET_STATE = "RESET_EXECUTE_STATE"
export const SINGLE_TYPE = "single"
export const SAMPLED_TYPE = "sampled"
export const SET_ITEM_FIELD_DEFAULT = "SET_ITEM_FIELD_DEFAULT"

export const executeActions = {
  setList(itemType, list) {
    return {
      type: SET_LIST,
      itemType: itemType,
      list: list,
    };
  },
  setListStatus(itemType, statusInfo) {
    return {
      type: SET_LIST_STATUS,
      itemType: itemType,
      statusInfo: statusInfo,
    };
  },
  setListError(itemType, error) {
    return {
      type: SET_LIST_ERROR,
      itemType: itemType,
      error: error,
    };
  },
  setItem(itemType, item) {
    return {
      type: SET_ITEM,
      itemType: itemType,
      item: item,
    };
  },
  setItemError(itemType, error) {
    return {
      type: SET_ITEM_ERROR,
      itemType: itemType,
      error: error,
    };
  },
  setItemTemplates(itemType, templates) {
    return {
      type: SET_ITEM_TEMPLATES,
      itemType: itemType,
      templates: templates,
    };
  },
  setItemOutputs(itemType, outputs) {
    return {
      type: SET_ITEM_OUTPUTS,
      itemType: itemType,
      outputs: outputs,
    };
  },
  setOutputItem(outputItem) {
    return {
      type: SET_OUTPUT_ITEM, 
      outputItem: outputItem,
    };
  },
  setItemStatus(itemType, statusInfo) {
    return {
      type: SET_ITEM_STATUS,
      itemType: itemType,
      statusInfo: statusInfo,
    };
  },
  deleteItem(itemType, id) {
    return {
      type: DELETE_ITEM,
      itemType: itemType,
      id: id,
    };
  },
  deleteItemError(itemType, error) {
    return {
      type: DELETE_ITEM_ERROR,
      itemType: itemType,
      error: error,
    };
  },
  handleChange(itemType, e) {
    e.persist();
    return {
      type: HANDLE_CHANGE,
      itemType: itemType,
      event: e,
    };
  },
  handleChangeModel(itemType, name, value) {
    return {
      type: HANDLE_CHANGE_MODEL,
      itemType: itemType,
      name: name,
      value: value,
    };
  },
  handleChangeOutput(itemType, name, value) {
    return {
      type: HANDLE_CHANGE_OUTPUT,
      itemType: itemType,
      name: name,
      value: value,
    };
  },
  setAnalysisTemplates(templates) {
    return {
      type: SET_ANALYSIS_TEMPLATES,
      templates: templates,
    };
  },
  setAnalysisTemplatesError(error) {
    return {
      type: SET_ANALYSIS_TEMPLATES_ERROR,
      error: error,
    };
  },
  setAnalysisComponents(components) {
    return {
      type: SET_ANALYSIS_COMPONENTS,
      components: components,
    };
  },
  setAnalysisComponentsError(error) {
    return {
      type: SET_ANALYSIS_COMPONENTS_ERROR,
      error: error,
    };
  },
  resetState() {
    return {
      type: RESET_STATE,
    }
  },
  setSavepoint(item) {
    return {
      type: SET_SAVEPOINT,
      item: item,
    }
  },
  setItemFieldDefault(field) {
    return {
      type: SET_ITEM_FIELD_DEFAULT,
      field: field,
    };
  },
};

export const executeReducer = (state, action) => {
  let errors = null;
  let newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case SET_LIST:
      newState.list = action.list
      newState.activeList = action.list.filter(exec => 
        exec.status !== 'Executed' && exec.status !== 'Pending')
      return newState
    case SET_LIST_STATUS:
      let exec = newState.list.find(
        (e) => e.id === action.statusInfo.id
      );
      exec.status = action.statusInfo.status;
      let newList = newState.list.filter(
        (e) => e.id !== action.statusInfo.id
      );
      newList.push(exec);
      newState.list = newList
      newState.activeList = newList.filter(exec => 
        exec.status !== 'Executed' && exec.status !== 'Pending')
      return newState
    case SET_ITEM:
      newState.item = action.item
      newState.error = null
      // Saved items from the backend do not have errors 
      if (!action.item.errors) {
        switch (action.itemType) {
          case SINGLE_TYPE:
            newState.item.errors = {...singleItemInitialState.item.errors}
            Object.keys(newState.item.errors).forEach(key => newState.item.errors[key] = "")
            break
          case SAMPLED_TYPE:
            newState.item.outputItem = {...outputItemInitialState}
            newState.item.errors = {...sampledItemInitialState.item.errors}
            Object.keys(newState.item.errors).forEach(key => newState.item.errors[key] = "")
            break
          default:
            newState.item.errors = {...sampledItemInitialState.item.errors}
        }
      }
      newState.item.defaultChecked = {
        seed: action.item.seed?.toString() === fieldDefaults.seed,
      }
      if (newState.item.seed === null){
        newState.item.seed = ""
      }
      newState.error = null
      newState.savepoint = newState.item
      return newState 
    case SET_OUTPUT_ITEM:
      newState.item.outputItem = action.outputItem
      if (!action.outputItem.errors) {
        let outputErrors = {...outputItemInitialState.errors}
        Object.keys(outputErrors).forEach(key => outputErrors[key] = "")
        newState.item.outputItem.errors = outputErrors
      }
      return newState
    case SET_ITEM_TEMPLATES:
      newState.item.templates = action.templates;
      return newState
    case SET_ITEM_OUTPUTS:
      newState.item.outputs = action.outputs;
      return newState
    case SET_ITEM_STATUS:
      newState.item.status = action.statusInfo.status;
      return newState
    case DELETE_ITEM:
      newState.list = newState.list.filter((i) => i.id !== action.id)
      return newState
    case HANDLE_CHANGE:
      const { name, value } = action.event.target;
      newState.item[name] = value
      if (typeof newState.item.errors[name] !== 'undefined') {
        newState.item.errors[name] = !value || value.length < 1 ? "Required." : "";
      }
      return newState
    case HANDLE_CHANGE_MODEL:
      newState.item[action.name] = action.value
      newState.item.errors[action.name] = !action.value || action.value.length < 1 ? "Required." : "";
      return newState
    case HANDLE_CHANGE_OUTPUT:
      let newOutputItem = newState.item.outputItem
      errors = newState.item.outputItem.errors
      if (errors[action.name]) {
        if (
          newOutputItem.statisticName === "Peak" &&
          (action.name === "unitId" || action.name === "value")
        ) {
          errors[action.name] = "";
        } else {
          errors[action.name] =
            !action.value || action.value.length < 1 ? "Required." : "";
        }
      }
      const changedOutputItem = {
        ...newOutputItem,
        [action.name]: action.value,
        errors: errors,
      };
      newState.item.outputItem = changedOutputItem
      return newState
    case SET_ANALYSIS_TEMPLATES:
      newState.analysisTemplates = action.templates
      return newState
    case SET_ANALYSIS_COMPONENTS:
      newState.analysisComponents = action.components
      return newState
    case SET_LIST_ERROR:
    case SET_ITEM_ERROR:
    case DELETE_ITEM_ERROR:
      newState.error = getErrorString(action.error)
      return newState
    case SET_ANALYSIS_TEMPLATES_ERROR:
      newState.analysisTemplatesError = action.error
      return newState
    case SET_ANALYSIS_COMPONENTS_ERROR:
      newState.analysisComponentsError = action.error
      return newState
    case RESET_STATE:
      return JSON.parse(JSON.stringify(executeInitialState))
    case SET_SAVEPOINT:
      newState.savepoint = action.item 
      return newState
    case SET_ITEM_FIELD_DEFAULT:
      const newItem = JSON.parse(JSON.stringify(state.item))
      const fieldKey = action.field
      const defaultValue = fieldDefaults[action.field]
      newItem[fieldKey] = defaultValue
      return {
        ...state,
        item: newItem,
      };
    default:
      return state;
  }
};

export const fieldDefaults = {
  seed: '12345'
}

export const singleItemInitialState = {
  list: [],
  activeList: [],
  error: null,
  item: {
    name: "",
    description: "",
    templateScalingTypeId: "",
    modelId: "",
    templateId: "",
    frequencyId: "",
    curveTypeId: "",
    curveId: "",
    errors: {
      name: "Required.",
      templateScalingTypeId: "Required.",
      modelId: "Required.",
      templateId: "Required.",
      frequencyId: "Required.",
      curveTypeId: "Required.",
      curveId: "Required.",
    },
  },
  savepoint:{
    name: "",
    description: "",
    templateScalingTypeId: "",
    modelId: "",
    templateId: "",
    frequencyId: "",
    curveTypeId: "",
    curveId: "",
    errors: {
      name: "Required.",
      templateScalingTypeId: "Required.",
      modelId: "Required.",
      templateId: "Required.",
      frequencyId: "Required.",
      curveTypeId: "Required.",
      curveId: "Required.",
    },
  },

};


export const sampledItemInitialState = {
  list: [],
  activeList: [],
  error: null,
  item: {
    name: "",
    description: "",
    templateScalingTypeId: "",
    modelId: "",
    sampleId: "",
    nodeLevelId: "",
    modelSaveOptionId: "",
    seed: fieldDefaults.seed,
    templates: [],
    outputs: [],
    outputItem: {
      componentId: "",
      componentName: "",
      variableId: "",
      variableName: "",
      statisticId: "",
      statisticName: "",
      unitId: "",
      unit: "",
      value: 0,
      errors: {
        componentId: "Required.",
        variableId: "Required.",
        statisticId: "Required.",
        unitId: "Required.",
        value: "Required.",
      },
    },
    errors: {
      name: "Required.",
      templateScalingTypeId: "Required.",
      modelId: "Required.",
      sampleId: "Required.",
      nodeLevelId: "Required.",
      modelSaveOptionId: "Required."
    },
  },
  savepoint: {
    name: "",
    description: "",
    templateScalingTypeId: "",
    modelId: "",
    sampleId: "",
    nodeLevelId: "",
    modelSaveOptionId: "",
    seed: fieldDefaults.seed,
    templates: [],
    outputs: [],
    outputItem: {
      componentId: "",
      componentName: "",
      variableId: "",
      variableName: "",
      statisticId: "",
      statisticName: "",
      unitId: "",
      unit: "",
      value: 0,
      errors: {
        componentId: "Required.",
        variableId: "Required.",
        statisticId: "Required.",
        unitId: "Required.",
        value: "Required.",
      },
    },
    errors: {
      name: "Required.",
      templateScalingTypeId: "Required.",
      modelId: "Required.",
      sampleId: "Required.",
      nodeLevelId: "Required.",
      modelSaveOptionId: "Required.",
    },
  },
};

export const outputItemInitialState = {
  componentId: "",
  componentName: "",
  statisticId: "",
  statisticName: "",
  unit: "",
  unitId: "",
  value: "",
  variableId: "",
  variableName: "",
  errors: {
    componentId: "Required.",
    variableId: "Required.",
    statisticId: "Required.",
    unitId: "Required.",
    value: "Required.",
  }
};

export const executeInitialState = {
  analysisTemplates: [],
  analysisTemplatesError: null,
  analysisComponents: [],
  analysisComponentsError: null,
  ...sampledItemInitialState,
};