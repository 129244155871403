import React, { useState, useEffect } from "react";
import {
  createColumnHelper
} from '@tanstack/react-table';
import { 
  Row, 
  Col, 
  Button 
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faFileDownload,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { ActionModal } from "./ActionModal";
import { ListTable } from "./ListTable";
import { ModelForm } from "./ModelForm";
import { useModelAPI } from "../hooks/modelAPI";
import { useLoading } from "../hooks/Loading";
import Loader from "./Loader";
import isEqual from "lodash.isequal"
import { Prompt } from "./Prompt";
import { useDependencyAPI } from "../hooks/dependencyAPI";

export const ModelList = ({ analysisId }) => {
  const [isLoading, load] = useLoading();
  const {
    dependencyTypes, 
    fetchDependencies,
  } = useDependencyAPI()
  const {
    item,
    list,
    error,
    savepoint,
    fetchList,
    selectItem,
    newItem,
    deleteItem,
    downloadData,
    resetState,
  } = useModelAPI();
  const [modalShow, setModalShow] = useState(false);
  const [itemToDelete, setItemToDelete] = useState();
  const [deletionWarning, setDeletionWarning] = useState("")
  const promptTitle = "Unsaved Changes"
  const promptMessage = "Are you sure you wish to leave without saving?  All unsaved data from the Model page will be deleted."
  const promptToDelete = async (row) => {
    const dependencies = await fetchDependencies(row.original.id, dependencyTypes.MODEL)
    setDeletionWarning(generateDeletionWarning(dependencies))
    setItemToDelete(row.original.id);
    setModalShow(!modalShow);
  };
  const callDelete = async () => {
    await load(deleteItem(itemToDelete));
    newItem()
    if (!error) {
      setModalShow(!modalShow);
    }
  };
  const callDownload = async (row) => {
    if (row.original.id) {
      await load(downloadData(row.original.id));
    }
  };
  useEffect(() => {
    if (analysisId) {
      load(fetchList(analysisId));
      newItem();
    }
    // eslint-disable-next-line
  }, [analysisId]);
  useEffect(() => {
    resetState()
    return () => resetState()
    // eslint-disable-next-line
  }, [])

  const generateDeletionWarning = (dependencies) => {
    if (dependencies.error) {
      return (
        <div>
          <p>Are you sure you wish to continue?</p>
          <p className='text-danger'>Error retrieving dependency list:</p>
          <p>{dependencies.error.toString()}</p>
          <br/>
          <p className='text-danger'>Deleting a model without knowledge of its associated items is not recommended!</p>
        </div>
      )
    }
    const sampledExecutions = dependencies.sampledExecutions
    const singleExecutions = dependencies.singleExecutions
    if (!sampledExecutions && !singleExecutions){
      return <span>Are you sure you wish to continue? All data for the model will be deleted from the database.</span>
    }
    return (
      <div>
        <p>Are you sure you wish to continue?</p>
        <p>All data for the model will be deleted from the database.</p>
        <p>The following items will also be deleted because they depend on this model.</p>   
        { sampledExecutions &&
          <React.Fragment>
            <p><b>Sampled Executions </b> ({sampledExecutions.length})</p>
            <ul>{sampledExecutions.map((ex) => <li>{ex.name}</li>)}</ul>
          </React.Fragment>
        }
        { singleExecutions &&
          <React.Fragment>
            <p><b>Single Executions </b> ({singleExecutions.length})</p>
            <ul>{singleExecutions.map((ex) => <li>{ex.name}</li>)}
            </ul>
          </React.Fragment>
        }
        
      </div>
    )
  }


  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => (
        <span className="float-start">
          <button
            className="hyperlinkButton"
            title="Edit model"
            onClick={() => selectItem(info.row.original)}
          >
            {info.getValue()}
          </button>
        </span>
      ),
      header: "Name",
    }),
    columnHelper.accessor("description", {
      header: "Description",
    }),
    columnHelper.accessor("version", {
      header: "Version",
    }),
    columnHelper.accessor("ownedBy", {
      header: "Owner",
    }),
    columnHelper.accessor("id", {
      cell: (info) => (
        <span className="float-end">
          <button
            className="hyperlinkButton me-3"
            title="Download model"
            onClick={() => callDownload(info.row)}
          >
            <FontAwesomeIcon icon={faFileDownload} />
          </button>
          <button
            className="hyperlinkButton"
            title="Delete model"
            onClick={() => promptToDelete(info.row)}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        </span>
      ),
      header: "",
    }),
  ];

  // const listExists = list && list.length > 0;
  return (
    <React.Fragment>
      <Row>
        <Col md="12 text-end" className="mb-2">
          <Button
            color="default"
            size="sm"
            title="Add a new model"
            onClick={newItem}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>Add New</span>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md="12">
        <ListTable data={list} columns={columns} />
        </Col>
      </Row>
      <ActionModal
        isOpen={modalShow}
        toggle={() => setModalShow(!modalShow)}
        header={"Delete Model"}
        body={deletionWarning}
        action={callDelete}
      />
      <ModelForm analysisId={analysisId} />
      <Loader loading={isLoading} />
      <Prompt header={promptTitle} message={promptMessage} shouldPrompt={!isEqual(savepoint, item)}/>
    </React.Fragment>
  );
};
