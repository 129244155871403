
import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import { Home } from "./components/Home";
import { AnalysisList } from "./components/AnalysisList";
import { Analysis } from "./components/Analysis";
import { General } from "./components/General";
import { PrecipFrequency } from "./components/PrecipFrequency";
import { Sampling } from "./components/Sampling";
import { Model } from "./components/Model";
import { Execute } from "./components/Execute";
import { Results } from "./components/Results";
import { Login } from "./components/Login";
import { Users } from "./components/Users";
import { ProtectedRoute } from "./components/ProtectedRoute";
import RootLayout from "./RootLayout";


const webRoot = process.env.REACT_APP_WEB_CONTEXT;

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={`${webRoot}`} element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path="analysisList" element={<ProtectedRoute />}>
        <Route index element={<AnalysisList />} />
      </Route>
      <Route path="admin" element={<ProtectedRoute />}>
        <Route index element={<Users />} />
      </Route>
      <Route path="analysis" element={<ProtectedRoute />}>
        <Route path=":analysisId" element={<Analysis />}>
          <Route path="general" element={<General />} />
          <Route path="precipfrequency" element={<PrecipFrequency />} />
          <Route path="sampling" element={<Sampling />} />
          <Route path="model" element={<Model />} />
          <Route path="execute" element={<Execute />} />
          <Route path="results" element={<Results />} />
        </Route>
      </Route>
      <Route path="login" element={<Login />} />
    </Route>
  )
);

export default router;
