import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import { StateStore } from "../store/GlobalStore";
import { Login } from "../components/Login";
// const webRoot = process.env.REACT_APP_WEB_CONTEXT;
export const ProtectedRoute = ({children}) => {
  const { state } = useContext(StateStore);
  if (!state.user.me) {
    //return <Navigate to={redirectPath} />
    return <Login redirectTo={window.location.pathname} />
  }
  return children ? children : <Outlet />;
}
/* export const ProtectedRoute = (props) => {
  const { state } = useContext(StateStore);
  let { as: Comp, path, children, ...otherProps } = props;
  let redirect = window.location.pathname;
  return state.user.me ? (
    <Comp {...otherProps}> {children} </Comp>
  ) : (
    <Login redirectTo={redirect} />
  );
}; */
