import { getErrorString } from "../util/validation";
export const SET_LIST = "SET_MODEL_LIST";
export const SET_LIST_ERROR = "SET_MODEL_LIST_ERROR";
export const UPDATE_LIST_ON_UPDATE = "UPDATE_MODEL_LIST_ON_UPDATE";
export const UPDATE_LIST_ON_CREATE = "UPDATE_MODEL_LIST_ON_CREATE";
export const SET_ITEM = "SET_MODEL";
export const SET_ITEM_ERROR = "SET_MODEL_ERROR";
export const DELETE_ITEM = "DELETE_MODEL";
export const DELETE_ITEM_ERROR = "DELETE_MODEL_ERROR";
export const HANDLE_CHANGE = "HANDLE_MODEL_CHANGE";
export const HANDLE_FILE_CHANGE = "HANDEL_MODEL_FILE_CHANGE";
export const SET_FILE_UPLOAD_INFO = "SET_FILE_UPLOAD_MODEL_INFO";
export const RESET_STATE = "RESET_MODEL_STATE"
export const SET_SAVEPOINT = "SET_MODEL_SAVEPOINT"

export const modelActions = {
  setList(list) {
    return {
      type: SET_LIST,
      list: list,
    };
  },
  setListError(error) {
    return {
      type: SET_LIST_ERROR,
      error: error,
    };
  },
  updateListOnUpdate(item) {
    return {
      type: UPDATE_LIST_ON_UPDATE,
      item: item,
    };
  },
  updateListOnCreate(item) {
    return {
      type: UPDATE_LIST_ON_CREATE,
      item: item,
    };
  },
  setItem(item) {
    return {
      type: SET_ITEM,
      item: item,
    };
  },
  setItemPersistError(item) {
    return {
      type: SET_ITEM,
      item: item,
      persistError: true,
    };
  },
  setItemError(error) {
    return {
      type: SET_ITEM_ERROR,
      error: error,
    };
  },
  deleteItem(id) {
    return {
      type: DELETE_ITEM,
      id: id,
    };
  },
  deleteItemError(error) {
    return {
      type: DELETE_ITEM_ERROR,
      error: error,
    };
  },
  handleChange(e) {
    e.persist();
    return {
      type: HANDLE_CHANGE,
      event: e,
    };
  },
  handleFileChange(fileItem) {
    return {
      type: HANDLE_FILE_CHANGE,
      fileItem: fileItem,
    };
  },
  setFileUploadInfo(uploadInfo) {
    return {
      type: SET_FILE_UPLOAD_INFO,
      uploadInfo: uploadInfo,
    };
  },
  resetState() {
    return {
      type: RESET_STATE,
    };
  },
  setSavepoint(item) {
    return {
      type: SET_SAVEPOINT,
      item: item,
    }
  }
};

export const modelReducer = (state, action) => {
  let newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case SET_LIST:
      return {
        ...state,
        list: action.list,
      };
    case UPDATE_LIST_ON_UPDATE:
      let oldItem = state.list.find(i=> i.id === action.item.id)
      let updateList = state.list.filter((i) => i.id !== action.item.id);
      action.item.version = oldItem.version

      updateList.push(action.item);
      return {
        ...state,
        list: updateList,
      };
    case UPDATE_LIST_ON_CREATE:
      let createList = [...state.list];
      createList.push(action.item);
      return {
        ...state,
        list: createList,
      };
    case SET_ITEM:
      if (!action.item.errors) {
        action.item.errors = {
          name: ""
        };
      }
      return {
        ...state,
        item: action.item,
        error: action.persistError ? state.error : null,
      };
    case DELETE_ITEM:
      return {
        ...state,
        list: state.list.filter((i) => i.id !== action.id),
      };
    case HANDLE_CHANGE:
      const { name, value } = action.event.target;
      let errors = state.item.errors;
      if (typeof errors[name] !== 'undefined') {
        errors[name] = value.length < 1 ? "Required." : "";
      }
      const changedItem = { ...state.item, [name]: value, errors };
      return {
        ...state,
        item: changedItem,
      };
    case HANDLE_FILE_CHANGE:
      newState.item.fileItem = action.fileItem
      return newState
    case SET_FILE_UPLOAD_INFO:
      return {
        ...state,
        uploadInfo: action.uploadInfo,
      };
    case SET_LIST_ERROR:
    case SET_ITEM_ERROR:
    case DELETE_ITEM_ERROR:
      newState.error = getErrorString(action.error)
      return newState
    case RESET_STATE:
      return JSON.parse(JSON.stringify(modelInitialState))
    case SET_SAVEPOINT:
      newState.savepoint = action.item 
      return newState
    default:
      return state;
  }
};

export const modelInitialState = {
  list: [],
  item: {
    name: "",
    description: "",
    fileItem: null,
    errors: {
      name: "Required."
    },
  },
  uploadInfo: null,
  error: "",
  savepoint: {
    name: "",
    description: "",
    fileItem: null,
    errors: {
      name: "Required."
    },
  },
};
