import React from "react";
import { Form, Row, Col, Button, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useUsers } from "../hooks/userApi";

const webRoot = process.env.REACT_APP_WEB_CONTEXT === "/" ? "" : process.env.REACT_APP_WEB_CONTEXT

export const WhoAmI = () => {
  const { me, signOut } = useUsers();
  return (
    <React.Fragment>
      {me ? (
        <Form className="my-2 my-lg-0">
          <Row>
            <Col>
              <Label htmlFor="signOut" className="text-white">
                <FontAwesomeIcon icon={faUser} className="me-2" />
                {me.email}
              </Label>
              <Button
                color="dark"
                className="ms-2 my-2 my-sm-0"
                id="signOut"
                title="Sign out"
                onClick={(e) => signOut()}
              >
                Sign Out
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (

        <Form className="my-2 my-lg-0">
          <Row>
            <Col>
              <a
                href={`${webRoot}/login`}
                className="btn btn-dark my-2 my-sm-0"
                id="signIn"
                title="Sign in"
              >
                Sign In
              </a>
            </Col>
          </Row>
        </Form>
      )}
    </React.Fragment>
  );
};
