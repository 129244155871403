import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  createColumnHelper
} from '@tanstack/react-table';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrashAlt,
  faEdit,
  faSave,
  faPlay,
  faCheck, faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { ListTable } from "./ListTable";
import { ActionModal } from "./ActionModal";
import { useDomainListAPI } from "../hooks/domainListAPI";
import { useSamplingAPI } from "../hooks/samplingAPI";
import { useModelAPI } from "../hooks/modelAPI";
import { useExecuteSampledAPI } from "../hooks/executeSampledAPI";
import { SSEProvider } from "react-hooks-sse";
import { eventRoot } from "../services/api";
import { ProgressBar } from "./ProgressBar";
import { NumericFormat } from "react-number-format";
import { useLoading } from "../hooks/Loading";
import Loader from "./Loader";
import { AllExecutionsSSEProvider } from "./AllExecutionsSSEProvider";

export const ExecuteSampledForm = ({ analysisId }) => {
  const [isLoading, load, sleep] = useLoading();
  const {
    execDurations,
    templateScalingTypes,
    nodeLevels,
    modelSaveOptions,
    componentTypes,
  } = useDomainListAPI();
  const {
    sampledItem,
    sampledList,
    sampledError,
    outputItem,
    analysisComponents,
    createItem,
    updateItem,
    newItem,
    executeItem,
    fetchList,
    onChange,
    fetchAnalysisTemplates,
    onChangeModel,
    updateItemTemplate,
    fetchAnalysisComponents,
    fetchModelComponents,
    fetchVariables,
    fetchStatistics,
    fetchUnits,
    fetchItemOutput,
    newItemOutput,
    addItemOutput,
    updateItemOutput,
    deleteItemOutput,
    onChangeOutput,
    updateStatus,
    setDefault,
  } = useExecuteSampledAPI();

  const columnHelper = createColumnHelper();
  const outputList = sampledItem.outputs;
  const outputColumns = [
    columnHelper.accessor("componentName", {
      cell: (info) => (
        <button
          className="hyperlinkButton"
          title="Edit output"
          onClick={() => callFetchOutput(info.row.original.id)}
        >
          {info.getValue()}
        </button>
      ),
      header: "Component",
    }),
    columnHelper.accessor("variableName", {
      header: "Variable name",
    }),
    columnHelper.accessor("statisticName", {
      header: "Statistic",
    }),
    columnHelper.accessor("value", {
      header: "Value",
    }),
    columnHelper.accessor("unit", {
      header: "Unit",
    }),
    columnHelper.accessor("id", {
      cell: (info) => (
        <button
          className="hyperlinkButton"
          title="Delete output"
          onClick={() => callDeleteOutput(info.row.original.id)}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      ),
      header: "",
    }),
  ];
  // const outputListExists = sampledItem.outputs && sampledItem.outputs.length > 0;

  const templateList = sampledItem.templates;
  const templateColumns = [
    columnHelper.accessor('templateName', {
      header: 'Template name',
    }),
    columnHelper.accessor('weight', {
      cell: (info) => (
        <Input
          name={info.row.original.templateId}
          type="number"
          min="0"
          step="1"
          className="form-control"
          placeholder="Enter weight"
          value={info.getValue()}
          onChange={(e) =>
            updateTemplateWeight(
              info.row.original.templateId,
              e.target.value
            )
          }
        />
      ),
      header: 'Weight',
    }),
  ];
  const templateListExists = sampledItem.templates && sampledItem.templates.length > 0;

  const [actionModalShow, setActionModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [saveAsModalShow, setSaveAsModalShow] = useState(false);
  const [newModelId, setNewModelId] = useState(null);
  const [newName, setNewName] = useState("");
  const [disableExecute, setDisableExecute] = useState(false);
  const [defaultChecked, setDefaultChecked] = useState({seed: true});
  const [saveAsError, setSaveAsError] = useState("");
  const errorRef = useRef(sampledError)
  const fetchModels = useModelAPI().fetchList;
  const models = useModelAPI().list;
  const fetchSamples = useSamplingAPI().fetchList;
  const samples = useSamplingAPI().list;
  const components = useMemo(
    () => fetchModelComponents(analysisComponents, sampledItem.modelId),
    // eslint-disable-next-line
    [analysisComponents, sampledItem.modelId]
  );
  const variables = useMemo(
    () => fetchVariables(components, componentTypes, outputItem.componentId),
    // eslint-disable-next-line
    [components, componentTypes, outputItem.componentId]
  );
  const statistics = useMemo(
    () =>
      fetchStatistics(
        components,
        componentTypes,
        outputItem.componentId,
        outputItem.variableId
      ),
    // eslint-disable-next-line
    [components, componentTypes, outputItem.componentId, outputItem.variableId]
  );
  const filteredUnits = useMemo(
    () =>
      fetchUnits(
        execDurations,
        outputItem.variableName,
        outputItem.statisticName
      ),
    // eslint-disable-next-line
    [outputItem.variableId, outputItem.statisticId]
  );

  const promptToChangeModel = async (e) => {
    const newSelectedModel = e.target.value
    if (sampledItem.modelId !== "" && newSelectedModel !== sampledItem.modelId) {
      setNewModelId(e.target.value);
      setActionModalShow(!actionModalShow);
    } else {
      onChangeModel(e.target.value);
    }
  };
  const changeModel = () => {
    onChangeModel(newModelId);
    setActionModalShow(!actionModalShow);
  };
  const cancelChangeModel = () => {
    setActionModalShow(!actionModalShow);
  };
  const updateTemplateWeight = (templateId, weight) => {
    updateItemTemplate(sampledItem.templates, templateId, weight);
  };
  const changeOutput = (e) => {
    onChangeOutput(e.target.name, e.target.value);
  };
  const changeOutputSelection = (e) => {
    const id = e.target.value;
    const name = e.target[e.target.selectedIndex].text;
    onChangeOutput(e.target.name + "Id", id);
    let targetName = e.target.name;
    if (targetName !== "unit") {
      targetName += "Name";
    }
    onChangeOutput(targetName, name);
  };
  const callFetchOutput = (id) => {
    fetchItemOutput(sampledItem.outputs, id);
  };
  const callDeleteOutput = (id) => {
    deleteItemOutput(sampledItem.outputs, id);
    newItemOutput();
  };
  const callUpdateOutput = () => {
    if (outputItem.id) {
      updateItemOutput(sampledItem.outputs, outputItem);
    } else {
      addItemOutput(sampledItem.outputs, outputItem);
    }
  };
  const save = async () => {
    if (sampledItem.id) {
      await load(updateItem(sampledItem.id));
    } else {
      await load(createItem());
    }
    if (!errorRef.current) {
      setModalShow(true);
      setDisableExecute(true);
      await load(fetchList(analysisId));
      setDisableExecute(false);
      await sleep(2000)
      setModalShow(false);
    }
  };
  const changeName = (e) => {
    e.persist();
    setSaveAsError("");
    setNewName(e.target.value);
  };
  const saveAs = async () => {
    setSaveAsError("");
    if (newName === "" || sampledList.some(item => item.name === newName.toString())) {
      setSaveAsError("Please enter a new name.");
    } else {
      await load(createItem(newName));
      if (!errorRef.current) {
        setSaveAsModalShow(true);
        setDisableExecute(true);
        await load(fetchList(analysisId));
        setDisableExecute(false);
        await sleep(2000)
        setSaveAsModalShow(false);
        setNewName("");
        setSaveAsError("");
      } else {
        setSaveAsError(sampledError);
      }
    }
  };
  const execute = async () => {
    if (sampledItem.id) {
      await executeItem(sampledItem.id);
    }
  };
  const handleModelResponse = (response) => {
    if (response.result) {
      //do something if required in future
    }
  };
  const defaultChangeHandler = (e, field) => {
    const isEnabled = e.target.checked
    if (isEnabled){
      setDefault(field)
    }
    setDefaultChecked({
      ...defaultChecked,
      [field]: isEnabled
    })
  }

  useEffect(() => {
    if (analysisId) {
      load(fetchModels(analysisId));
      load(fetchSamples(analysisId));
      load(fetchAnalysisTemplates(analysisId));
      load(fetchAnalysisComponents(analysisId));
    }
    // eslint-disable-next-line
  }, [analysisId]);
  useEffect(() => {
    setNewName('')
    setSaveAsError('')
  }, [sampledItem.id])

  useEffect(() => {
    // this property is set when item is loaded to ensure default checkbox reflects value
    if(sampledItem.defaultChecked)
      setDefaultChecked(sampledItem.defaultChecked)
    // eslint-disable-next-line
  }, [sampledItem.defaultChecked.seed]);

  return (
    <React.Fragment>
      <div className="mt-3">
        <h3 style={{ fontWeight: "normal" }}>
          Sampled Execution Configuration
        </h3>
        <Row className="p-3">
          <Col md="6" className="shadow-sm pt-1 ps-4 pe-4 pb-4">
            <Row>
              <Col md="12">
                <FormGroup className="mt-3">
                  <Label for="name">Name</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter execution configuration name"
                    name="name"
                    value={sampledItem.name}
                    onChange={onChange}
                  />
                  {
                    sampledItem.errors.name.length > 0 &&
                    (<span className="text-danger">
                      {sampledItem.errors.name}
                    </span>)
                  }
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className="mt-3">
                  <Label for="description">Description</Label>
                  <Input
                    type="textarea"
                    rows="2"
                    className="form-control"
                    placeholder="Enter description"
                    name="description"
                    value={sampledItem.description}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className="mt-3">
                  <Label for="templateScalingTypeId">
                    Storm template scaling
                  </Label>
                  <span id={'templateScalingInfo'}>
                    <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                  </span>
                  <UncontrolledTooltip target="templateScalingInfo" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
                    <div className="text-start">
                      <p>Storm template scaling choices:</p>
                      <ul>
                        <li>
                          Critical Duration Only: This choice limits the precipitation scaling to the maximum 'X' hours,
                          where 'X' is the duration associated with the Precipitation-Frequency Curve being used for
                          this Execution.
                        </li>
                        <li>
                          Entire timeseries:  If this choice is selected the entire timeseries is scaled. This can result
                          in unrealistically large volumes if the hyetograph tails are long.
                        </li>
                      </ul>
                    </div>
                  </UncontrolledTooltip>
                  <select
                    className="form-control"
                    name="templateScalingTypeId"
                    value={sampledItem.templateScalingTypeId}
                    onChange={onChange}
                  >
                    <option value=""></option>
                    {templateScalingTypes.map((s) => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </select>
                  {sampledItem.errors.templateScalingTypeId.length > 0 && (
                    <span className="text-danger">
                      {sampledItem.errors.templateScalingTypeId}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className="mt-3">
                  <Label for="nodeLevelId">
                    Compute Resource Level
                  </Label>
                  <span id={'nodeLevelInfo'}>
                    <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                  </span>
                  <UncontrolledTooltip target="nodeLevelInfo" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
                    <div className="text-start">
                      <p>Compute resource level is used to control the number of nodes used to execute the simulations.
                        There are no hard rules for choosing the resource level, but the following general guidelines
                        are a good starting point based on the number of samples being run.</p>
                      <ul>
                        <li>
                          Low: 100 or fewer simulations
                        </li>
                        <li>
                          Medium: 100-500 simulations
                        </li>
                        <li>
                          Large: 500-2,000 simulations
                        </li>
                        <li>
                          Extra Large: More than 2,000
                        </li>
                      </ul>
                    </div>
                  </UncontrolledTooltip>
                  <select
                    className="form-control"
                    name="nodeLevelId"
                    value={sampledItem.nodeLevelId}
                    onChange={onChange}
                  >
                    <option value="" />
                    {nodeLevels.map((s) => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </select>
                  {sampledItem.errors.nodeLevelId.length > 0 && (
                    <span className="text-danger">
                      {sampledItem.errors.nodeLevelId}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className="mt-3">
                  <Label for="modelSaveOptionId">
                    Model Save Option
                  </Label>
                  <span id={'saveOptionInfo'}>
                    <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                  </span>
                  <UncontrolledTooltip target="saveOptionInfo" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
                    <div className="text-start">
                      <p>Model save options are specified to control what models are saved when they are executed.
                        Note this refers to a zipped-up copy of the entire model setup that can be downloaded and
                        run on your personal computer for troubleshooting purposes. The model results are always saved.</p>
                      <ul>
                        <li>
                          All: With this option, every model run will be saved.  This is not recommended except
                          for troubleshooting executions with a small number of simulations (i.e., less than 25)
                        </li>
                        <li>
                          Failed: With this option, model simulations that fail will be saved for download and evaluation.
                          This option can be used with large runs that are relatively stable but are maybe experiencing some
                          failures that you want to look into. This will typically be used after a simulation has been run
                          with "None" selected and some failures are seen.
                        </li>
                        <li>
                          None: With this option, no models will be saved regardless of whether they fail or not.
                          This is what should typically be used as a default.
                        </li>
                      </ul>
                    </div>
                  </UncontrolledTooltip>
                  <select
                    className="form-control"
                    name="modelSaveOptionId"
                    value={sampledItem.modelSaveOptionId}
                    onChange={onChange}
                  >
                    <option value="" />
                    {modelSaveOptions.map((s) => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </select>
                  {sampledItem.errors.modelSaveOptionId.length > 0 && (
                    <span className="text-danger">
                      {sampledItem.errors.modelSaveOptionId}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className="mt-3">
                  <Label for="seed">
                    Template Sampling Seed
                  </Label>        
                  <span id={'seedInfo'}>
                    <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                  </span>
                  <br/>
                  <Label for="useSeedDefault" className={'smallLabel'}>
                    Use Default
                  </Label>
                  &nbsp;&nbsp;
                  <input 
                    type="checkbox" 
                      id="useSeedDefault" 
                      value="true" 
                      checked={defaultChecked ? defaultChecked.seed : true} 
                      onChange={(e) => defaultChangeHandler(e, "seed")} 
                    />
                  <UncontrolledTooltip target="seedInfo" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
                    <div className="text-start">
                      <p>
                        The Template Sampling Seed is used to seed the random sampling of templates for each simulation.
                        The seed must be an integer between 0 and 1,000,000, or the seed can be left blank to have the system generate a random seed.
                        Typically, leaving the seed as the default value of 12345 will be appropriate.
                      </p>
                    </div>
                  </UncontrolledTooltip>
                    <NumericFormat
                      className="form-control"
                      placeholder="Enter integer < 1,000,000 or leave empty to use random seed"
                      name="seed"
                      value={sampledItem.seed}
                      onChange={onChange}
                      disabled={defaultChecked.seed}
                      decimalScale={0}
                      isAllowed={(values) => {
                        const inRange = values.floatValue < 1000000 && values.floatValue > 0
                        const empty = !values.floatValue
                        return inRange || empty
                      }}
                    />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md="6" className="shadow-sm pt-1 ps-4 pe-4 pb-4">
            <Row>
              <Col md="12">
                <FormGroup className="mt-3">
                  <Label>Model</Label>
                  <select
                    className="form-control"
                    name="modelId"
                    value={sampledItem.modelId}
                    onChange={promptToChangeModel}
                  >
                    <option value="" />
                    {models.map((s) => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </select>
                  {sampledItem.errors.modelId.length > 0 && (
                    <span className="text-danger">
                      {sampledItem.errors.modelId}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className="mt-3">
                  <Label>Sampling</Label>
                  <select
                    className="form-control"
                    name="sampleId"
                    value={sampledItem.sampleId}
                    onChange={onChange}
                  >
                    <option value="" />
                    {samples.map((s) => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </select>
                  {sampledItem.errors.sampleId.length > 0 && (
                    <span className="text-danger">
                      {sampledItem.errors.sampleId}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            {templateListExists && (
              <Row>
                <Col md="12" className="mt-3">
                  <Label>Storm templates and weights</Label>
                  <span id={'templateAndWeightsInfo'}>
                    <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                  </span>
                  <UncontrolledTooltip target="templateAndWeightsInfo" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
                    <div className="text-start">
                      The template weights entered in this table are used to specify the relative likelihood
                      that a given template will be selected when the templates are randomly selected during execution.
                      A weight of 0 means that a template will not be selected.  Any template with a weight value greater
                      than 0 will be selected based on its relative weight. For example, if you have two templates A and B
                      with weights 1 and 2, respectively, template B will be selected twice as often as template A.
                    </div>
                  </UncontrolledTooltip>
                  <ListTable data={templateList} columns={templateColumns} />
                </Col>
              </Row>)}
          </Col>
        </Row>
        <Row className="p-1 border-top mt-2">
          <Col md="12" className="mt-3">
            <Label>
              <h3 style={{ fontWeight: "normal" }}>
                Select Model Outputs
              </h3>
            </Label>

            <span id={'modelOutputsInfo'}>
              <FontAwesomeIcon icon={faInfoCircle} className="ms-2 mb-1" />
            </span>
            <UncontrolledTooltip target="modelOutputsInfo" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
              <div className="text-start">
                Select components, variables and statistics to be output from the model for each model simulation.
              </div>
            </UncontrolledTooltip>
            <React.Fragment>
              <Row>
                <Col md="12 mb-2 text-end">
                  <Button
                    color="default"
                    size="sm"
                    title="Add an execution configuration"
                    onClick={newItemOutput}
                  >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    <span>Add New</span>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <ListTable data={outputList} columns={outputColumns} />
                </Col>
              </Row>
            </React.Fragment>

          </Col>
        </Row>
        <Row className="p-3">
          <Col md="12" className="shadow-sm p-3">
            <Row>
              <Col md="6">
                <Row>
                  <Col md="12">
                    <FormGroup className="mt-3">
                      <Label for="component">Component</Label>
                      <select
                        className="form-control"
                        name="component"
                        value={outputItem.componentId}
                        onChange={changeOutputSelection}
                      >
                        <option value="" />
                        {sampledItem.modelId &&
                          components.map((s) => (
                            <option key={s.id} value={s.id}>
                              {s.name}
                            </option>
                          ))}
                      </select>
                      {outputItem.errors.componentId.length > 0 && (
                        <span className="text-danger">
                          {outputItem.errors.componentId}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup className="mt-3">
                      <Label for="variable">Variable</Label>
                      <select
                        className="form-control"
                        name="variable"
                        value={outputItem.variableId}
                        onChange={changeOutputSelection}
                      >
                        <option value="" />
                        {sampledItem.modelId &&
                          outputItem.componentId &&
                          variables.map((s) => (
                            <option key={s.id} value={s.id}>
                              {s.name}
                            </option>
                          ))}
                      </select>
                      {outputItem.errors.variableId.length > 0 && (
                        <span className="text-danger">
                          {outputItem.errors.variableId}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <Row>
                  <Col md="12">
                    <FormGroup className="mt-3">
                      <Label for="statistic">Statistic</Label>
                      <select
                        className="form-control"
                        name="statistic"
                        value={outputItem.statisticId}
                        onChange={changeOutputSelection}
                      >
                        <option value="" />
                        {sampledItem.modelId &&
                          outputItem.componentId &&
                          outputItem.variableId &&
                          statistics.map((s) => (
                            <option key={s.id} value={s.id}>
                              {s.name}
                            </option>
                          ))}
                      </select>
                      {outputItem.errors.statisticId.length > 0 && (
                        <span className="text-danger">
                          {outputItem.errors.statisticId}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup className="mt-3">
                      <Label for="value">Value</Label>
                      <Input
                        type="number"
                        min="0"
                        step="1"
                        className="form-control"
                        placeholder="Enter value"
                        name="value"
                        value={outputItem.value}
                        onChange={changeOutput}
                      />
                      {outputItem.errors.value.length > 0 && (
                        <span className="text-danger">
                          {outputItem.errors.value}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mt-3">
                      <Label for="unit">Units</Label>
                      <select
                        className="form-control"
                        name="unit"
                        value={outputItem.unitId}
                        onChange={changeOutputSelection}
                      >
                        <option value=""></option>
                        {filteredUnits.map((s) => (
                          <option key={s.id} value={s.id}>
                            {s.name}
                          </option>
                        ))}
                      </select>
                      {outputItem.errors.unitId.length > 0 && (
                        <span className="text-danger me-2">
                          {outputItem.errors.unitId}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Button
                  color="dark"
                  size="sm"
                  className="me-2"
                  title="{outputItem.id ? 'Update' : 'Add'}"
                  onClick={callUpdateOutput}
                >
                  <FontAwesomeIcon icon={faEdit} className="me-2" />
                  <span>{outputItem.id ? "Update" : "Add"}</span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-2 border-top">
          <Col md="6" className="mt-3">
              {sampledItem.status && sampledItem.status === "Executed" && (
                <Row>
                  <Col className="col-auto pe-2">
                    <Label for="newName">Save as</Label>
                  </Col>
                  <Col className="col-auto pe-2">
                    <Input
                      type="text"
                      name="newName"
                      placeholder="Enter new execution configuration name"
                      onChange={changeName}
                      style={{ minWidth: '300px' }}
                      value={newName}
                      disabled={saveAsModalShow}
                    />
                  </Col>
                  <Col className="col-auto pe-2">
                    <Button color="dark" size="sm" title="Save As" onClick={saveAs} disabled={saveAsModalShow}>
                      <FontAwesomeIcon icon={faSave} className="me-2" />
                      <span>Save</span>
                    </Button>
                  </Col>
                  {saveAsError !== "" && (
                    <Col className="col-auto">
                      <span className="text-danger">
                        <b>{saveAsError}</b>
                      </span>
                    </Col>
                  )}
                  {saveAsError === "" && sampledError && (
                    <Col className="col-auto">
                      <span className="text-danger">
                        <b>{sampledError}</b>
                      </span>
                    </Col>
                  )}
                </Row>
              )}
              {(!sampledItem.status || sampledItem.status !== "Executed") && (
                <React.Fragment>
                  <Button
                    color="dark"
                    size="sm"
                    className="me-2"
                    title="Save"
                    onClick={save}
                    disabled={
                      sampledItem.status && sampledItem.status !== "Pending"
                    }
                  >
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    <span>Save</span>
                  </Button>
                  <Button
                    color="default"
                    size="sm"
                    title="Reset execution configuration form"
                    onClick={newItem}
                    className="me-2"
                  >
                    <span>Reset Form</span>
                  </Button>
                  {sampledError && sampledError !== "" && (
                    <span className="text-danger me-2">
                      <b>{sampledError}</b>
                    </span>
                  )}
                </React.Fragment>
              )}
              {sampledItem.status && sampledItem.status !== "Executed" && (
                <React.Fragment>
                  <Button
                    color="primary"
                    size="sm"
                    className=" me-2"
                    title="Execute"
                    onClick={execute}
                    disabled={(sampledItem.status !== "Pending" || disableExecute)}
                  >
                    <FontAwesomeIcon icon={faPlay} className="me-2" />
                    <span>Execute</span>
                  </Button>
                </React.Fragment>
              )}
              {(modalShow && !sampledError) && (
                <span className="text-success">
                  <FontAwesomeIcon icon={faCheck} className="me-2" />Execution configuration saved!
                </span>
              )}
              {(saveAsModalShow && !sampledError) && (
                <span className="text-success">
                  <FontAwesomeIcon icon={faCheck} className="me-2" />Execution configuration saved!
                </span>
              )}
          </Col>
          <Col md="6" className="mt-3">
              {sampledItem.id && sampledItem.status && (
                <React.Fragment>
                  <Row>
                    <Col md="12" className="pe-2 mb-1">
                      <SSEProvider
                        key={sampledItem.id}
                        endpoint={`${eventRoot}/executions/${sampledItem.id}/start`}
                      >
                        <ProgressBar
                          updateStatusFunction={updateStatus}
                          initialProgress={
                            sampledItem.status === "Executed" ? "100" : "0"
                          }
                          modelResponseHandler={handleModelResponse}
                          executionType="sampled"
                        />
                      </SSEProvider>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      {sampledItem.status !== "Executed" && (
                        <span>
                          Execution status:{" "}
                          <span className="text-primary">
                            {sampledItem.status}
                          </span>
                        </span>
                      )}
                      {sampledItem.status === "Executed" && (
                        <span className="text-success">
                          <FontAwesomeIcon icon={faCheck} className="me-2" />
                          Execution {sampledItem.name} completed successfully.
                        </span>
                      )}
                    </Col>
                  </Row>
                </React.Fragment>
              )}
          </Col>
        </Row>
        <ActionModal
          isOpen={actionModalShow}
          toggle={cancelChangeModel}
          header={"Warning"}
          body={
            "Are you sure you wish to continue?  This action will clear all storm template weights and output data."
          }
          action={changeModel}
        />
      </div>
      <Loader loading={isLoading} />
      <AllExecutionsSSEProvider />
    </React.Fragment>
  );
};
