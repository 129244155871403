import { useContext } from "react";
import { DispatchStore, StateStore } from "../store/GlobalStore";
import { apiRoot } from "../services/api";
import { domainListActions } from "../reducers/domainListReducer";
import { userActions } from "../reducers/userReducer";

export const useDomainListAPI = () => {
  const { state } = useContext(StateStore);
  const { dispatch } = useContext(DispatchStore);
  const {
    componentTypes,
    curveTypes,
    days,
    durations,
    execDurations,
    frequencyTypes,
    hours,
    importanceDistributions,
    minutes,
    spatialTypes,
    statisticTypes,
    unitSystems,
    templateScalingTypes,
    variableStatistics,
    distributionTypes,
    nodeLevels,
    modelSaveOptions,
    sampleTypeFrequencyTypes,
    roles,
  } = state.domainList;

  const fetchLists = async () => {
    try {
      const res = await fetch(`${apiRoot}/domains/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(domainListActions.setListError(json.error));
      } else {
        if (json.data.componentTypes) {
          dispatch(
            domainListActions.setComponentTypes(json.data.componentTypes)
          );
        }
        if (json.data.curveTypes) {
          dispatch(domainListActions.setCurveTypes(json.data.curveTypes));
        }
        if (json.data.days) {
          dispatch(domainListActions.setDays(json.data.days));
        }
        if (json.data.durations) {
          dispatch(domainListActions.setDurations(json.data.durations));
        }
        if (json.data.execDurations) {
          dispatch(domainListActions.setExecDurations(json.data.execDurations));
        }
        if (json.data.frequencyTypes) {
          dispatch(
            domainListActions.setFrequencyTypes(json.data.frequencyTypes)
          );
        }
        if (json.data.hours) {
          dispatch(domainListActions.setHours(json.data.hours));
        }
        if (json.data.importanceDistributions) {
          dispatch(
            domainListActions.setImportanceDistributions(
              json.data.importanceDistributions
            )
          );
        }
        if (json.data.roles) {
          dispatch(domainListActions.setRoles(json.data.roles));
        }
        if (json.data.minutes) {
          dispatch(domainListActions.setMinutes(json.data.minutes));
        }
        if (json.data.spatialTypes) {
          dispatch(domainListActions.setSpatialTypes(json.data.spatialTypes));
        }
        if (json.data.statisticTypes) {
          dispatch(
            domainListActions.setStatisticTypes(json.data.statisticTypes)
          );
        }
        if (json.data.nodeLevels) {
          dispatch(domainListActions.setNodeLevels(json.data.nodeLevels));
        }
        if (json.data.modelSaveOptions) {
          dispatch(domainListActions.setModelSaveOptions(json.data.modelSaveOptions))
        }
        if (json.data.templateScalingTypes) {
          dispatch(
            domainListActions.setTemplateScalingTypes(
              json.data.templateScalingTypes
            )
          );
        }
        if (json.data.unitSystems) {
          dispatch(domainListActions.setUnitSystems(json.data.unitSystems));
        }
        if (json.data.variableStatistics) {
          dispatch(
            domainListActions.setVariableStatistics(
              json.data.variableStatistics
            )
          );
        }
        if (json.data.distributionTypes) {
          dispatch(
            domainListActions.setDistributionTypes(json.data.distributionTypes)
          );
        }
        if (json.data.sampleTypeFrequencyTypes) {
          dispatch(
            domainListActions.setSampleTypeFrequencyTypes(json.data.sampleTypeFrequencyTypes)
          );
        }
      }
    } catch (error) {
      dispatch(domainListActions.setListError(error));
    }
  };

  return {
    componentTypes,
    curveTypes,
    days,
    durations,
    execDurations,
    frequencyTypes,
    hours,
    importanceDistributions,
    minutes,
    spatialTypes,
    statisticTypes,
    templateScalingTypes,
    unitSystems,
    variableStatistics,
    distributionTypes,
    nodeLevels,
    modelSaveOptions,
    sampleTypeFrequencyTypes,
    roles,
    fetchLists,
  };
};
