import { useContext } from "react";
import { DispatchStore, StateStore } from "../store/GlobalStore";
import { apiRoot, pfToolsRoot } from "../services/api";
import {
  precipFrequencyActions,
  precipFrequencyInitialState,
  normalizer,
} from "../reducers/precipFrequencyReducer";
import { normalize } from "../util/normalize";
import { userActions } from "../reducers/userReducer";
import { shadeBetweenConfidenceTraces } from "../util/plotlytrace";

export const LowerCI = "Lower CI";
export const UpperCI = "Upper CI";
export const Expected = "Expected";
export const Computed = "Computed";
export const CI = "Confidence Interval"

export const usePrecipFrequencyAPI = () => {
  const { state } = useContext(StateStore);
  const { dispatch } = useContext(DispatchStore);
  const { item, list, error, savepoint } = state.precipFrequency;
  const analysis = state.analysis.savepoint;

  const fileUpload = async (precipFrequencyId, fileItem) => {
    dispatch(precipFrequencyActions.fileUploadError(null));
    try {
      let formData = new FormData();
      formData.append("file", fileItem);
      const res = await fetch(`${apiRoot}/curves/convert/`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(precipFrequencyActions.setListError(json.error));
      } else {
        dispatch(precipFrequencyActions.setCurves(json.data));
      }
    } catch (error) {
      dispatch(precipFrequencyActions.setListError(error));
    }
  };

  const processA14 = async () => {
    let data = normalize(normalizer, item);
    delete data.bootstrap.erl;
    delete data.bootstrap.distributionParameters;
    if (data.bootstrap.rngSeed === '') {
      delete data.bootstrap.rngSeed;
    }
    if (item.useArealReductionFactorList) {
      const arealReductionFactorList = item.arealReductionFactorList
      if (arealReductionFactorList?.length > 0) {
        delete data.arealReductionFactor;
      }
    }
    if (!item.useArealReductionFactorList || (item.useArealReductionFactorList && item.arealReductionFactorList?.length > 0)) {
      try {
        const res = await fetch(`${pfToolsRoot}/process_a14/`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${state.user.token}`,
            "Content-Type": "application/json",
          },
        });
        const json = await res.json();
        if (res.status === 401) {
          window.localStorage.removeItem("rrftToken");
          dispatch(userActions.userError("Incorrect email or password"));
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
        } else if (res.status === 403) {
          dispatch(
            userActions.userError(
              "You do not have the appropriate permissions to view / use this resource."
            )
          );
        } else if (json.detail) {
          dispatch(precipFrequencyActions.setItemError(json.detail));
        } else {
          if (json.bootstrap && json.bootstrap.rngSeed === null) {
            json.bootstrap.rngSeed = ''
          }
          dispatch(precipFrequencyActions.bootstrap.processA14Grids(json));
        }
      } catch (error) {
        dispatch(precipFrequencyActions.setItemError(error));
      }
    } else {
      alert("Please import a list of Areal Reduction Factors.")
    }
  };


  const transformDataToSeriesPlotly = (curves) => {
    let seriesMap = new Map();
    // O(n)
    for (let curve of curves) {
      if (
        curve.curveType && 
        curve.probability && 
        curve.value && 
        curve.probability !== "" && 
        curve.value !== "" &&
        parseFloat(curve.probability) < 1 && 
        parseFloat(curve.probability) > 0 && 
        parseFloat(curve.value) > 0
        ) {
        if (seriesMap.has(curve.curveType)) {
          seriesMap.get(curve.curveType).x.push(parseFloat(curve.probability));
          seriesMap.get(curve.curveType).y.push(parseFloat(curve.value));
          seriesMap.get(curve.curveType).id.push(curve.id);
        } else {
          seriesMap.set(curve.curveType, {
            name: curve.curveType,
            type: "scatter",
            mode: curve.curveType.includes("A14") ? "markers" : "markers+lines",
            marker: curve.curveType.includes("A14") ? { size: 8 } : { size: 6 },
            x: [parseFloat(curve.probability)],
            y: [parseFloat(curve.value)],
            id: [curve.id]
          });
        }
      }
    }
    seriesMap = shadeBetweenConfidenceTraces(seriesMap)
    const series = [...seriesMap.values()];
    return series
  };

  const transformDataToSeries = (curves) => {
    const seriesMap = {};
    // O(n)
    for (let curve of curves) {
      curve.x = curve.probability;
      curve.y = curve.value;
      if (seriesMap[curve.curveType]) {
        seriesMap[curve.curveType].data.push(curve);
      } else {
        seriesMap[curve.curveType] = {
          name: curve.curveType,
          type: curve.curveType.includes("A14") ? "scatter" : "line",
          marker: curve.curveType.includes("A14")
            ? { enabled: true }
            : { enabled: false },
          data: [curve],
        };
      }
    }
    const series = Object.values(seriesMap);
    // modify series in place to sort ascending
    // O(n)
    for (let i = 0; i < series.length; i++) {
      series[i].data.sort((a, b) => a.x - b.x);
    }

    // remove values rarer than 1E-8
    // this can be removed after PFTools is updated and return "series" above
    var newSeries = [];
    for (let i = 0; i < series.length; i++) {
      var s = series[i].data.filter((d) => d.x > 1e-10);
      newSeries.push({ ...series[i], data: s });
    }
    return newSeries;
  };

  const a14Bootstrap = async () => {
    let data = normalize(normalizer, item);
    if (data.bootstrap.rngSeed === '') {
      delete data.bootstrap.rngSeed;
    }
    try {
      const res = await fetch(`${pfToolsRoot}/bootstrap_a14/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${state.user.token}`,
          "Content-Type": "application/json",
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.detail) {
        dispatch(precipFrequencyActions.setItemError(json.detail));
      } else {
        dispatch(precipFrequencyActions.setCurves(json.curves));
      }
    } catch (error) {
      dispatch(precipFrequencyActions.setItemError(error));
    }
  };

  const bootstrap = async () => {
    let data = normalize(normalizer, item);
    try {
      const res = await fetch(`${pfToolsRoot}/distribution_bootstrap/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${state.user.token}`,
          "Content-Type": "application/json",
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.detail) {
        dispatch(precipFrequencyActions.setItemError(json.detail));
      } else {
        dispatch(precipFrequencyActions.setCurves(json.curves));
      }
    } catch (error) {
      dispatch(precipFrequencyActions.setItemError(error));
    }
  };

  const shapeFileUpload = async (fileItem) => {
    dispatch(precipFrequencyActions.fileUploadError(null));
    try {
      let formData = new FormData();
      formData.append("file", fileItem);
      const res = await fetch(`${pfToolsRoot}/shape_to_geojson/`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.detail) {
        dispatch(precipFrequencyActions.setItemError(json.detail));
      } else {
        dispatch(precipFrequencyActions.a14.setFeature(json));
      }
    } catch (error) {
      dispatch(precipFrequencyActions.setItemError(error));
    }
  };

  const fetchList = async (analysisId) => {
    try {
      const res = await fetch(
        `${apiRoot}/analyses/${analysisId}/frequencies/`,
        {
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        }
      );
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(precipFrequencyActions.setListError(json.error));
      }
      dispatch(precipFrequencyActions.setList(json.data));
    } catch (error) {
      dispatch(precipFrequencyActions.setListError(error));
    }
  };

  const fetchItemFromRow = async (row) => {
    try {
      const res = await fetch(`${apiRoot}/frequencies/${row.original.id}/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      dispatch(precipFrequencyActions.setItem(json.data));
      dispatch(precipFrequencyActions.setSavepoint(json.data))
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(precipFrequencyActions.setItemError(json.error));
      }
    } catch (error) {
      dispatch(precipFrequencyActions.setItemError(error));
    }
  };

  const fetchItem = async (itemId) => {
    try {
      const res = await fetch(`${apiRoot}/frequencies/${itemId}/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(precipFrequencyActions.setItemError(json.error));
      }
      return json.data
    } catch (error) {
      dispatch(precipFrequencyActions.setItemError(error));
    }
  };

  const deleteItem = async (id) => {
    dispatch(precipFrequencyActions.setItemError(null));
    try {
      const res = await fetch(`${apiRoot}/frequencies/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(precipFrequencyActions.deleteItemError(json.error));
      } else {
        dispatch(precipFrequencyActions.deleteItem(id));
      }
    } catch (error) {
      dispatch(precipFrequencyActions.deleteItemError(error));
    }
  };

  const availableA14 = async () => {
    try {
      const res = await fetch(`${pfToolsRoot}/available_a14/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
          "Content-Type": "application/json",
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.detail) {
        dispatch(precipFrequencyActions.setItemError(json.detail));
      }
      return json.data;
    } catch (error) {
      precipFrequencyActions.setItemError(error);
    }
  };

  const updateItem = async (analysisId) => {
    if (validateItem(item.errors)) {
      dispatch(precipFrequencyActions.setItemError(null));
      try {
        let data = normalize(normalizer, item);
        const res = await fetch(
          `${apiRoot}/analyses/${analysisId}/frequencies/`,
          {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              Authorization: `Bearer ${state.user.token}`,
            },
          }
        );
        const json = await res.json();
        if (res.status === 401) {
          window.localStorage.removeItem("rrftToken");
          dispatch(userActions.userError("Incorrect email or password"));
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
        } else if (res.status === 403) {
          dispatch(
            userActions.userError(
              "You do not have the appropriate permissions to view / use this resource."
            )
          );
        } else if (json.error) {
          dispatch(precipFrequencyActions.setItemError(json.error));
        } else {
          dispatch(precipFrequencyActions.setItem(json.data));
          dispatch(precipFrequencyActions.setSavepoint(json.data));
          dispatch(precipFrequencyActions.updateListOnUpdate(json.data));
        }
      } catch (error) {
        dispatch(precipFrequencyActions.setItemError(error));
      }
    }
  };

  const createItem = async (analysisId, newName) => {
    if (validateItem(item.errors)) {
      dispatch(precipFrequencyActions.setItemError(null));
      try {
        let data = normalize(normalizer, item);
        if (newName) {
          delete data.id;
          data.name = newName;
        }
        if (data.arealReductionFactor === null) {
          delete data.arealReductionFactor
        }
        const res = await fetch(
          `${apiRoot}/analyses/${analysisId}/frequencies/`,
          {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              Authorization: `Bearer ${state.user.token}`,
            },
          }
        );
        const json = await res.json();
        if (res.status === 401) {
          window.localStorage.removeItem("rrftToken");
          dispatch(userActions.userError("Incorrect email or password"));
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
        } else if (res.status === 403) {
          dispatch(
            userActions.userError(
              "You do not have the appropriate permissions to view / use this resource."
            )
          );
        } else if (json.error) {
          dispatch(precipFrequencyActions.setItemError(json.error));
        } else {
          dispatch(precipFrequencyActions.setItem(json.data));
          dispatch(precipFrequencyActions.setSavepoint(json.data));
          dispatch(precipFrequencyActions.updateListOnCreate(json.data));
        }
      } catch (error) {
        dispatch(precipFrequencyActions.setItemError(error));
      }
    }
  };
  const onChange = (e) => dispatch(precipFrequencyActions.handleChange(e));
  const onA14Change = (e) =>
    dispatch(precipFrequencyActions.a14.handleChange(e));
  const onSpatialTypeChange = (e) =>
    dispatch(precipFrequencyActions.handleSpatialTypeChange(e));
  const onA14DurationChange = (duration) =>
    dispatch(precipFrequencyActions.a14.setDuration(duration));
  const onFrequencyTypeChange = (e) => {
    if (item) {
      let persistData = { name: item.name, description: item.description, errors:{name: item.errors.name, description: item.errors.description} };
      if (item.id && analysis.id) {
        fetchItem(item.id).then((data) => {
          dispatch(precipFrequencyActions.handleFrequencyTypeChange(e));
          dispatch(precipFrequencyActions.mergeItem({ ...data, ...persistData }))
        });
      } else {
        dispatch(precipFrequencyActions.handleFrequencyTypeChange(e));
        dispatch(precipFrequencyActions.mergeItem({...persistData}))
      }
    }
  };
  const onBootstrapChange = (e) =>
    dispatch(precipFrequencyActions.bootstrap.handleChange(e));
  const setBootstrapDefault = (field) => {
    dispatch(precipFrequencyActions.bootstrap.setBootstrapDefault(field))
  }
  const setDistribution = (dist) =>
    dispatch(precipFrequencyActions.bootstrap.setDistribution(dist));
  const handleParameterChange = (e) =>
    dispatch(precipFrequencyActions.bootstrap.handleParameterChange(e));
  const newItem = () => {
    dispatch(
      precipFrequencyActions.setItem(
        JSON.parse(JSON.stringify(precipFrequencyInitialState.item))
      )
    );
    dispatch(precipFrequencyActions.setItemError(null))
    dispatch(precipFrequencyActions.setSavepoint(precipFrequencyInitialState.item))
  };
  const addCurve = (curve) => {
    if (!curve.curveType) {
      curve.curveType = "Expected"
    }
    dispatch(precipFrequencyActions.addCurve(curve))
  };
  const editCurve = (curve, index) => {
    if (!curve.curveType) {
      curve.curveType = "Expected"
    }
    dispatch(precipFrequencyActions.editCurve(curve, index))
  };
  const deleteCurve = (index) =>
    dispatch(precipFrequencyActions.deleteCurve(index));
  const validateItem = (errors) => {
    let valid = true;
    Object.values(errors).forEach((v) => v && v.length > 0 && (valid = false));
    return valid;
  };

  const onUseArfListCheckboxChange = (e) => {
    dispatch(precipFrequencyActions.handleUseArfChange(e));
  }
  const onArealReductionFactorListChange = (arealReductionFactorList) =>
    dispatch(precipFrequencyActions.setArealReductionFactorList(arealReductionFactorList));
  const addArf = (arf) => dispatch(precipFrequencyActions.addArf(arf));
  const editArf = (arf, index) => dispatch(precipFrequencyActions.editArf(arf, index));
  const deleteArf = (index) =>
    dispatch(precipFrequencyActions.deleteArf(index));
  const setList = (lst) => dispatch(precipFrequencyActions.setList(lst));
  const resetState = () => dispatch(precipFrequencyActions.resetState());
  const setSavepoint = (item) => dispatch(precipFrequencyActions.setSavepoint(item));

  return {
    item,
    list,
    error,
    savepoint,
    fileUpload,
    shapeFileUpload,
    availableA14,
    fetchList,
    fetchItem,
    fetchItemFromRow,
    validateItem,
    addCurve,
    editCurve,
    deleteCurve,
    deleteItem,
    createItem,
    updateItem,
    onChange,
    transformDataToSeries,
    transformDataToSeriesPlotly,
    onBootstrapChange,
    setDistribution,
    setBootstrapDefault,
    handleParameterChange,
    onA14Change,
    onA14DurationChange,
    onSpatialTypeChange,
    onFrequencyTypeChange,
    processA14,
    a14Bootstrap,
    bootstrap,
    newItem,
    analysis,
    onUseArfListCheckboxChange,
    onArealReductionFactorListChange,
    addArf,
    editArf,
    deleteArf,
    setList,
    resetState,
    setSavepoint,
  };
};
