import "../css/Home.css";
import React from "react";
import { Container, Row, Col } from "reactstrap";

const webRoot = process.env.REACT_APP_WEB_CONTEXT === "/" ? "" : process.env.REACT_APP_WEB_CONTEXT

export const Home = () => (
  <Container className="marketing pt-5 pb-5">
    <Row>
    <h2 className="text-center">Update: On 4/10/2024 RRFT started using HEC-HMS v4.11.</h2>
    </Row>
    <Row className="featurette">
      <Col md={{ size: 5, order: 2, offset: 1 }}>
        <h2 className="featurette-heading mb-4 text-center">
          Rainfall Runoff Frequency Tool (RRFT)
								</h2>
        <p className="lead text-center">
          The RRFT is a web-based stochastic rainfall runoff frequency
          tool that can be used to develop screening and intermediate
          level hydrologic hazard information for Dam and Levee Safety
          risk assessments. The RRFT employs a precipitation-driven
          approach for developing reservoir flow-, volume-, and
          stage-frequency curves.
								</p>
      </Col>
      <Col md={{ size: 6, order: 1 }} className="mt-5">
        <img
          src={`${webRoot}/img/home-1.png`}
          alt="Screen shot of map results from RRFT"
          className="featurette-image img-fluid shadow rounded"
        />
      </Col>
    </Row>
    <hr className="featurette-divider mb-5"></hr>
    <Row>
      <Col md={{ size: 12 }} className="mb-4">
        <p className="text-center lead">
          The RRFT provides an intuitive step-by-step process for rapid
          hydrologic hazard curve development.
                  <br />
									There are five main steps in the process.
								</p>
      </Col>
    </Row>
    <Row>
      <Col md={{ size: 12, order: 1 }} className="mb-4">
        <div className="card border-light lead">
          <div className="card-header font-weight-bold">
            1. Precipitation
									</div>
          <div className="card-body">
            <p className="card-text">
              There are three methods available for developing a
              precipitation frequency curve.
										</p>
            <ol type="a" className="card-text">
              <li className="mb-2">
                <u>NOAA Atlas 14 (A14):</u> A precipitation-frequency
												curve can be automatically extracted from A14 and areal
												reduction can be applied. RRFT will fit an analytical
												distribution to the A14 data and estimate uncertainty
												for return periods rarer than 1:1,000.
											</li>
              <li className="mb-2">
                <u>Empirical:</u> The user can directly enter the
												expected precipitation-frequency curve as precipitation
												vs. depth data.
											</li>
              <li className="mb-2">
                <u>Analytical:</u> The user can enter an analytical
												distribution
											</li>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
    <Row>
      <Col md={{ size: 6, order: 1 }} className="mb-4">
        <div className="card border-light lead h-100">
          <div className="card-header font-weight-bold">
            2. Sampling
									</div>
          <div className="card-body">
            <p className="card-text">
              {/* TODO: update description to include new sample types */}
              The precipitation-frequency distribution is sampled using
              an importance and stratified sampling approach. This
              requires the user to select the number of bins, the number
              of events that should be sampled per bin and the
              importance distribution.
										</p>
          </div>
        </div>
      </Col>
      <Col md={{ size: 6, order: 2 }} className="mb-4">
        <div className="card border-light lead h-100">
          <div className="card-header font-weight-bold">
            3. HEC-HMS Model
									</div>
          <div className="card-body">
            <p className="card-text">
              The user will upload a calibrated HEC-HMS model which will
              be used to route the sampled rainfall events through the
              hydrologic network.
										</p>
          </div>
        </div>
      </Col>
    </Row>
    <Row>
      <Col md={{ size: 6, order: 1 }}>
        <div className="card border-light lead h-100">
          <div className="card-header font-weight-bold">
            4. Execution
									</div>
          <div className="card-body">
            <p className="card-text">
              When the analysis is executed, each sampled rainfall depth
              is routed through the user-provided HEC-HMS model. The
              user selects inputs to be used as well as outputs
              locations and statistics.
										</p>
          </div>
        </div>
      </Col>
      <Col md={{ size: 6, order: 2 }}>
        <div className="card border-light lead h-100">
          <div className="card-header font-weight-bold">5. Results</div>
          <div className="card-body">
            <p className="card-text">
              After each sampled rainfall event has been routed through
              the HEC-HMS model, the RRFT will then automatically
              post-process the results and construct flow-, volume-
              and/or stage-frequency curves at user specified locations.
										</p>
          </div>
        </div>
      </Col>
    </Row>
    <hr className="featurette-divider mb-5"></hr>
    <Row>
      <Col md={{ size: 12 }} className="mb-4">
        <p className="text-center lead">
          Example graphs of precipitation depth, dam inflow, and
          incremental precipitation from the RRFT
								</p>
      </Col>
    </Row>
    <Row>
      <Col md={{ size: 6, order: 1 }} className="mb-4">
        <img
          src={`${webRoot}/img/home-2.png`}
          alt="Example of RRFT graph"
          className="img-fluid shadow rounded border mb-4 h-100"
        />
      </Col>
      <Col md={{ size: 6, order: 2 }} className="mb-4">
        <img
          src={`${webRoot}/img/home-3.png`}
          alt="Example of RRFT graph"
          className="img-fluid shadow rounded mb-4 border h-100"
        />
      </Col>
    </Row>
    <Row>
      <Col md={{ size: 6, order: 1 }} className="mb-4">
        <img
          src={`${webRoot}/img/home-4.png`}
          alt="Example of RRFT graph"
          className="img-fluid shadow rounded border mb-4 h-100"
        />
      </Col>
      <Col md={{ size: 6, order: 2 }} className="mb-4">
        <img
          src={`${webRoot}/img/home-5.png`}
          alt="Example of RRFT graph"
          className="img-fluid shadow rounded border mb-4 h-100"
        />
      </Col>
    </Row>
  </Container>
);
