import React, { useState, useEffect, useContext } from "react";
import {
  createColumnHelper
} from '@tanstack/react-table';
import { 
  Row, 
  Col, 
  Button 
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faPlus, 
  faTrashAlt 
} from "@fortawesome/free-solid-svg-icons";
import { ListTable } from "./ListTable";
import { ActionModal } from "./ActionModal";
import { SamplingForm } from "./SamplingForm";
import { useSamplingAPI } from "../hooks/samplingAPI";
import { useLoading } from "../hooks/Loading";
import Loader from "./Loader";
import isEqual from "lodash.isequal"
import { Prompt } from "./Prompt";
import { useDependencyAPI } from "../hooks/dependencyAPI";
import { usePrecipFrequencyAPI } from "../hooks/precipFrequencyAPI";
import { StateStore } from "../store/GlobalStore";
import { useDomainListAPI } from "../hooks/domainListAPI";

export const SamplingList = ({ analysisId }) => {
  const [isLoading, load] = useLoading();
  const {
    dependencyTypes, 
    fetchDependencies,
  } = useDependencyAPI()
  const {
    item,
    list,
    error,
    savepoint,
    fetchList,
    deleteItem,
    fetchItem,
    newItem,
    fetchBootstrapForSample,
    resetState,
  } = useSamplingAPI();
  const { fetchList: fetchPrecipList } = usePrecipFrequencyAPI();
  const { curveTypes, importanceDistributions } = useDomainListAPI();
  const { state } = useContext(StateStore); 
  const { list: precipList } = state.precipFrequency;
  const [modalShow, setModalShow] = useState(false);
  const [itemToDelete, setItemToDelete] = useState();
  const [deletionWarning, setDeletionWarning] = useState("")
  const promptTitle = "Unsaved Changes"
  const promptMessage = "Are you sure you wish to leave without saving?  All unsaved data from the Sampling page will be deleted."

  const promptToDelete = async (row) => {
    const dependencies = await fetchDependencies(row.original.id, dependencyTypes.SAMPLE)
    setDeletionWarning(generateDeletionWarning(dependencies))
    setItemToDelete(row.original.id);
    setModalShow(!modalShow);
  };
  const callDelete = async () => {
    await load(deleteItem(itemToDelete));
    if (!error) {
      setModalShow(!modalShow);
      load(fetchList(analysisId));
      newItem();
    }
  };
  const callFetch = async (row) => {
    if (row.original.id) {
      await load(fetchItem(row.original.id));
      fetchBootstrapForSample(row.original.frequencyId)
    }
  };
  // bootstrap is currently stored in item even though it's from precipitation
  // here we delete it when checking for dirty forms, but this should be moved out of item in the future
  // todo: move bootstrap errors out of sampling item
  const cleanItem = (item) => {
    if (item == null) return null
    const newItem = JSON.parse(JSON.stringify(item))
    delete newItem.errors?.bootstrap
    delete newItem.realizations
    return newItem
  };
  useEffect(() => {
    if (analysisId) {
      load(fetchList(analysisId));
      load(fetchPrecipList(analysisId))
      newItem();
    }
    // eslint-disable-next-line
  }, [analysisId]);
  useEffect(() => {
    return () => resetState()
    // eslint-disable-next-line
  }, [])

  const generateDeletionWarning = (dependencies) => {
    if (dependencies.error) {
      return (
        <div>
          <p>Are you sure you wish to continue?</p>
          <p className='text-danger'>Error retrieving sample dependency list:</p>
          <p>{dependencies.error.toString()}</p>
          <br/>
          <p className='text-danger'>Deleting a sample without knowledge of its associated items is not recommended!</p>
        </div>
      )
    }
    const sampledExecutions = dependencies.sampledExecutions
    if (!sampledExecutions){
      return <span>Are you sure you wish to continue? All data for the sample will be deleted from the database.</span>
    }
    return (
      <div>
        <p>Are you sure you wish to continue?</p>
        <p>All data for the sample will be deleted from the database.</p>
        <p>The following items will also be deleted because they depend on this sample.</p>
        <p><b>Sampled Executions </b> ({sampledExecutions.length})</p>
        <ul>{sampledExecutions.map((ex) => <li>{ex.name}</li>)}
        </ul>
      </div>
    )
  }

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => (
        <button
          className="hyperlinkButton"
          title="Edit Sampling"
          onClick={() => callFetch(info.row)}
        >
          {info.getValue()}
        </button>
      ),
      header: "Name",
    }),
    columnHelper.accessor("sampleTypeName", {
      header: "Sample Type",
    }),
    columnHelper.accessor(
      (row) => precipList.find((f) => f.id === row.frequencyId)?.frequencyType,
      {
        header: "Frequency",
      }
    ),
    columnHelper.accessor(
      (row) =>
        row.curveTypeId
          ? curveTypes.find((c) => c.id === row.curveTypeId)?.name
          : "NA",
      {
        header: "Curve",
      }
    ),
    columnHelper.accessor(
      (row) =>
        row.distributionTypeId
          ? importanceDistributions.find((d) => d.id === row.distributionTypeId)
              ?.name
          : "NA",
      {
        header: "Distribution",
      }
    ),
    columnHelper.accessor("numBins", {
      header: "Bins",
    }),
    columnHelper.accessor("numSamples", {
      header: "Events Per Bin",
    }),
    columnHelper.accessor("id", {
      cell: (info) => (
        <button
          className="hyperlinkButton float-end"
          title="Delete Sampling"
          onClick={() => promptToDelete(info.row)}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      ),
      header: "",
    }),
  ];

  // const listExists = list && list.length > 0;
  return (
    <React.Fragment>
      <Row>
        <Col md="12 text-end" className="mb-2">
          <Button
            color="default"
            size="sm"
            title="Add a new Sampling"
            onClick={newItem}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>Add New</span>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md="12">
        <ListTable data={list} columns={columns} />
        </Col>
      </Row>
      <ActionModal
        isOpen={modalShow}
        toggle={() => setModalShow(!modalShow)}
        header={"Delete Sampling"}
        body={deletionWarning}
        action={callDelete}
      />
      <SamplingForm analysisId={analysisId} />
      <Loader loading={isLoading} />
      <Prompt header={promptTitle} message={promptMessage} shouldPrompt={!isEqual(cleanItem(savepoint), cleanItem(item))}/>
    </React.Fragment>
  );
};
