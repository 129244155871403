import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faSave, faCheck } from "@fortawesome/free-solid-svg-icons";
import { ButtonNavigation } from "./ButtonNavigation";
import { useDomainListAPI } from "../hooks/domainListAPI";
import { useAnalysisAPI } from "../hooks/analysisAPI";
import { useUsers } from "../hooks/userApi";
import { checkErrors } from "../util/validation";
import { useLoading } from "../hooks/Loading";
import Loader from "./Loader";
import isEqual from "lodash.isequal"
import { Prompt } from "./Prompt";

const webRoot = process.env.REACT_APP_WEB_CONTEXT === "/" ? "" : process.env.REACT_APP_WEB_CONTEXT;

const UserDropdown = () => {
  const [isLoading, load] = useLoading();
  const { onChange, item } = useAnalysisAPI();
  const { list, fetchList } = useUsers();
  useEffect(() => {
    load(fetchList())
    // eslint-disable-next-line
  }, []);
  return (
    <React.Fragment>
      <FormGroup className="mt-3">
        <Label for="ownedBy">Owner</Label>
        <select
          className="form-control"
          name="ownedBy"
          value={item.ownedBy}
          onChange={onChange}
        >
          <option></option>
          <option value=""></option>
          {list.map((u) => (
            <option key={u.id} value={u.email}>
              {u.email}
            </option>
          ))}
        </select>
      </FormGroup>
      <Loader loading={isLoading} />
    </React.Fragment>
  );
};

export const General = () => {
  const [isLoading, load, sleep] = useLoading();
  const { unitSystems } = useDomainListAPI();
  const { isAdmin } = useUsers();
  const { item, error, savepoint, fetchItem, updateItem, createItem, onChange, resetState } = useAnalysisAPI();
  const [modalShow, setModalShow] = useState(false);
  const [validationError, setValidationError] = useState("");
  const isValid = useMemo(() => checkErrors(item.errors), [item]);
  const promptTitle = "Unsaved Changes"
  const promptMessage = "Are you sure you wish to leave without saving?  All unsaved data from the General page will be deleted."
  const navigate = useNavigate();
  
  const save = async () => {
    if (isValid) {
      setValidationError("");
      if (item.id) {
        await load(updateItem(item.id));
      } else {
        await load(createItem());
      }
      if (!error) {
        setModalShow(!modalShow);
        await updateRoute()
      }
    } else {
      setValidationError("Please complete all required fields.");
    }
  };

  const updateRoute = async () => {
    await sleep(2000);
    setModalShow(false);
    if (item.id) {
      await navigate(`${webRoot}/analysis/${item.id}/general`);
    }
  };

  // we use savepoint since Analysis is by design always mounted
  useEffect(() => {
    if (savepoint.id) {
      fetchItem(savepoint.id);
    }
    // eslint-disable-next-line
    }, []);

  useEffect(() => {
    return () => resetState();
    // eslint-disable-next-line
  }, []);

  // const [isHovering, setIsHovering] = useState(false);

  // const handleMouseOver = () => {
  //   setIsHovering(true);
  // };

  // const handleMouseOut = () => {
  //   setIsHovering(false);
  // };

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <div className="form">
                <fieldset>
                  <legend>General</legend>
                  <Row className="mt-3">
                    <Col md="6">
                      <Row>
                        <Col md="12">
                          <FormGroup className="mt-3">
                            <Label for="name">Name</Label>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Enter analysis name"
                              name="name"
                              value={item.name}
                              onChange={onChange}
                            />
                            {item.errors.name.length > 0 && (
                              <span className="text-danger">
                                {item.errors.name}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup className="mt-3">
                            <Label for="description">Description</Label>
                            <Input
                              type="textarea"
                              rows="2"
                              className="form-control"
                              placeholder="Enter description"
                              name="description"
                              value={item.description}
                              onChange={onChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="6">
                      <Row>
                        <Col md="12">
                          <FormGroup className="mt-3">
                            <Label for="units">System of units</Label>
                            <select
                              className="form-control"
                              name="units"
                              value={item.units}
                              onChange={onChange}
                            >
                              <option value=""></option>
                              {unitSystems.map((u) => (
                                <option key={u.id} value={u.name}>
                                  {u.name}
                                </option>
                              ))}
                            </select>
                            {item.errors.units.length > 0 && (
                              <span className="text-danger">
                                {item.errors.units}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      {isAdmin() && item.id && (
                        <Row>
                          <Col md="12">
                            <UserDropdown />
                          </Col>
                        </Row>
                      )}

                    </Col>
                  </Row>
                  <legend>Display Properties</legend>
                  <Row className="mt-3">
                    <Col md="6">
                      <Row>
                        <Col md="12">
                          <FormGroup className="mt-3">
                            <Label for="decimalDigitsGeneral">Decimal Digits for General Numbers</Label>
                            <span id={'decimalDigitsGeneralInfo'}>
                              <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                            </span>
                            <UncontrolledTooltip target="decimalDigitsGeneralInfo" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
                              Number of digits to be displayed for all numeric values except AEP.
                            </UncontrolledTooltip>
                            <Input
                              type="number"
                              className="form-control"
                              placeholder="Enter number of digits for general numbers."
                              name="decimalDigitsGeneral"
                              value={item.decimalDigitsGeneral}
                              onChange={onChange}
                            />
                            <span className="text-danger">
                              {item.errors.decimalDigitsGeneral}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="6">
                      <Row>
                        <Col md="12">
                          <FormGroup className="mt-3">
                            <Label for="decimalDigitsAep">Decimal Digits for Annual Exceedance Probability Values (AEP)</Label>
                            <span id={'decimalDigitsAepInfo'}>
                              <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                            </span>
                            <UncontrolledTooltip target="decimalDigitsAepInfo" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
                              Number of digits to be displayed for all AEP values.
                            </UncontrolledTooltip>
                            <Input
                              type="number"
                              className="form-control"
                              placeholder="Enter number of digits for AEP."
                              name="decimalDigitsAep"
                              value={item.decimalDigitsAep}
                              onChange={onChange}
                            />
                            <span className="text-danger">
                              {item.errors.decimalDigitsAep}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" className="mt-3">
                      <Button
                        color="dark"
                        size="sm"
                        title="Save"
                        disabled={!isValid}
                        onClick={save}
                        id="saveButton"
                        className="me-2"
                      >
                        <FontAwesomeIcon icon={faSave} className="me-2" />
                        <span>Save</span>
                      </Button>
                      {error && (
                        <span className="text-danger">
                          <b>{error}</b>
                        </span>
                      )}
                      {validationError !== "" && (
                        <span className="text-danger">
                          <b>{validationError}</b>
                        </span>
                      )}
                      {(modalShow && !error) && (
                        <span className="text-success ms-2">
                          <FontAwesomeIcon icon={faCheck} className="me-2" />Analysis saved!
                        </span>
                      )}
                    </Col>
                  </Row>

                </fieldset>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ButtonNavigation 
        nextLink={`${webRoot}/analysis/${item.id}/precipFrequency`} 
        nextLinkTooltip="Go to Precipitation Frequency tab" 
      />
      <Loader loading={isLoading} />
      <Prompt header={promptTitle} message={promptMessage} shouldPrompt={!isEqual(savepoint, item)} />
    </React.Fragment>
  );
};