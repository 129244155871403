import React, { useState, useEffect } from "react";
import {
  createColumnHelper
} from '@tanstack/react-table';
import { 
  Row, 
  Col, 
  Button 
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faPlus, 
  faTrashAlt 
} from "@fortawesome/free-solid-svg-icons";
import { ListTable } from "./ListTable";
import { ActionModal } from "./ActionModal";
import { PrecipFrequencyForm } from "./PrecipFrequencyForm";
import { usePrecipFrequencyAPI } from "../hooks/precipFrequencyAPI";
import { useDependencyAPI } from "../hooks/dependencyAPI";
import { useLoading } from "../hooks/Loading";
import Loader from "./Loader";
import isEqual from "lodash.isequal"
import { Prompt } from "./Prompt"

export const PrecipFrequencyList = ({ analysisId }) => {
  const [isLoading, load] = useLoading();
  const {
    dependencyTypes, 
    fetchDependencies,
  } = useDependencyAPI()
  const {
    item,
    list,
    error,
    savepoint,
    fetchList,
    deleteItem,
    fetchItemFromRow,
    newItem,
    setList,
    resetState,
  } = usePrecipFrequencyAPI();
  const [modalShow, setModalShow] = useState(false);
  const [itemToDelete, setItemToDelete] = useState();
  const promptTitle = "Unsaved Changes"
  const promptMessage = "Are you sure you wish to leave without saving?  All unsaved data from the Precipitation Frequency page will be deleted."
  const [deletionWarning, setDeletionWarning] = useState("")
  const promptToDelete = async (row) => {
    const dependencies = await fetchDependencies(row.original.id, dependencyTypes.FREQUENCY)
    setDeletionWarning(generateDeletionWarning(dependencies))
    setItemToDelete(row.original.id);
    setModalShow(!modalShow);
  };
  const callDelete = async () => {
    await load(deleteItem(itemToDelete));
    if (!error) {
      setModalShow(!modalShow);
      newItem();
    }
  };
  const callFetch = (row) => {
    if (row.original.id) {
      // Reset the Distribution Parameters to use the Formatted input for display.
      for (let index = 0; index < 3; index++) {
        const elInput = document.getElementById(index + '')
        if (elInput) {
          elInput.style.display = "none";
        }
        const elText = document.querySelector('#formatted-' + index)
        if (elText) {
          elText.style.display = "inline";
        }
      }
      const bootstrapFieldsToReset = ['minAep', 'maxAep']
      for (let j = 0; j < bootstrapFieldsToReset.length; j++) {
        const name = bootstrapFieldsToReset[j]
        const elInput = document.getElementById(name)
        if (elInput) {
          elInput.style.display = "none";
        }
        const elText = document.querySelector('#formatted-' + name)
        if (elText) {
          elText.style.display = "inline";
        }
      }
      load(fetchItemFromRow(row));
    }
  };
  useEffect(() => {
    if (analysisId) {
      load(fetchList(analysisId));
      newItem();
    } else {
      setList([])
    }
    // eslint-disable-next-line
  }, [analysisId]);
  useEffect(() => {
    return () => resetState();
    // eslint-disable-next-line
  }, []);

  const generateDeletionWarning = (dependencies) => {
    if (dependencies.error) {
      return (
        <div>
          <p>Are you sure you wish to continue?</p>
          <p className='text-danger'>Error retrieving frequency dependency list:</p>
          <p>{dependencies.error.toString()}</p>
          <br/>
          <p className='text-danger'>Deleting a frequency without knowledge of its associated items is not recommended!</p>
        </div>
      )
    }
    const samples = dependencies.samples
    const sampledExecutions = dependencies.sampledExecutions
    const singleExecutions = dependencies.singleExecutions
    if (!sampledExecutions && !singleExecutions && !samples){
      return <span>Are you sure you wish to continue? All data for the precipitation frequency will be deleted from the database.</span>
    }
    return (
      <div>
        <p>Are you sure you wish to continue?</p>
        <p>All data for the frequency will be deleted from the database.</p>
        <p>The following items will also be deleted because they depend on this frequency.</p>
        { samples &&
          <React.Fragment>
            <p><b>Samples </b> ({samples.length})</p>
            <ul>{samples.map((s) => <li>{s.name}</li>)}</ul>
          </React.Fragment>
        }        
        { sampledExecutions &&
          <React.Fragment>
            <p><b>Sampled Executions </b> ({sampledExecutions.length})</p>
            <ul>{sampledExecutions.map((ex) => <li>{ex.name}</li>)}</ul>
          </React.Fragment>
        }
        { singleExecutions &&
          <React.Fragment>
            <p><b>Single Executions </b> ({singleExecutions.length})</p>
            <ul>{singleExecutions.map((ex) => <li>{ex.name}</li>)}
            </ul>
          </React.Fragment>
        }
        
      </div>
    )
  }

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => (
        <button
          className="hyperlinkButton"
          title="Edit precipitation frequency"
          onClick={() => callFetch(info.row)}
        >
          {info.getValue()}
        </button>
      ),
      header: "Name",
    }),
    columnHelper.accessor("description", {
      header: "Description",
    }),
    columnHelper.accessor("spatialType", {
      header: "Spatial Type",
    }),
    columnHelper.accessor("arealReductionFactor", {
      header: "Areal Reduction Factor",
    }),
    columnHelper.accessor("frequencyType", {
      header: "Frequency Type",
    }),
    columnHelper.accessor("id", {
      cell: (info) => (
        <button
          className="hyperlinkButton float-end"
          title="Delete precipitation frequency"
          onClick={() => promptToDelete(info.row)}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      ),
      header: "",
    }),
  ];

  // const listExists = list && list.length > 0;
  return (
    <React.Fragment>
      <Row>
        <Col md="12 text-end" className="mb-2">
          <Button
            color="default"
            size="sm"
            title="Add a new precipitation frequency"
            onClick={newItem}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>Add New</span>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md="12">
        <ListTable data={list} columns={columns} />
        </Col>
      </Row>
      <ActionModal
        isOpen={modalShow}
        toggle={() => setModalShow(!modalShow)}
        header={"Delete Precipitation Frequency"}
        body={deletionWarning}
        action={callDelete}
      />
      <PrecipFrequencyForm analysisId={analysisId} />
      <Loader loading={isLoading} />

      <Prompt header={promptTitle} message={promptMessage} shouldPrompt={!isEqual(savepoint, item)} />

    </React.Fragment>
  );
};
