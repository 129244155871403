import React, { useEffect } from "react";
import "../css/ProgressBar.css";
import { useSSE } from "react-hooks-sse";
import { Progress } from "reactstrap";
import { events } from "../services/api";
import { Duration } from "luxon"
import { useExecuteSampledAPI } from "../hooks/executeSampledAPI";
import { useExecuteSingleAPI } from "../hooks/executeSingleAPI";

export function ProgressBar({
  updateStatusFunction,
  initialProgress,
  modelResponseHandler,
  executionType,
}) {
  const { sampledItem: item, setSampledItemError } = useExecuteSampledAPI();
  const { setSingleItemError } = useExecuteSingleAPI();

  const status = useSSE(
    events.Promoted,
    {
      value: { id: "", status: "" }
    },
    {
      stateReducer(state, changes) {
        let newState = {
          ...state,
          value: changes.data,
        };
        return newState;
      },
      parser(input) {
        return JSON.parse(input);
      },
    }
  );
  const progress = useSSE(
    events.Progress,
    {
      value: initialProgress ? initialProgress : 0,
    },
    {
      stateReducer(state, changes) {
        let newState = {
          ...state,
          value: changes.data,
        };
        return newState;
      },
      parser(input) {
        return parseFloat(input);
      },
    });
  const startTime = useSSE(
    events.StartTime,
    { value: null },
    {
      stateReducer(state, changes) {
        let newState = {
          ...state,
          value: changes.data,
        };
        return newState;
      },
      parser(input) {
        return new Date(input);
      },
    });
  // eslint-disable-next-line
  const response = useSSE(
    events.DownloadAvailable,
    {},
    {
      stateReducer(state, changes) {
        let newState = {
          ...state,
          value: changes.data,
        };
        modelResponseHandler(newState.value);
        // console.log(changes);
        return newState;
      },
    });
  const error = useSSE(
    events.ExecutionError,
    { value: null },
    {
      stateReducer(state, changes) {
        const newState = {
          ...state,
          value: changes.data,
        };
        return newState;
      },
      parser(input) {
        return JSON.parse(input);
      },
    }
  );
  function timeRemaining(startTime, progress){
    if (!progress || !startTime) return
    const elapsed = Date.now()-startTime
    const totalTime = (elapsed/progress)*100
    const remainingMillis = totalTime - elapsed
    const remaining = Duration.fromMillis(remainingMillis).shiftTo('hours', 'minutes', 'seconds', 'milliseconds').toObject()
    
    const hours = remaining.hours
    const minutes = remaining.minutes
    const seconds = remaining.seconds
    const s = (val) =>  val > 1 ? "s" : ""

    if (hours >= 1) {
      return `${hours} hour${s(hours)} and ${minutes} minute${s(minutes)}`
    } else if (minutes >= 1) {
      return `${minutes} minute${s(minutes)} and ${seconds} second${s(seconds)}`
    }
    return `${seconds} second${s(seconds)}`
  }
  const remaining = timeRemaining(startTime.value, progress.value)

  useEffect(() => {
    if (error.value) {
      (executionType === "sampled" ? setSampledItemError : setSingleItemError)(error.value);
    }
  // eslint-disable-next-line
  }, [error.value]);

  useEffect(() => {
    if (status.value.status) {
      updateStatusFunction(status.value)
    }
  // eslint-disable-next-line 
  }, [status.value]);

  return (
    <React.Fragment>
      <Progress 
        value={progress.value}  
        className="p-bar-custom-outer"
        barClassName="p-bar-custom-inner" 
        >
        {item?.status === "Running" &&
          <span className="time-remaining-text">
            Time Remaining: <b>{remaining ? remaining : "calculating"}</b>
          </span>
        }
        {item?.status === "Queuing" && 
          <span className="time-remaining-text">
            Completion: <b>{progress.value && progress.value !== '0' ? progress.value.toFixed(1) + ' %' : "retrieving"}</b>
          </span>
        }
      </Progress>
    </React.Fragment>
  )
}
