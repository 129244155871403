import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardFooter,
  CardBody,
  Button,
} from "reactstrap";
import { useResultsAPI } from "../hooks/resultsAPI";
import { usePrecipFrequencyAPI } from "../hooks/precipFrequencyAPI";
// import TSChart from "./TSReactChart";
import { TSPlotlyChart } from "./TSPlotlyChart";
import { JsonViewer } from '@textea/json-viewer'
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { useLoading } from "../hooks/Loading";
import Loader from "./Loader";

export const SimulationPanel = ({ simulationId, showDownload, showBap }) => {
  const [isLoading, load] = useLoading(false);
  const { fetchSimulationBap, downloadModel, simulationBap, fetchSimulationMetaInfo, simulationMetaInfo } = useResultsAPI();
  const { analysis } = usePrecipFrequencyAPI();
  const [error, setErr] = useState();
  const [activeTab, setActiveTab] = useState("bap");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    const fetch = () => {
      fetchSimulationBap(simulationId).then(() => {
        setErr(null);
      });
      fetchSimulationMetaInfo(simulationId).then(() => {
        setErr(null);
      });
    }
    fetch()
    setActiveTab(showBap ? "bap" : "metainfo")
    // eslint-disable-next-line
  }, [simulationId]);

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <div className="form">
                <fieldset>
                  <legend>Simulation Information</legend>
                  <Nav tabs>
                    {showBap && <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "bap",
                        })}
                        onClick={() => {
                          toggleTab("bap");
                        }}
                      >
                        Basin Average Precipitation
                      </NavLink>
                    </NavItem>}
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "metainfo",
                        })}
                        onClick={() => {
                          toggleTab("metainfo");
                        }}
                      >
                        Simulation MetaInfo
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>

                    {showBap && <TabPane tabId="bap">
                      <Row>
                        <Col md="12">
                          {simulationBap && (
                            <TSPlotlyChart
                              title="Scaled Basin Average Precipitation"
                              ts={simulationBap}
                              xAxisText="Datetime"
                              yAxisText="Scaled Precipitation (in)"
                              yAxis2Text="Accumulated Precipitation (in)"
                              decimalDigitsGeneral={analysis.decimalDigitsGeneral}
                              showLegend={false}
                            />
                          )}
                        </Col>
                      </Row>
                    </TabPane>}
                    <TabPane tabId="metainfo">
                      <Row>
                        <Col md="12">
                          <div className="border mt-3 pt-2 ps-2"
                            style={{ width: "100%" }}>
                            <JsonViewer
                              value={simulationMetaInfo}
                              collapsed={false}
                              style={{ height: "350px", overflow: "scroll" }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </TabPane>

                  </TabContent>
                </fieldset>
              </div>
            </CardBody>
            <CardFooter>
              <Button
                color="dark"
                size="sm"
                className="me-2"
                title={
                  (showDownload) ?
                    "Download model setup" :
                    "Execute model with \"Save All\" option to enable download."
                }
                disabled={isLoading || !showDownload}
                onClick={() => load(downloadModel(simulationId).catch(err => setErr(err)))}>
                <FontAwesomeIcon icon={faDownload} className="me-2" />
                Download Model
              </Button>

              <span>
                {error && (
                  <span className="text-danger">
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      className="me-2"
                    />
                    <b>There was an issue getting your model: {error}</b>
                  </span>
                )}
              </span>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Loader loading={isLoading} />
    </React.Fragment>
  );
};
