import { getErrorString } from "../util/validation";
import { LowerCI, UpperCI, Expected, Computed } from "../hooks/precipFrequencyAPI";
export const SET_LIST = "SET_SAMPLING_LIST";
export const SET_LIST_ERROR = "SET_SAMPLING_LIST_ERROR";
export const SET_ITEM = "SET_SAMPLING_ITEM";
export const MERGE_ITEM = "MERGE_SAMPLING_ITEM"
export const SET_ITEM_ERROR = "SET_SAMPLING_ITEM_ERROR";
export const SET_ANALYSIS_CURVES = "SET_SAMPLING_ANALYSIS_CURVES";
export const SET_ANALYSIS_CURVES_ERROR = "SET_SAMPLING_ANALYSIS_CURVES_ERROR";
export const SET_ITEM_AEP = "SET_ITEM_AEP"
export const SET_BINS = "SET_SAMPLING_BINS";
export const SET_REALIZATIONS = "SET_SAMPLING_REALIZATIONS";
export const SET_PROBABILITY = "SET_SAMPLING_PROBABILITY"
export const SET_BINS_ERROR = "SET_SAMPLING_BINS_ERROR";
export const DELETE_ITEM = "DELETE_SAMPLING";
export const DELETE_ITEM_ERROR = "DELETE_SAMPLING_ERROR";
export const HANDLE_CHANGE = "HANDLE_SAMPLING_CHANGE";
export const SET_BOOTSTRAP_PARAMETERS = "SET_BOOTSTRAP_PARAMETERS"
export const SET_BOOTSTRAP_ERROR = "SET_BOOTSTRAP_ERROR"
export const VALIDATE_BOOTSTRAP = "VALIDATE_BOOTSTRAP"
export const HANDLE_SAMPLE_TYPE_CHANGE = "HANDLE_SAMPLE_TYPE_CHANGE"
export const HANDLE_FREQUENCY_ID_CHANGE = "HANDLE_FREQUENCY_ID_CHANGE"
export const SET_STRATIFIED_DEFAULT = "SET_STRATIFIED_DEFAULT"
export const SET_CURVE_DEFAULT = "SET_CURVE_DEFAULT"
export const RESET_STATE = "RESET_SAMPLING_STATE"
export const SET_SAVEPOINT = "SET_SAMPLING_SAVEPOINT"

export const Stratified = "Stratified";
export const Quantile = "Quantile";
export const FullUncertainty = "Full Uncertainty";

export const Empirical = "Empirical Expected CDF";
export const A14 = "NOAA A14 GIS Data";
export const Analytical = "Analytical Distribution Parameters (e.g., from L-RAP)";

const defaultItemErrors = {
  name: "Required.",
  description: "",
  sampleTypeId: "Required.",
  frequencyId: "Required.",
  curveTypeId: "Required.",
  distributionTypeId: "Required.",
  aep: "Required.",
  numBins: "Required.",
  numSamples: "Required.",
  minBinProb: "Required.",
  maxBinProb: "Required.",
}

export const stratifiedErrors = {
  name: "Required.",
  description: "",
  sampleTypeId: "",
  frequencyId: "Required.",
  curveTypeId: "Required.",
  distributionTypeId: "Required.",
  aep: "",
  numBins: "Required.",
  numSamples: "Required.",
  minBinProb: "Required.",
  maxBinProb: "Required.",
}

export const quantileErrors = {
  name: "Required.",
  description: "",
  sampleTypeId: "",
  frequencyId: "Required.",
  curveTypeId: "",
  distributionTypeId: "",
  aep: "Required.",
  numBins: "",
  numSamples: "",
  minBinProb: "",
  maxBinProb: "",
}

export const samplingActions = {
  setList(list) {
    return {
      type: SET_LIST,
      list: list,
    };
  },
  setListError(error) {
    return {
      type: SET_LIST_ERROR,
      error: error,
    };
  },
  setItem(item) {
    return {
      type: SET_ITEM,
      item: item,
    };
  },
  mergeItem(item){
    return {
      type: MERGE_ITEM,
      item: item,
    }
  },
  setItemError(error) {
    return {
      type: SET_ITEM_ERROR,
      error: error,
    };
  },
  setAnalysisCurves(curves) {
    return {
      type: SET_ANALYSIS_CURVES,
      curves: curves,
    };
  },
  setBootstrapParameters(bootstrap) {
    return {
      type: SET_BOOTSTRAP_PARAMETERS,
      bootstrap: bootstrap,
    };
  },
  setAnalysisCurvesError(error) {
    return {
      type: SET_ANALYSIS_CURVES_ERROR,
      error: error,
    };
  },
  setBins(bins) {
    return {
      type: SET_BINS,
      bins: bins,
    };
  },
  setRealizations(realizations) {
    return {
      type: SET_REALIZATIONS,
      realizations: realizations,
    };
  },
  setBinsError(error) {
    return {
      type: SET_BINS_ERROR,
      error: error,
    };
  },
  setBootstrapError(error) {
    return {
      type: SET_BOOTSTRAP_ERROR,
      error: error,
    };
  },
  setItemAEP(value){
    return {
      type: SET_ITEM_AEP, 
      aep: value,
    };
  },
  setProbability(field, value, scale) {
    return {
      type: SET_PROBABILITY,
      field: field,
      value: value,
      scale: scale,
    };
  },
  deleteItem(id) {
    return {
      type: DELETE_ITEM,
      id: id,
    };
  },
  deleteItemError(error) {
    return {
      type: DELETE_ITEM_ERROR,
      error: error,
    }
  },
  setStratifiedDefault(freqType, distributionTypeId) {
    return {
      type: SET_STRATIFIED_DEFAULT,
      freqType: freqType, 
      distributionTypeId: distributionTypeId,
    };
  },
  setCurveDefault(curveTypeId, frequencyId, analysisCurves) {
    return {
      type: SET_CURVE_DEFAULT,
      curveTypeId: curveTypeId,
      analysisCurves: analysisCurves,
      frequencyId: frequencyId,
    }
  },
  handleSampleTypeChange(e) {
    e.persist()
    const {id, name} = JSON.parse(e.target.value);
    switch (name) {
      case Stratified:
        return {
          type: HANDLE_SAMPLE_TYPE_CHANGE,
          sampleTypeName: name,
          sampleTypeId: id,
          errors: stratifiedErrors,
        }
      case Quantile: {
        return {
          type: HANDLE_SAMPLE_TYPE_CHANGE,
          sampleTypeName: name,
          sampleTypeId: id,
          errors: quantileErrors,
        }
      }
      case FullUncertainty: {
        return {
          type: HANDLE_SAMPLE_TYPE_CHANGE,
          sampleTypeName: name,
          sampleTypeId: id,
          errors: stratifiedErrors, //strat errors same as fu
        }
      }
      default:
        return {
          type: HANDLE_SAMPLE_TYPE_CHANGE,
          sampleTypeName: "",
          sampleTypeId: "",
          errors: defaultItemErrors,
        }
    }
  },
  handleFrequencyIdChange(id, type) {
    return {
      type: HANDLE_FREQUENCY_ID_CHANGE,
      frequencyId: id,
      frequencyType: type,
    }
  },
  handleChange(e) {
    e.persist();
    return {
      type: HANDLE_CHANGE,
      event: e,
    };
  },
  validateBootstrap(sampleType) {
    return {
      type: VALIDATE_BOOTSTRAP,
      sampleType: sampleType,
    };
  },
  resetState() {
    return {
      type: RESET_STATE,
    }
  },
  setSavepoint(item) {
    return {
      type: SET_SAVEPOINT,
      item: item
    }
  }
};

export const samplingReducer = (state, action) => {
  let newState = JSON.parse(JSON.stringify(state))
  let newItem = JSON.parse(JSON.stringify(state.item))
  switch (action.type) {
    case SET_LIST:
      newState.list = action.list 
      return newState
    case SET_ITEM:
      newState.item = action.item
      newState.error = null
      newState.binsError = null
      // saved items from the backend do not have errors
      if (!action.item.errors) {
        switch (action.item.sampleTypeName) {
          case Stratified:
            newState.item.errors = {...stratifiedErrors}
            break
          case Quantile:
            newState.item.errors = {...quantileErrors}
            break
          default:
            newState.item.errors = {...defaultItemErrors}
        }
        Object.keys(newState.item.errors).forEach(key => newState.item.errors[key] = "") 
      }
      return newState
    case MERGE_ITEM:
      newState.item = {...newItem, ...action.item}
      return newState
    case SET_ANALYSIS_CURVES:
      newState.analysisCurves = action.curves
      return newState
    case SET_BINS:
      newState.item.bins = action.bins
      return newState
    case SET_REALIZATIONS:
      newState.item.realizations = action.realizations;
      return newState
    case SET_BOOTSTRAP_PARAMETERS:
      newState.bootstrap = action.bootstrap;
      return newState
    case SET_ITEM_AEP:
      if (!action.aep) {
        newState.item.aep = 0.01
        newState.item.errors.aep = ""
        newState.savepoint.aep = 0.01
        newState.savepoint.errors.aep = ""
        return newState
      }
      let minAep = newState.bootstrap.minAep 
      let maxAep = newState.bootstrap.maxAep
      let val = action.aep ? parseFloat(action.aep) : ""
      let err = ""

      if (state.item.frequencyId && (action.aep.length < 1 || isNaN(action.aep) || val < minAep || val > maxAep)){
        err = `Required value must be between the minimum and maximum AEP values (${minAep} - ${maxAep})`
      }
      if (!state.item.frequencyId && action.aep.length < 1){
        err = "Required."
      } 
      newState.item.aep = val
      newState.item.errors.aep = err
      return newState
    case SET_PROBABILITY:
      let probability = parseFloat(action.value)
      newState.item[action.field] = probability
      if (action.value.length < 1) {
        newState.item.errors[action.field] = "Required."
        return newState 
      }
      newState.item.errors[action.field] = ""
      if (!newItem.frequencyId || !newState.defaultMax) {
        return newState 
      }
      
      let max = +newState.defaultMax.toFixed(action.scale)
      let min = +newState.defaultMin.toFixed(action.scale)
      if (action.field === 'maxBinProb' && probability > max) {
        newState.item.errors[action.field] = `Value must be less than the precip-freq curve maximum value of ${max}`
      }
      if (action.field === 'minBinProb' && probability < min) {
        newState.item.errors[action.field] = `Value must be greater than the precip-freq curve minimum value of ${min}`
      }
      return newState
    case DELETE_ITEM:
      return {
        ...state,
        list: state.list.filter((i) => i.id !== action.id),
      };
    case HANDLE_CHANGE:
      const { name, value } = action.event.target;
      let errors = {...state.item.errors}
      errors[name] = value.length < 1 ? "Required." : "";
      errors.description = "";
      const changedItem = { ...state.item, [name]: value, errors };
      return {
        ...state,
        item: changedItem,
      };
    case HANDLE_SAMPLE_TYPE_CHANGE:
      const newSampleItem = {
        ...samplingInitialState.item,
        sampleTypeName: action.sampleTypeName,
        sampleTypeId: action.sampleTypeId,
        errors: {
          ...action.errors,
          name: "Required.",
          description: "",
        },
      }
      return { 
        ...samplingInitialState, 
        analysisCurves: state.analysisCurves,
        list: state.list,
        item: newSampleItem,
        savepoint: newSampleItem,
      }
    case HANDLE_FREQUENCY_ID_CHANGE:
      newItem.frequencyId = action.frequencyId
      newItem.errors = {
        ...state.item.errors,
        sampleTypeId: "",
        name: !state.item.name || state.item.name.length < 1 ? "Required." : "",
        description: "",
        frequencyId: !state.item.frequencyId || state.item.frequencyId.length < 1 ? "Required." : "",
      }
      return {...state, item:newItem, error:null}
    case SET_ANALYSIS_CURVES_ERROR:
    case SET_LIST_ERROR:
    case SET_ITEM_ERROR:
    case DELETE_ITEM_ERROR:
      newState.error = getErrorString(action.error)
      return newState
    case SET_BINS_ERROR:
      return {
        ...state,
        binsError: action.error,
      };
    case SET_BOOTSTRAP_ERROR:
      return {
        ...state,
        bootstrapError: action.error,
      };
    case VALIDATE_BOOTSTRAP:
      if (action.sampleType === Stratified) return newState
      const realization = newState.bootstrap.numRealizations ? parseInt(newState.bootstrap.numRealizations) : ""
      if (!realization){
        newState.item.errors["bootstrap"] = "Required."
      } else {
        if (realization < 10 && action.sampleType === FullUncertainty) {
          newState.item.errors["bootstrap"] = "The minimum number of realizations required for Full Uncertainty Sampling is 10."
        }
        if (realization > 1000 && action.sampleType === FullUncertainty) {
          newState.item.errors["bootstrap"] = "The maximum number of realizations allowed for Full Uncertainty Sampling is 1,000."
        }
        if (realization < 100 && action.sampleType === Quantile) {
          newState.item.errors["bootstrap"] = "The minimum number of realizations required for Quantile Sampling is 100."
        }
        if (realization > 10000 && action.sampleType === Quantile) {
          newState.item.errors["bootstrap"] = "The maximum number of realizations allowed for Quantile Sampling is 10,000."
        }
      }
      return newState
    case SET_STRATIFIED_DEFAULT:
      newState.item.distributionTypeId = action.distributionTypeId
      newState.item.errors.distributionTypeId = ""
      return newState
    case SET_CURVE_DEFAULT:
      const curves = action.analysisCurves.filter(obj => obj.frequencyId === action.frequencyId && obj.curveTypeId === action.curveTypeId)
      const curveType = curves[0].curveType
      const minBinProb = curves.reduce((min, obj) => Math.min(min, obj.probability), Number.MAX_VALUE)
      const maxBinProb = curves.reduce((max, obj) => Math.max(max, obj.probability), Number.MIN_VALUE)
      newState.defaultMin = minBinProb
      newState.defaultMax = maxBinProb
      newState.item.minBinProb = minBinProb
      newState.item.maxBinProb = maxBinProb
      newState.item.errors.minBinProb = ""
      newState.item.errors.maxBinProb = ""

      const distributedCurves = [UpperCI, LowerCI, Expected, Computed]
      if (distributedCurves.includes(curveType)){
        newState.item = {...newState.item, ...distributedCurveDefaultState}
      } else {
        newState.item = {...newState.item, ...a14CurveDefaultState}
      }
      newState.item.errors = {...newState.item.errors, ...curveErrorDefaultState}
      return newState
    case RESET_STATE:
      return JSON.parse(JSON.stringify(samplingInitialState))
    case SET_SAVEPOINT:
      newState.savepoint = action.item 
      return newState
    default:
      return state;
  }
};

const a14CurveDefaultState = {
  numBins: "20",
  numSamples: "50",
  bins: [],
}
const distributedCurveDefaultState = {
  numBins: "100",
  numSamples: "100",
  bins: [],
}
const curveErrorDefaultState = {
  numBins: "",
  numSamples: "",
};
export const samplingInitialState = {
  list: [],
  item: {
    name: "",
    description: "",
    id: null,
    sampleTypeName: "",
    sampleTypeId: "",
    frequencyId: "",
    curveTypeId: "",
    distributionTypeId: "",
    aep: "",
    numBins: "",
    numSamples: "",
    minBinProb: "",
    maxBinProb: "",
    bins: [],
    errors: defaultItemErrors,
  },
  analysisCurves: [],
  error: null,
  binsError: null,
  bootstrapError: null,
  bootstrap: {},
};
