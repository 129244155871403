import { useContext } from "react";
import { DispatchStore, StateStore } from "../store/GlobalStore";
import { apiRoot } from "../services/api";
import { modelActions, modelInitialState } from "../reducers/modelReducer";
import { userActions } from "../reducers/userReducer";

export const useModelAPI = () => {
  const { state } = useContext(StateStore);
  const { dispatch } = useContext(DispatchStore);
  const { list, item, error, savepoint, uploadInfo } = state.model;
  const { fileItem } = state.model.item

  const fetchList = async (analysisId) => {
    try {
      const res = await fetch(`${apiRoot}/analyses/${analysisId}/models/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(modelActions.setListError(json.error));
      }
      dispatch(modelActions.setList(json.data));
    } catch (err) {
      dispatch(modelActions.setListError(err));
    }
  };

  const newItem = () => {

    dispatch(modelActions.setItem(modelInitialState.item));
    dispatch(modelActions.handleFileChange(modelInitialState.item.fileItem));
    dispatch(modelActions.setSavepoint(modelInitialState.item))
    dispatch(modelActions.setFileUploadInfo(modelInitialState.uploadInfo));
  };

  const newItemPersistError = () => {
    dispatch(modelActions.setItemPersistError(modelInitialState.item));
    dispatch(modelActions.handleFileChange(modelInitialState.item.fileItem));
    dispatch(modelActions.setSavepoint(modelInitialState.item))
    dispatch(modelActions.setFileUploadInfo(modelInitialState.uploadInfo));
  };

  const deleteItem = async (id) => {
    dispatch(modelActions.setItemError(null));
    try {
      const res = await fetch(`${apiRoot}/models/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(modelActions.deleteItemError(json.error));
        console.log(json.error);
      } else {
        dispatch(modelActions.deleteItem(id));
      }
    } catch (err) {
      dispatch(modelActions.deleteItemError(err));
      console.log(err);
    }
  };

  const downloadData = async (id) => {
    dispatch(modelActions.setItemError(null));
    try {
      const res = await fetch(`${apiRoot}/models/${id}/download/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      let blob = res.blob();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (!blob) {
        dispatch(modelActions.setItemError("An error occurred."));
      } else {
        blob = (await blob).slice(0, (await blob).size, "application/zip"); //coerce blob into correct content type
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", id + ".zip");
        document.body.appendChild(link);
        link.click();
      }
    } catch (err) {
      dispatch(modelActions.setItemError(err));
      console.log(err);
    }
  };

  const createItem = async (analysisId) => {
    if (validateItem(item.errors)) {
      dispatch(modelActions.setItemError(null));
      try {
        let formData = new FormData();
        formData.append("modelId", item.id);
        formData.append("name", item.name);
        formData.append("description", item.description);
        formData.append("file", fileItem? fileItem: null);
        formData.append("analysisId", analysisId);
        const res = await fetch(`${apiRoot}/analyses/${analysisId}/models/`, {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        });
        const json = await res.json();
        if (res.status === 401) {
          window.localStorage.removeItem("rrftToken");
          dispatch(userActions.userError("Incorrect email or password"));
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
        } else if (res.status === 403) {
          dispatch(
            userActions.userError(
              "You do not have the appropriate permissions to view / use this resource."
            )
          );
        } else if (json.error) {
          dispatch(modelActions.setItemError(json.error));
          console.log(json.error);
        } else {
          dispatch(modelActions.setFileUploadInfo(json.data));
          // if there was a file upload and this was an existing model pop that record off the model list
          if (fileItem && item.id) {
            dispatch(modelActions.deleteItem(item.id))
          }
          if (item.id === null || fileItem) {
            dispatch(modelActions.updateListOnCreate(json.data));
          } else {
            dispatch(modelActions.updateListOnUpdate(json.data));
          }
        }
      } catch (err) {
        dispatch(modelActions.setItemError(err));
        console.log(err);
      }
    } else {
      dispatch(modelActions.setItemError("Missing required fields."));
    }
  };

  const onChange = (e) => dispatch(modelActions.handleChange(e));

  const onFileChange = (fileItem) =>
    dispatch(modelActions.handleFileChange(fileItem));

  const validateItem = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  const selectItem  = (item) => {
    dispatch(modelActions.setItem(item))
    dispatch(modelActions.setSavepoint(item))
  }

  const resetState = () => {
    dispatch(modelActions.resetState())
  }

  return {
    list,
    item,
    fileItem,
    uploadInfo,
    error,
    savepoint,
    fetchList,
    selectItem,
    newItem,
    deleteItem,
    downloadData,
    createItem,
    onChange,
    onFileChange,
    resetState,
    newItemPersistError,
  };
};
