export const apiRoot = process.env.REACT_APP_API_GATEWAY;
export const eventRoot = process.env.REACT_APP_EVENT_GATEWAY;
export const pfToolsRoot = process.env.REACT_APP_PFTOOLS_GATEWAY;
export const authRoot = process.env.REACT_APP_AUTH_GATEWAY;
console.log(apiRoot);
console.log(authRoot);
console.log(pfToolsRoot);
console.log(eventRoot);
export const events = {
  Info: "Info",
  DownloadAvailable: "DownloadAvailable",
  Progress: "Progress",
  Promoted: "Promoted",
  StartTime: "StartTime",
  Error: "Error",
  ExecutionError: "ExecutionError",
};
