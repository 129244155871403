import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import { BootstrapParameters } from "../components/BootstrapParams";
import { usePrecipFrequencyAPI } from "../hooks/precipFrequencyAPI";
import { useDomainListAPI } from "../hooks/domainListAPI";

import { EditTable } from "./EditTable";
import { Modal, ModalBody, Button } from "reactstrap";
import { faTable } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const AnalyticalDistParams = ({ precipFrequency }) => {
  const {
    item,
    onChange,
    onSpatialTypeChange,
    onUseArfListCheckboxChange,
    addArf,
    editArf,
    deleteArf,
  } = usePrecipFrequencyAPI();
  const { spatialTypes, durations } = useDomainListAPI();


  const columns = [
    {
      name: "probability",
      label: "Annual Exceedance Probability",
      min: 0,
      step: 0.5,
    },
    {
      name: "arealReductionFactor",
      label: "Areal Reduction Factor",
      min: 0,
      step: 1,
    },
  ];

  const emptyRow = { arealReductionFactor: "", probability: "" };
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const closeModal = () => {
    setModal(false);
  };
  const modalStyle = {
    border: "0",
    borderRadius: "4px",
    bottom: "auto",
    minHeight: "10rem",
    left: "50%",
    padding: "2rem",
    position: "fixed",
    right: "auto",
    top: "50%",
    height: "50%",
    transform: "translate(-50%,-50%)",
    minWidth: "10rem",
    width: "50%",
    maxWidth: "40rem",
  };
  const [gdata, setData] = useState(item.arealReductionFactorList)
  useEffect(() => {
    setData(item.arealReductionFactorList)
  }, [item.arealReductionFactorList])
  return (
    <React.Fragment>
      <Row className="mt-3">
        <Col md="12">
          <legend title="Analytical Distribution Parameters">
            Analytical Distribution Parameters
          </legend>
          <Row>
            <Col md="12">
              <Row>
                <Col md="4">
                  <FormGroup className="mt-3">
                    <Label for="duration">Duration</Label>
                    <Input
                      type="number"
                      min="1"
                      step="1"
                      className="form-control"
                      placeholder="Enter duration"
                      name="duration"
                      value={item.duration.toString()}
                      onChange={onChange}
                    />
                    {item.errors.duration.length > 0 && (
                      <span className="text-danger">
                        <b>{item.errors.duration}</b>
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mt-3">
                    <Label for="durationUnit">Duration Units</Label>
                    <select
                      className="form-control"
                      name="durationUnit"
                      value={item.durationUnit}
                      onChange={onChange}
                    >
                      <option value="" />
                      {durations.map((s) => (
                        <option key={s.id} value={s.name}>
                          {s.name}
                        </option>
                      ))}
                    </select>
                    {item.errors.durationUnit.length > 0 && (
                      <span className="text-danger">
                        <b>{item.errors.durationUnit}</b>
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mt-3">
                    <Label>Spatial type</Label>
                    <select
                      onChange={onSpatialTypeChange}
                      className="form-control"
                      name="spatialType"
                      value={item.spatialType.toString()}
                    >
                      <option value="" />
                      {spatialTypes.map((s) => (
                        <option key={s.id} value={s.name}>
                          {s.name}
                        </option>
                      ))}
                    </select>
                    {item.errors.spatialType.length > 0 && (
                      <span className="text-danger">
                        <b>{item.errors.spatialType}</b>
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              {item.spatialType === "Point" && (
                <React.Fragment>
                  <Row>
                    <Col md="4">
                    <Label>Areal Reduction Factor &nbsp;&nbsp;
                  <Input
                        name="useArealReductionFactorList"
                        type="checkbox"
                        checked={item.useArealReductionFactorList}
                        onChange={(e) => {
                          if (!item.useArealReductionFactorList) {
                            toggle();
                          }
                          onUseArfListCheckboxChange(e);
                        }}
                      />
                      &nbsp; Use Multiple
                  </Label>
                    </Col>
                    </Row>
                  <Row>
                    {!item.useArealReductionFactorList && (
                      <Col md="4">
                        <FormGroup>
                          <Input
                            type="number"
                            min="0"
                            max="1"
                            step="0.05"
                            onChange={onChange}
                            className="form-control"
                            name="arealReductionFactor"
                            value={item.arealReductionFactor.toString()}
                          />
                          {item.errors.arealReductionFactor.length > 0 && (
                            <span className="text-danger">
                              <b>{item.errors.arealReductionFactor}</b>
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                    )}
                    {item.useArealReductionFactorList && (
                      <Col md="3" className="mt-1">
                        <Button
                          color="light"
                          size="sm"
                          className="custom-upload w-100"
                          onClick={toggle}
                        >
                          <FontAwesomeIcon icon={faTable} className="me-2" />
                          <span>Edit Multiple ARFs</span>
                        </Button>
                      </Col>
                    )}
                    {item.useArealReductionFactorList && (
                      <Col md="6">
                        <Modal
                          isOpen={modal}
                          toggle={toggle}
                          style={modalStyle}
                          scrollable={true}
                        >
                          <ModalBody>
                            <EditTable
                              title="Multiple Areal Reduction Factors"
                              gdata={gdata}
                              columns={columns}
                              emptyRowObj={emptyRow}
                              onAdd={addArf}
                              onEdit={editArf}
                              onDelete={deleteArf}
                            />
                            <Button
                              color="dark"
                              size="sm"
                              className="me-2"
                              onClick={closeModal}
                              style={{marginTop:"10px"}}
                            >
                              <span>Close</span>
                            </Button>
                          </ModalBody>
                        </Modal>
                      </Col>
                    )}
                  </Row>
                </React.Fragment>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <BootstrapParameters />
      
    </React.Fragment>
  );
};
