import { useContext } from "react";
import { DispatchStore, StateStore } from "../store/GlobalStore";
import { apiRoot } from "../services/api";
import { pfToolsRoot } from "../services/api";
import {
  samplingActions,
  samplingInitialState,
  Stratified,
  Quantile,
  stratifiedErrors,
  quantileErrors,
  FullUncertainty,
} from "../reducers/samplingReducer";
import { userActions } from "../reducers/userReducer";
import colors from "../data/colors.json";
import { shadeBetweenConfidenceTraces } from "../util/plotlytrace";
import { useDomainListAPI } from "../hooks/domainListAPI";
import { flexibleDatatypes } from "../reducers/precipFrequencyReducer";

export const useSamplingAPI = () => {
  const { state } = useContext(StateStore);
  const { dispatch } = useContext(DispatchStore);
  const { list, item, analysisCurves, error, binsError, bootstrap, savepoint } = state.sampling;
  const { importanceDistributions } = useDomainListAPI();
  const analysis = state.analysis.savepoint;
  const { list: precipList } = state.precipFrequency

  const fetchList = async (analysisId) => {
    try {
      const res = await fetch(`${apiRoot}/analyses/${analysisId}/samples/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error || !json.data) {
        dispatch(samplingActions.setListError(json.error));
        if (json.error) {
          console.log(json.error);
        }
      } else {
        dispatch(samplingActions.setList(json.data));
      }
    } catch (err) {
      dispatch(samplingActions.setListError(err));
      console.log(err);
    }
  };

  const fetchItem = async (sampleId) => {
    try {
      const res = await fetch(`${apiRoot}/samples/${sampleId}/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error || !json.data) {
        dispatch(samplingActions.setItemError(json.error));
        if (json.error) {
          console.log(json.error);
        }
      } else {
        let itemData = json.data;
        // remove unnecessary fields before setting state. These fields are only needed for list.
        delete itemData.curve;
        delete itemData.distribution;
        delete itemData.frequency;
        dispatch(samplingActions.setItem(itemData));
        dispatch(samplingActions.setSavepoint(itemData))
      }
    } catch (err) {
      dispatch(samplingActions.setItemError(err));
      console.log(err);
    }
  };

  const deleteItem = async (id) => {
    dispatch(samplingActions.setItemError(null));
    try {
      const res = await fetch(`${apiRoot}/samples/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(samplingActions.deleteItemError(json.error));
        console.log(json.error);
      } else {
        dispatch(samplingActions.deleteItem(id));
      }
    } catch (err) {
      dispatch(samplingActions.deleteItemError(err));
      console.log(err);
    }
  };

  const fetchAnalysisCurves = async (analysisId) => {
    try {
      const res = await fetch(`${apiRoot}/analyses/${analysisId}/curves/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error || !json.data) {
        dispatch(samplingActions.setAnalysisCurvesError(json.error));
        if (json.error) {
          console.log(json.error);
        }
      } else {
        let curves = json.data;
        dispatch(samplingActions.setAnalysisCurves(curves));
      }
    } catch (err) {
      dispatch(samplingActions.setAnalysisCurvesError(err));
      console.log(err);
    }
  };

  const fetchFrequencies = (curves) => {
    let result = [];
    let map = new Map();
    for (let curve of curves) {
      if (!map.has(curve.frequencyId)) {
        map.set(curve.frequencyId, true);
        result.push({
          id: curve.frequencyId,
          name: curve.frequencyName,
          frequencyTypeName: curve.frequencyTypeName,
          frequencyTypeId: curve.frequencyTypeId
        });
      }
    }
    return result;
  };

  const fetchCurveTypes = (curves, frequencyId) => {
    let result = [];
    if (frequencyId) {
      let map = new Map();
      for (let curve of curves) {
        if (curve.frequencyId === frequencyId) {
          if (!map.has(curve.curveTypeId)) {
            map.set(curve.curveTypeId, true);
            result.push({
              id: curve.curveTypeId,
              name: curve.curveType,
            });
          }
        }
      }
    }
    return result;
  };

  const fetchCurves = (curves, frequencyId, curveTypeId) => {
    let result = [];
    if (frequencyId && curveTypeId) {
      for (let curve of curves) {
        if (
          curve.frequencyId === frequencyId &&
          curve.curveTypeId === curveTypeId
        ) {
          result.push({
            id: curve.id,
            value: curve.value,
            probability: curve.probability,
          });
        }
      }
    }
    return result;
  };

  function formatForPfTools(item, sampleType) {
    let formattedData = {}
    let stratifiedSample = {}
    const data = JSON.parse(JSON.stringify(item));
    switch (sampleType) {
      case Stratified:
        stratifiedSample.minBinProb = parseFloat(data.minBinProb);
        stratifiedSample.maxBinProb = parseFloat(data.maxBinProb);
        stratifiedSample.distribution = getDistributionName(data.distributionTypeId)
        stratifiedSample.numBins = parseFloat(data.numBins);
        stratifiedSample.numSamples = parseFloat(data.numSamples);
        formattedData.stratifiedSampling = stratifiedSample;
        formattedData.data = fetchCurves(
          analysisCurves,
          item.frequencyId,
          item.curveTypeId
        );
        return formattedData
      case FullUncertainty:
        stratifiedSample.minBinProb = parseFloat(data.minBinProb);
        stratifiedSample.maxBinProb = parseFloat(data.maxBinProb);
        stratifiedSample.distribution = getDistributionName(data.distributionTypeId)
        stratifiedSample.numBins = parseFloat(data.numBins);
        stratifiedSample.numSamples = parseFloat(data.numSamples);
        formattedData.stratifiedSampling = stratifiedSample;
        formattedData.bootstrap = bootstrap;
        return formattedData
      case Quantile:
        const precipFrequency = precipList.find(f => f.id === item.frequencyId)
        formattedData.bootstrap = bootstrap;
        formattedData.aep = data.aep;
        formattedData.arealReductionFactor = flexibleDatatypes(precipFrequency.arealReductionFactor)
        formattedData.arealReductionFactorList = precipFrequency.arealReductionFactorList || []
        formattedData.useArealReductionFactorList = Boolean(precipFrequency.useArealReductionFactorList)
        return formattedData
      default:
        return "TODO: handle this default case"
    }
  }

  function formatPfToolsOutput(response, sampleType) {
    let formattedBins
    switch (sampleType) {
      case Stratified:
        formattedBins = response.output
        break
      case FullUncertainty:
        formattedBins = response.output
        break
      case Quantile:
        let singleBin = {}
        singleBin.binId = 0
        singleBin.data = response.output
        formattedBins = [singleBin]
        break
      default:
        return "TODO: handle this default case"
    }
    return formattedBins
  }

  const pfToolsEndpoints = {
    Stratified: "empirical_stratification",
    Quantile: "quantile_sampling",
    "Full Uncertainty": "fu_sampling"
  }

  const fetchBins = async () => {
    const sampleType = item.sampleTypeName
    if (validateItem(item.errors)) {
      dispatch(samplingActions.setBinsError(null));
      try {
        const data = formatForPfTools(item, sampleType);
        const res = await fetch(`${pfToolsRoot}/${pfToolsEndpoints[sampleType]}/`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${state.user.token}`,
            "Content-Type": "application/json",
          },
        });
        const json = await res.json();
        if (!json.output && !json.quantileSample) {
          let errorDetail = "An error occurred.";
          if (json.detail) {
            errorDetail += " " + json.detail;
            console.log(json.detail);
          }
          dispatch(samplingActions.setBinsError(errorDetail));
        } else {
          // formattedOutput will be list of bins or list of realizations depending on sample type
          const formattedOutput = formatPfToolsOutput(json, sampleType)
          const setter = sampleType === FullUncertainty ? samplingActions.setRealizations : samplingActions.setBins
          dispatch(setter(formattedOutput))
        }
      } catch (err) {
        dispatch(samplingActions.setBinsError(err));
        console.log(err);
      }
    } else {
      dispatch(samplingActions.setBinsError("Missing required fields."));
    }
  };

  const transformDataToSeriesPlotly = (bins, realizations, sampleType) => {

    function roundUp(num, precision) {
      precision = Math.pow(10, precision)
      return Math.ceil(num * precision) / precision
    }

    // modify bins in place to sort ascending
    // O(n)
    let series = [];

    switch (sampleType) {

      case Stratified:
        if (bins && bins.length > 0) {

          bins.sort((a, b) => a.binId - b.binId);

          for (let bin of bins) {
            if (bin.data == null) continue;
            let x = [];
            let y = [];
            let id = [];
            for (let d of bin.data) {
              x.push(d.probability);
              y.push(d.value);
              id.push(d.simulationId)
            }
            let singleSeries = {
              name: "Bin " + bin.binId,
              type: "scatter",
              mode: "markers",
              marker: { size: 8 },
              x: x,
              y: y,
              id: id
            };
            series.push(singleSeries);
          }
        }
        return series;
      case Quantile:
        if (bins && bins.length > 0) {
          let data = bins[0].data
          if (data == null) return;
          let depths = [];
          // let id = [];
          for (let d of data) {
            depths.push(d.value);
            // id.push(d.simulationId);
          }
          // return the trace:
          let binCount = 2 * Math.cbrt(data.length);
          // console.log("Max Bin Count (nbinsx): " + binCount)
          let dataRange = (Math.max(...depths) - Math.min(...depths));
          let binSize = roundUp(dataRange / binCount, 1);
          // console.log("DataRange " + dataRange)
          series.push(
            {
              name: "Quantile Sample at AEP = " + bins[0].data[0].probability,
              type: "histogram",
              histnorm: 'probability',
              x: depths,
              xbins: { size: binSize },
              marker: {
                line: {
                  color: "grey",
                  width: 1
                }
              }
            }
          )
        }
        return series;
      case FullUncertainty:
        if (realizations) {
          let seriesMap = new Map();
          for (let realization of realizations) {

            let rbins = realization.bins;
            rbins.sort((a, b) => a.binId - b.binId);
            let x = [];
            let y = [];
            let id = [];
            let b = [];
            let markercolors = []
            for (let bin of rbins) {
              if (bin.data == null) continue;
              bin.data.sort((a, b) => b.probability - a.probability)
              for (let d of bin.data) {
                x.push(d.probability);
                y.push(d.value);
                id.push(d.simulationId)
                b.push(bin.binId);
                markercolors.push(colors[bin.binId])
              }
            }
            let singleSeries = {
              name: "Realization #" + realization.realizationNumber,
              type: "scatter",
              mode: "lines", // or lines+markers?
              // marker: { size: 4 , color: markercolors},
              customdata: b,
              x: x,
              y: y,
              id: id
            };
            seriesMap.set(realization.realizationNumber, singleSeries)
          }
          seriesMap = shadeBetweenConfidenceTraces(seriesMap)
          series = [...seriesMap.values()];
        }
        return series;
      default:
        return "TODO: handle this default case"
    };
  };

  const transformDataToSeries = (bins) => {
    // modify bins in place to sort ascending
    // O(n)
    bins.sort((a, b) => a.binId - b.binId);

    let series = [];
    for (let bin of bins) {
      let singleSeriesData = [];
      for (let d of bin.data) {
        d.x = d.probability;
        d.y = d.value;
        singleSeriesData.push(d);
      }
      let singleSeries = {
        name: "Bin " + bin.binId,
        type: "scatter",
        data: singleSeriesData,
      };
      series.push(singleSeries);
    }
    return series;
  };

  const fetchFrequencyTypes = (curves) => {
    // get all frequency types associated with current analysis
    return [...new Set(curves.map((curve) => curve.frequencyTypeId))]
  }

  const fetchValidSampleTypes = (curves, sampleTypeFrequencyTypes) => {
    const currentFreqTypes = fetchFrequencyTypes(curves)
    return sampleTypeFrequencyTypes.map((sampleType) => {
      const necessaryFreqTypes = sampleType.frequencyTypes.map((t) => t.id)
      const valid = currentFreqTypes.some(fType => necessaryFreqTypes.includes(fType))
      return { ...sampleType, valid }
    })
  }

  const fetchValidFrequencies = (curves, sampleType, sampleTypeFrequencyTypes) => {
    if (!sampleType) return []
    const currentFrequencies = fetchFrequencies(curves)
    const validFrequencies = sampleTypeFrequencyTypes.find((s) => s.name === sampleType).frequencyTypes
    return currentFrequencies.map((freq) => {
      const valid = validFrequencies.map((f) => f.id).includes(freq.frequencyTypeId)
      return { ...freq, valid }
    })
  }

  const getDistributionName = (distributionTypeId) => {
    return importanceDistributions.find(d => d.id === distributionTypeId).name
  }

  const fetchBootstrapForSample = async (frequencyId) => {
    try {
      const res = await fetch(`${apiRoot}/frequencies/${frequencyId}/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(samplingActions.setBootstrapError(json.error));
      } else {
        json.data.bootstrap ||= {};
        dispatch(samplingActions.setBootstrapParameters(json.data.bootstrap));
        dispatch(samplingActions.validateBootstrap(item.sampleTypeName));
      }
    } catch (error) {
      dispatch(samplingActions.setBootstrapError(error));
    }
  }

  function formatForCreateOrUpdate(data) {
    delete data.errors;
    if ([Stratified, FullUncertainty].includes(data.sampleTypeName)) {
      data.numBins = parseFloat(data.numBins);
      data.numSamples = parseFloat(data.numSamples);
      data.minBinProb = parseFloat(data.minBinProb);
      data.maxBinProb = parseFloat(data.maxBinProb);
      delete data.aep
      if (data.sampleTypeName === FullUncertainty) {
        delete data.curveTypeId;
      }
    }
    else if (data.sampleTypeName === Quantile) {
      data.numBins = 1;
      data.numSamples = bootstrap.numRealizations;
      data.minBinProb = 0;
      data.maxBinProb = 0;
      data.aep = parseFloat(data.aep);
      delete data.curveTypeId;
      delete data.distributionTypeId;
      delete data.curveTypeId;
    }
    return data
  }

  const createItem = async (newName) => {
    if (validateItem(item.errors)) {
      dispatch(samplingActions.setItemError(null));
      try {
        let data = JSON.parse(JSON.stringify(item));
        data = formatForCreateOrUpdate(data)
        if (newName) {
          delete data.id;
          data.name = newName;
        }
        const res = await fetch(`${apiRoot}/samples/`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        });
        const json = await res.json();
        if (res.status === 401) {
          window.localStorage.removeItem("rrftToken");
          dispatch(userActions.userError("Incorrect email or password"));
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
        } else if (res.status === 403) {
          dispatch(
            userActions.userError(
              "You do not have the appropriate permissions to view / use this resource."
            )
          );
        } else if (json.error || !json.data) {
          dispatch(samplingActions.setItemError(json.error));
          if (json.error) {
            console.log(json.error);
          }
        } else {
          dispatch(samplingActions.setItem(json.data));
          dispatch(samplingActions.setSavepoint(json.data));
        }
      } catch (err) {
        dispatch(samplingActions.setItemError(err));
        console.log(err);
      }
    } else {
      dispatch(samplingActions.setItemError("Missing required fields."));
    }
  };

  const updateItem = async (id) => {
    if (validateItem(item.errors)) {
      dispatch(samplingActions.setItemError(null));
      try {
        let data = JSON.parse(JSON.stringify(item));
        data = formatForCreateOrUpdate(data)
        const res = await fetch(`${apiRoot}/samples/`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        });
        const json = await res.json();
        if (res.status === 401) {
          window.localStorage.removeItem("rrftToken");
          dispatch(userActions.userError("Incorrect email or password"));
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
        } else if (res.status === 403) {
          dispatch(
            userActions.userError(
              "You do not have the appropriate permissions to view / use this resource."
            )
          );
        } else if (json.error || !json.data) {
          dispatch(samplingActions.setItemError(json.error));
          if (json.error) {
            console.log(json.error);
          }
        } else {
          dispatch(samplingActions.setItem(json.data));
          dispatch(samplingActions.setSavepoint(json.data));
        }
      } catch (err) {
        dispatch(samplingActions.setItemError(err));
        console.log(err);
      }
    } else {
      dispatch(samplingActions.setItemError("Missing required fields."));
    }
  };

  const downloadData = () => { };

  const onChange = (e) => dispatch(samplingActions.handleChange(e));

  const onSampleTypeChange = (e) => dispatch(samplingActions.handleSampleTypeChange(e));

  const onFrequencyIdChange = (e, validFrequencies) => {
    const persistData = { sampleTypeName: item.sampleTypeName, name: item.name, description: item.description, frequencyId: e.target.value, sampleTypeId: item.sampleTypeId };
    const value = e.target.value
    const freqType = value ? validFrequencies.find(freq => freq["id"] === value)["frequencyTypeName"] : ""
    let errorState = quantileErrors
    if ([Stratified, FullUncertainty].includes(item.sampleTypeName))
      errorState = {...stratifiedErrors}
      if (item.sampleTypeName === FullUncertainty) {
        errorState.curveTypeId = ""
      }
    if (item.id) {
      dispatch(samplingActions.mergeItem({ persistData }));
      dispatch(samplingActions.handleFrequencyIdChange(value, freqType));
    } else {
      dispatch(samplingActions.setItem({...samplingInitialState.item}));
      dispatch(samplingActions.mergeItem({...persistData, errors:errorState}))
      dispatch(samplingActions.handleFrequencyIdChange(value, freqType));
    }
  };

  const newItem = () => {
    dispatch(
      samplingActions.setItem(
        JSON.parse(JSON.stringify(samplingInitialState.item))
      ));
    dispatch(samplingActions.setSavepoint(samplingInitialState.item))
  };
  const setItem = (item) => {
    dispatch(samplingActions.setItem(item));
  };

  const validateItem = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  const setList = (lst) => dispatch(samplingActions.setList(lst));

  const setAnalysisCurves = (lst) =>
    dispatch(samplingActions.setAnalysisCurves(lst));

  const setStratifiedDefault = (freqType, distributionTypeId) =>
    dispatch(samplingActions.setStratifiedDefault(freqType, distributionTypeId));

  const setAep = (value) => dispatch(samplingActions.setItemAEP(value));

  const validateBootstrap = (sampleType) =>
    dispatch(samplingActions.validateBootstrap(sampleType));

  const setCurveDefault = (curveTypeId, frequencyId) => {
    dispatch(samplingActions.setCurveDefault(curveTypeId, frequencyId, analysisCurves))
  }

  const resetState = () => {
    dispatch(samplingActions.resetState())
  }

  const setProbability = (e) => {
    const {name, value} = e.target
    dispatch(samplingActions.setProbability(name, value, analysis.decimalDigitsAep))
  }

  return {
    list,
    item,
    analysisCurves,
    error,
    savepoint,
    binsError,
    bootstrap,
    fetchList,
    setList,
    fetchItem,
    deleteItem,
    fetchAnalysisCurves,
    setAnalysisCurves,
    fetchValidFrequencies,
    fetchCurveTypes,
    fetchCurves,
    fetchBins,
    transformDataToSeries,
    transformDataToSeriesPlotly,
    fetchValidSampleTypes,
    createItem,
    updateItem,
    downloadData,
    onChange,
    newItem,
    setItem,
    setAep,
    fetchBootstrapForSample,
    onSampleTypeChange,
    fetchFrequencies,
    onFrequencyIdChange,
    validateBootstrap,
    setStratifiedDefault,
    setCurveDefault,
    resetState,
    setProbability,
  };
};
