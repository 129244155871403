import { useContext } from "react";
import { DispatchStore, StateStore } from "../store/GlobalStore";
import { apiRoot } from "../services/api";
import {
  executeActions,
  outputItemInitialState,
  sampledItemInitialState,
} from "../reducers/executeReducer";
import { userActions } from "../reducers/userReducer";

export const useExecuteSampledAPI = () => {
  const { state } = useContext(StateStore);
  const { dispatch } = useContext(DispatchStore);
  const {
    item: sampledItem,
    list: sampledList,
    error: sampledError,
    activeList: sampledActiveList,
    savepoint,
    analysisTemplates,
    analysisTemplatesError,
    analysisComponents,
    analysisComponentsError,
  } = state.execute;
  const { outputItem } = state.execute.item

  const fetchList = async (analysisId) => {
    try {
      const res = await fetch(
        `${apiRoot}/analyses/${analysisId}/executions/sampled/`,
        {
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        }
      );
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error || !json.data) {
        dispatch(executeActions.setListError("sampled", json.error));
        if (json.error) {
          console.log(json.error);
        }
      } else {
        dispatch(executeActions.setList("sampled", json.data));
      }
    } catch (err) {
      dispatch(executeActions.setListError("sampled", err));
      console.log(err);
    }
  };

  const fetchItem = async (id) => {
    try {
      const res = await fetch(`${apiRoot}/executions/sampled/${id}/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error || !json.data) {
        dispatch(executeActions.setItemError("sampled", json.error));
        if (json.error) {
          console.log(json.error);
        }
      } else {
        dispatch(executeActions.setItem("sampled", {...json.data, outputItem:outputItem}));
      }
    } catch (err) {
      dispatch(executeActions.setItemError("sampled", err));
      console.log(err);
    }
  };

  const newItem = () => {
    dispatch(
      executeActions.setItem(
        "sampled",
        JSON.parse(JSON.stringify(sampledItemInitialState.item))
      )
    );
  };

  const deleteItem = async (id) => {
    dispatch(executeActions.setItemError("sampled", null));
    try {
      const res = await fetch(`${apiRoot}/executions/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(
          executeActions.deleteItemError("sampled", json.error)
        );
        console.log(json.error);
      } else {
        dispatch(executeActions.deleteItem("sampled", id));
      }
    } catch (err) {
      dispatch(executeActions.deleteItemError("sampled", err));
      console.log(err);
    }
  };

  const createItem = async (newName) => {
    if (validateItem(sampledItem.errors)) {
      dispatch(executeActions.setItemError("sampled", null));
      try {
        let data = JSON.parse(JSON.stringify(sampledItem));
        delete data.errors;
        data.duration = parseInt(data.duration);
        data.seed = parseInt(data.seed)
        for (let template of data.templates) {
          template.weight = parseFloat(template.weight);
        }
        for (let output of data.outputs) {
          if (output.errors) {
            delete output.errors;
          }
          if (output.id.indexOf("temp_") > -1) {
            delete output.id;
          }
          if (output.statisticName === "Peak") {
            delete output.unit;
            delete output.unitId;
            delete output.value;
          } else {
            output.value = parseFloat(output.value);
          }
        }
        if (newName) {
          delete data.id;
          data.name = newName;
        }
        const res = await fetch(`${apiRoot}/executions/sampled/`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        });
        const json = await res.json();
        if (res.status === 401) {
          window.localStorage.removeItem("rrftToken");
          dispatch(userActions.userError("Incorrect email or password"));
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
        } else if (res.status === 403) {
          dispatch(
            userActions.userError(
              "You do not have the appropriate permissions to view / use this resource."
            )
          );
        } else if (json.error || !json.data) {
          dispatch(
            executeActions.setItemError("sampled", json.error)
          );
          if (json.error) {
            console.log(json.error);
          }
        } else {
          dispatch(executeActions.setItem("sampled", json.data));
        }
      } catch (err) {
        dispatch(executeActions.setItemError("sampled", err));
        console.log(err);
      }
    } else {
      dispatch(
        executeActions.setItemError("sampled", "Missing required fields.")
      );
    }
  };

  const updateItem = async (id) => {
    if (validateItem(sampledItem.errors)) {
      dispatch(executeActions.setItemError("sampled", null));
      try {
        let data = JSON.parse(JSON.stringify(sampledItem));
        delete data.errors;
        data.duration = parseInt(data.duration);
        data.seed = parseInt(data.seed)
        for (let template of data.templates) {
          template.weight = parseFloat(template.weight);
        }
        for (let output of data.outputs) {
          if (output.id.indexOf("temp_") > -1) {
            delete output.id;
          }
          if (output.errors) {
            delete output.errors;
          }
          output.value = parseFloat(output.value);
        }
        const res = await fetch(`${apiRoot}/executions/sampled/`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        });
        const json = await res.json();
        if (res.status === 401) {
          window.localStorage.removeItem("rrftToken");
          dispatch(userActions.userError("Incorrect email or password"));
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
        } else if (res.status === 403) {
          dispatch(
            userActions.userError(
              "You do not have the appropriate permissions to view / use this resource."
            )
          );
        } else if (json.error || !json.data) {
          dispatch(
            executeActions.setItemError("sampled", json.error)
          );
          if (json.error) {
            console.log(json.error);
          }
        } else {
          dispatch(executeActions.setItem("sampled", json.data));
        }
      } catch (err) {
        dispatch(executeActions.setItemError("sampled", err));
        console.log(err);
      }
    } else {
      dispatch(
        executeActions.setItemError("sampled", "Missing required fields.")
      );
    }
  };

  const executeItem = async (id) => {
    dispatch(executeActions.setItemError("sampled", null));
    try {
      updateStatus({id, status:'Sending HTTP request'})
      const res = await fetch(`${apiRoot}/executions/${id}/start/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(executeActions.setItemError("sampled", json.error));
        console.log(json.error);
      }
      if (!res.ok) {
        updateStatus({ id, status:'HTTP request failed'});
      }
    } catch (err) {
      dispatch(executeActions.setItemError("sampled", err));
      updateStatus({id, status:'HTTP request failed'})
      console.log(err);
    }
  };

  const onChange = (e) => {
    dispatch(executeActions.handleChange("sampled", e));
  };

  const validateItem = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  const setSampledItemError = (error) => {
    dispatch(executeActions.setItemError("sampled", error));
  };

  const fetchAnalysisTemplates = async (analysisId) => {
    try {
      const res = await fetch(`${apiRoot}/analyses/${analysisId}/templates/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error || !json.data) {
        dispatch(
          executeActions.setAnalysisTemplatesError(json.error)
        );
        if (json.error) {
          console.log(json.error);
        }
      }
      dispatch(executeActions.setAnalysisTemplates(json.data));
    } catch (err) {
      dispatch(executeActions.setAnalysisTemplatesError(err));
      console.log(err);
    }
  };

  const fetchModelTemplates = (templates, modelId) => {
    let result = [];
    if (modelId) {
      for (let template of templates) {
        if (template.modelId === modelId) {
          result.push({
            templateId: template.id,
            templateName: template.name,
            weight: 1,
          });
        }
      }
      const sortedResults = result.sort((a, b) => a.templateName.localeCompare(b.templateName))
      dispatch(executeActions.setItemTemplates("sampled", sortedResults));
    } else {
      dispatch(executeActions.setItemTemplates("sampled", []));
    }
  };

  const onChangeModel = (modelId) => {
    fetchModelTemplates(analysisTemplates, modelId);
    dispatch(executeActions.handleChangeModel("sampled", "modelId", modelId));
    dispatch(executeActions.setItemOutputs("sampled", []))
  };

  const updateItemTemplate = (templates, id, weight) => {
    let updatedTemplates = JSON.parse(JSON.stringify(templates));
    for (let template of updatedTemplates) {
      if (template.templateId === id) {
        template.weight = parseFloat(weight);
      }
    }
    dispatch(executeActions.setItemTemplates("sampled", updatedTemplates));
  };

  const fetchAnalysisComponents = async (analysisId) => {
    try {
      const res = await fetch(`${apiRoot}/analyses/${analysisId}/components/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error || !json.data) {
        dispatch(
          executeActions.setAnalysisComponentsError(json.error)
        );
        if (json.error) {
          console.log(json.error);
        }
      }
      dispatch(executeActions.setAnalysisComponents(json.data));
    } catch (err) {
      dispatch(executeActions.setAnalysisComponentsError(err));
      console.log(err);
    }
  };

  const fetchModelComponents = (components, modelId) => {
    let result = [];
    if (modelId) {
      for (let component of components) {
        if (component.modelId === modelId) {
          result.push({
            id: component.id,
            name: component.name,
            componentTypeId: component.componentTypeId,
          });
        }
      }
    }
    const sortedResults = result.sort((a, b) => a.name.localeCompare(b.name))
    return sortedResults;
  };

  const fetchVariables = (components, componentTypes, componentId) => {
    let result = [];
    let componentTypeId = null;
    for (let component of components) {
      if (component.id && component.id.toString() === componentId.toString()) {
        componentTypeId = component.componentTypeId;
      }
    }
    for (let componentType of componentTypes) {
      if (componentType.id && componentType.id.toString() === componentTypeId) {
        result = componentType.variables;
      }
    }
    return result;
  };

  const fetchStatistics = (
    components,
    componentTypes,
    componentId,
    variableId
  ) => {
    const variables = fetchVariables(components, componentTypes, componentId);
    let result = [];
    for (let variable of variables) {
      if (variable.id === variableId) {
        result = variable.statistics;
      }
    }
    return result;
  };

  const fetchUnits = (units, variableName, statisticName) => {
    let result = [];
    if (statisticName === "Peak") {
      onChangeOutput("unitId", "");
      onChangeOutput("unit", "");
      onChangeOutput("value", 0);
    } else if (statisticName === "Volume") {
      for (let unit of units) {
        if (
          unit.name === "Minute" ||
          unit.name === "Hour" ||
          unit.name === "Day"
        ) {
          result.push(unit);
        }
      }
    } else if (statisticName === "Threshold") {
      for (let unit of units) {
        if (
          (variableName === "Inflow" || variableName === "Outflow") &&
          (unit.name === "CFS" || unit.name === "CMS")
        ) {
          result.push(unit);
        } else if (
          variableName === "Stage" &&
          (unit.name === "FT" || unit.name === "M")
        ) {
          result.push(unit);
        }
      }
    }
    return result;
  };

  const onChangeOutput = (name, value) => {
    dispatch(executeActions.handleChangeOutput("output", name, value));
  };

  const fetchItemOutput = (outputs, id) => {
    for (let output of outputs) {
      if (output.id === id) {
        dispatch(
          executeActions.setOutputItem(JSON.parse(JSON.stringify(output)))
        );
      }
    }
  };

  const newItemOutput = () => {
    dispatch(executeActions.setOutputItem({...outputItemInitialState}));
  };

  const addItemOutput = (outputs, outputItem) => {
    if (validateItem(outputItem.errors)) {
      let newOutputs = [...outputs, {...outputItem, id: "temp_" + Date.now()}]
      dispatch(executeActions.setItemOutputs("sampled", newOutputs));
      newItemOutput();
    }
  };

  const updateItemOutput = (outputs, outputItem) => {
    if (validateItem(outputItem.errors)) {
      let newOutputs = outputs.filter((o) => o.id !== outputItem.id);
      newOutputs.push(outputItem);
      dispatch(executeActions.setItemOutputs("sampled", newOutputs));
      newItemOutput();
    }
  };

  const deleteItemOutput = (outputs, id) => {
    let newOutputs = outputs.filter((o) => o.id !== id);
    dispatch(executeActions.setItemOutputs("sampled", newOutputs));
    newItemOutput();
  };

  const updateStatus = (statusInfo) => {
    dispatch(executeActions.setListStatus("sampled", statusInfo));
    dispatch(executeActions.setItemStatus("sampled", statusInfo));
  };
  const updateListStatus = (statusInfo) => {
    dispatch(executeActions.setListStatus("sampled", statusInfo))
  }


  const resetState = () => {
    dispatch(executeActions.resetState());
  }
   
  const setDefault = (field) => {
    dispatch(executeActions.setItemFieldDefault(field))
  }

  return {
    sampledList,
    sampledActiveList,
    sampledItem,
    sampledError,
    outputItem,
    savepoint,
    analysisTemplates,
    analysisTemplatesError,
    analysisComponents,
    analysisComponentsError,
    fetchList,
    newItem,
    fetchItem,
    deleteItem,
    createItem,
    updateItem,
    executeItem,
    onChange,
    onChangeModel,
    onChangeOutput,
    fetchAnalysisTemplates,
    fetchModelTemplates,
    updateItemTemplate,
    fetchAnalysisComponents,
    fetchModelComponents,
    fetchVariables,
    fetchStatistics,
    fetchUnits,
    fetchItemOutput,
    newItemOutput,
    addItemOutput,
    updateItemOutput,
    deleteItemOutput,
    updateStatus,
    updateListStatus,
    resetState,
    setDefault,
    setSampledItemError,
  };
};