import { useContext } from "react";
import { DispatchStore, StateStore } from "../store/GlobalStore";
import { apiRoot } from "../services/api";
import { userActions } from "../reducers/userReducer";

export const useDependencyAPI = () => {
    const { state } = useContext(StateStore);
    const { dispatch } = useContext(DispatchStore);

    const dependencyTypes = {
        SAMPLE: "sample",
        FREQUENCY: "frequency",
        MODEL: "model",
    }
    const fetchDependencies = async (id, type) => {
        console.log(id, type)
        try {
        const res = await fetch(`${apiRoot}/dependencies/${id}/${type}/`, {
            headers: {
            Authorization: `Bearer ${state.user.token}`,
            },
        });
        const json = await res.json();
        if (res.status === 401) {
            window.localStorage.removeItem("rrftToken");
            dispatch(userActions.userError("Incorrect email or password"));
            dispatch(userActions.setToken(null));
            dispatch(userActions.setMe(null));
            return {error: "Incorrect email or password"}
        } else if (res.status === 403) {
            dispatch(
            userActions.userError(
                "You do not have the appropriate permissions to view / use this resource."
            )
            );
            return {error: "You do not have the appropriate permissions to view / use this resource."}
        } else if (json.error) {
            console.log(json.error);
            return {error: json.error}
        } else if (!json.data) {
            return {error: "No Data"}
        } else {
            return json.data
        }
        } catch (err) {
            console.log(err);
            return {error: err}
        }
    };

    return {dependencyTypes, fetchDependencies}
}