import React from "react";
import { Row, Col, FormGroup, Label } from "reactstrap";
import { usePrecipFrequencyAPI } from "../hooks/precipFrequencyAPI";
// import { normalizeFloat } from "../util/normalize";
import { NumericFormat } from 'react-number-format';

const ParameterInput = ({ readOnly, index, parameter }) => {
  const { handleParameterChange, item, analysis } = usePrecipFrequencyAPI();
  const placeholderText = readOnly
    ? "Process A14 grids to get value"
    : `Enter a value for ${parameter.commonName}`;
    const switchElement = (e) => {
        const id = e.target.id
        const idArray = id.split("-")
        const isFormattedElement = idArray.length === 2 ? true : false
        if (isFormattedElement) {
            const elInputName = idArray[1]
            const elInput = document.getElementById(elInputName)
            elInput.style.display = "inline";
            elInput.focus()
            const elFormatted = e.target
            elFormatted.style.display = "none";
        } else {
            const id = e.target.id
            const elInput = e.target
            elInput.style.display = "none";
            elInput.blur()
            const elFormattedName = "formatted-" + id
            const elFormatted = document.getElementById(elFormattedName)
            elFormatted.style.display = "inline";
        }
    }
    let input = (
        <div><NumericFormat
            id={'formatted-'+index}
            value={parameter.value.toString()}
            className="form-control"
            placeholder={placeholderText}
            required
            decimalScale={analysis.decimalDigitsGeneral}
            displayType={'input'}
            onFocus={switchElement}
        />
        <NumericFormat
            name={parameter.variableName}
            id={index}
            value={parameter.value.toString()}
            onChange={handleParameterChange}
            className="form-control"
            placeholder={placeholderText}
            required
            displayType={'input'}
            style={{display: 'none'}}
            onBlur={switchElement}
        />
        </div>
    )
  return (
    <Row>
      <Col md="12">
        <FormGroup className="mt-3">
          <Label for="location">
            <span style={{ textTransform: "capitalize" }}>
              {parameter.commonName}
            </span>
            &nbsp; ({parameter.variableName})
          </Label>
          {input}
          {item.errors.bootstrap.distributionParameters[index].value.length >
            0 && (
            <span className="text-danger">
              <b>{item.errors.bootstrap.distributionParameters[index].value}</b>
            </span>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
};

export const DistributionParameterForm = ({ readOnly }) => {
  const { item } = usePrecipFrequencyAPI();
  const parameters = item.bootstrap.distributionParameters;
  return (
    <React.Fragment>
      {parameters.map((p, index) => (
        <ParameterInput
          key={index}
          readOnly={readOnly}
          parameter={p}
          index={index}
        />
      ))}
    </React.Fragment>
  );
};
