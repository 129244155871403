import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export const ActionModal = props => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} centered>
      <ModalHeader toggle={props.toggle}>{props.header}</ModalHeader>
      <ModalBody>{props.body}</ModalBody>
      <ModalFooter>
        <Button
          title="Delete"
          color="primary"
          size="sm"
          onClick={props.action}
          className="me-1"
        >
          Continue
        </Button>
        <Button color="secondary" size="sm" onClick={props.toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
