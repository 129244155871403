const SET_USER = "SET_USER";
const SET_USER_ERROR = "SET_USER_ERROR";
const SET_ME = "SET_ME";
const SET_TOKEN = "SET_TOKEN";
const SET_USER_LIST = "SET_USER_LIST";
const HANDLE_CHANGE = "HANDLE_USER_CHANGE";
const SET_ACTIVE = "SET_ACTIVE";
const HANDLE_ROLE_CHANGE = "HANDLE_ROLE_CHANGE";
export const userActions = {
  setUser(user) {
    return {
      type: SET_USER,
      user: user,
    };
  },
  userError(error) {
    return {
      type: SET_USER_ERROR,
      error: error,
    };
  },
  handleRoleChange(roles) {
    return {
      type: HANDLE_ROLE_CHANGE,
      roles: roles,
    };
  },
  handleChange(e) {
    if (e.persist) {
      e.persist();
    }

    return {
      type: HANDLE_CHANGE,
      event: e,
    };
  },
  setMe(me) {
    return {
      type: SET_ME,
      me: me,
    };
  },
  setUserList(users) {
    return {
      type: SET_USER_LIST,
      users: users,
    };
  },
  setToken(token, remember) {
    return {
      type: SET_TOKEN,
      token: token,
      remember: remember,
    };
  },
  setActive(isActive) {
    return {
      type: SET_ACTIVE,
      isActive: isActive,
    };
  },
};

export const userReducer = (state, action) => {
  switch (action.type) {
    case SET_USER:
      if (!action.user.errors) {
        action.user.errors = {
          roles: "",
          active: "",
          email: "",
        };
      }
      return {
        ...state,
        user: action.user,
      };
    case SET_USER_LIST:
      return {
        ...state,
        list: action.users,
      };
    case SET_ACTIVE:
      return {
        ...state,
        user: { ...state.user, active: action.isActive },
      };
    case HANDLE_ROLE_CHANGE:
      return {
        ...state,
        user: {
          ...state.user,
          roles: action.roles,
          errors: {
            ...state.user.errors,
            roles: action.roles.length > 0 ? "" : "Required.",
          },
        },
      };
    case HANDLE_CHANGE:
      const { name, value } = action.event.target;
      let errors = state.user.errors;
      errors[name] = value.length < 1 ? "Required." : "";
      return {
        ...state,
        user: { ...state.user, [name]: value, errors },
      };
    case SET_TOKEN:
      if (action.remember) {
        window.localStorage.setItem("rrftToken", action.token);
      }
      return {
        ...state,
        token: action.token,
      };
    case SET_ME:
      return {
        ...state,
        me: action.me,
      };
    case SET_USER_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export const userInitialState = {
  list: [],
  token: window.localStorage.getItem("rrftToken"),
  user: {
    email: "",
    active: true,
    roles: [],
    errors: {
      email: "Required.",
      active: "",
      roles: "Required.",
    },
  },
  me: null,
  error: null,
};
