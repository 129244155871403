import React, { useState, useMemo, useEffect, useRef} from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Empirical, A14, Analytical } from "../reducers/precipFrequencyReducer";
import { EmpiricalExpectedCDF } from "./EmpiricalExpectedCDF";
import { NoaaA14GisData } from "./NoaaA14GisData";
import { AnalyticalDistParams } from "./AnalyticalDistParams";
import { usePrecipFrequencyAPI } from "../hooks/precipFrequencyAPI";
import { useDomainListAPI } from "../hooks/domainListAPI";
import { checkErrors } from "../util/validation";
import { useLoading } from "../hooks/Loading";
import { AepChart } from "../components/AepPlotlyChart";
import Loader from "./Loader";

export const PrecipFrequencyForm = ({ analysisId }) => {
  const [isLoading, load, sleep] = useLoading();
  const { frequencyTypes } = useDomainListAPI();
  const {
    item,
    list,
    error,
    analysis,
    onChange,
    onFrequencyTypeChange,
    createItem,
    updateItem,
    newItem,
    fetchList,
    transformDataToSeriesPlotly,
  } = usePrecipFrequencyAPI();
  const [modalShow, setModalShow] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [saveAsModalShow, setSaveAsModalShow] = useState(false);
  const [newName, setNewName] = useState('');
  const [savedName, setSavedName] = useState('');
  const [saveAsError, setSaveAsError] = useState('');
  const errorRef = useRef(error)
  const isValid = useMemo(() => checkErrors(item.errors), [item]);
  
  // eslint-disable-next-line
  const chartData = useMemo(
    () => transformDataToSeriesPlotly(item.curves),
    [transformDataToSeriesPlotly, item.curves]
  );
  
  
  const save = async () => {
    if (isValid) {
      setValidationError("");
      if (item.id) {
        await load(updateItem(analysisId)).then(async () => {
          onSave()
        });
      } else {
        await load(createItem(analysisId)).then(async () => {
          onSave()
        })
      }
    } else {
      setValidationError("Please complete all required fields.");
    }
  };
  const onSave = async () => {
    if (!errorRef.current){
      setModalShow(true);
      load(fetchList(analysisId));
      await sleep(2000)
      setModalShow(false); 
    }
  }
  const changeName = (e) => {
    e.persist();
    setSaveAsError('');
    setNewName(e.target.value);
  };
  const saveAs = async () => {
    setSaveAsError('');
    if (newName === "" || list.some(item => item.name === newName)) {
      setSaveAsError('Please enter a new name.');
    }
    else {
      await load(createItem(analysisId, newName));
      if (!error) {
        setSavedName(newName.toString());
        setSaveAsModalShow(true);
        load(fetchList(analysisId));
        await sleep(2000)
        setSaveAsModalShow(false);
        setNewName('');
        setSaveAsError('');
      }
      else {
        setSaveAsError(error);
      }
    }
  };

  useEffect(() => {
    newItem();
    setSaveAsError('');
  // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setNewName('')
    setSaveAsError('')
  }, [item.id])

  return (
    <React.Fragment>
      <Row className="mt-3">
        <Col md="12">
          <Card>
            <CardBody>
              <div className="form">
                <fieldset>
                  <legend>General</legend>
                  <Row>
                    <Col md="6">
                      <Row>
                        <Col md="12">
                          <FormGroup className="mt-3">
                            <Label for="name">Name</Label>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Enter precipitation frequency name"
                              name="name"
                              value={item.name}
                              onChange={onChange}
                            />
                            {item.errors.name.length > 0 && (
                              <span className="text-danger">
                                {item.errors.name}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="6">
                      <Row>
                        <Col md="12">
                          <FormGroup className="mt-3">
                            <Label>Analysis type</Label>
                            <select
                              className="form-control"
                              title="Select an Analysis type"
                              name="frequencyType"
                              value={item.frequencyType}
                              disabled={item.id}
                              onChange={(e) => {setSaveAsError('');onFrequencyTypeChange(e)}}
                            >
                              <option value="" />
                              {frequencyTypes.map((f) => (
                                <option key={f.id} value={f.name}>
                                  {f.name}
                                </option>
                              ))}
                            </select>
                            {item.errors.frequencyType.length > 0 && (
                              <span className="text-danger">
                                {item.errors.frequencyType}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mt-3">
                        <Label for="description">Description</Label>
                        <Input
                          type="textarea"
                          rows="2"
                          className="form-control"
                          placeholder="Enter description"
                          name="description"
                          value={item.description}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {item.frequencyType === Empirical && (
                    <EmpiricalExpectedCDF precipFrequencyId={item.id} />
                  )}
                  {item.frequencyType === Analytical && (
                    <AnalyticalDistParams precipFrequencyId={item.id} />
                  )}
                  {item.frequencyType === A14 && (
                    <NoaaA14GisData precipFrequencyId={item.id} />
                  )}
                  <Row>
                    <Row>
                      <Col md="12" className="mt-2">
                        <div className="border shadow-sm" style={{ width: "100%" }}>
                          {item.curves.length && item.curves.length > 1 
                          ? (<AepChart
                              yAxisText={"Precipitation (inches)"}
                              xAxisText={"Annual Exceedance Probability"}
                              dataSeries={chartData}
                              decimalDigitsGeneral={analysis.decimalDigitsGeneral}
                              decimalDigitsAep={analysis.decimalDigitsAep}
                              downloadFileName={item.name}/>) 
                          : (<div className="p-5 text-center">No plot data available.</div>)}
                        </div>
                      </Col>
                    </Row>
                    <Col md="12" className="mt-3">
                      <Button
                        color="dark"
                        size="sm"
                        className="me-2"
                        title="Save"
                        disabled={!isValid}
                        onClick={save}
                        id="saveButton"
                      >
                        <FontAwesomeIcon icon={faSave} className="me-2" />
                        <span>Save</span>
                      </Button>
                      <Button
                        color="default"
                        size="sm"
                        title="Reset form"
                        onClick={newItem}
                        className="me-2"
                      >
                        <span>Reset Form</span>
                      </Button>
                      {(modalShow && !error) && (
                        <span className="text-success">
                          <FontAwesomeIcon icon={faCheck} className="me-2" />Precipitation frequency saved!
                        </span>
                      )}
                      {error && (
                        <span className="text-danger">
                          <b>{error}</b>
                        </span>
                      )}
                      {validationError !== "" && (
                        <span className="text-danger">
                          <b>{validationError}</b>
                        </span>
                      )}
                    </Col>
                  </Row>
                  {item.id &&
                    <Row className="mt-3">
                      <Col className="col-auto pe-2">
                        <Label for="newName">Save as</Label>
                      </Col>
                      <Col className="col-auto pe-2">
                        <Input
                          type="text"
                          name="newName"
                          placeholder="Enter new precipitation frequency name"
                          value={newName}
                          onChange={changeName}
                          style={{ minWidth: '300px' }}
                          disabled={saveAsModalShow}
                        />
                      </Col>
                      <Col className="col-auto pe-2">
                        <Button color="dark" size="sm" title="Save As" onClick={saveAs} disabled={saveAsModalShow}>
                          <FontAwesomeIcon icon={faSave} className="me-2" />
                          <span>Save</span>
                        </Button>
                      </Col>
                      {saveAsError !== "" && (
                        <Col className="col-auto">
                          <span className="text-danger">
                            <b>{saveAsError}</b>
                          </span>
                        </Col>
                      )}
                      {(saveAsModalShow && !saveAsError) && (
                        <Col className="col-auto">
                          <span className="text-success">
                            <FontAwesomeIcon icon={faCheck} className="me-2" />
                            {`Precipitation frequency saved as: ${savedName}`}
                          </span>
                        </Col>
                      )}
                    </Row>
                  }
                </fieldset>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
      </Row>
      <Loader loading={isLoading} />
    </React.Fragment>
  );
};
