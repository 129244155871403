import React, { useState, useMemo, useEffect } from "react";
import {
  createColumnHelper
} from '@tanstack/react-table';
import {
  Input,
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSave
} from "@fortawesome/free-solid-svg-icons";
import { useUsers } from "../hooks/userApi";
import { useDomainListAPI } from "../hooks/domainListAPI";
import { InfoModal } from "./InfoModal";
import { ListTable } from "./ListTable";
import Select from "react-select";
import { checkErrors } from "../util/validation";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();
const UsersForm = () => {
  const { roles } = useDomainListAPI();
  const options = roles.map((r) => {
    return { value: r.name, label: r.name, role: r };
  });
  const {
    user,
    error,
    onChange,
    setActive,
    onRoleChange,
    fetchList,
    saveUser,
    newUser,
  } = useUsers();
  const [modalShow, setModalShow] = useState(false);
  const [selectedRoles, setRoles] = useState();
  const [validationError, setValidationError] = useState("");
  const isValid = useMemo(() => checkErrors(user.errors), [user]);
  console.log(isValid);
  useMemo(() => {
    setRoles(
      user.roles.map((r) => {
        return {
          value: r.name,
          label: r.name,
          role: r,
        };
      })
    );
  }, [user.roles]);
  const save = async () => {
    if (isValid) {
      setValidationError("");
      await saveUser();
      await fetchList();
      if (!error) {
        setModalShow(true);
      }
    } else {
      setValidationError("Please fill out required fields");
    }
  };
  const changeHandler = (opt) => {
    let roles = opt ? opt.map((o) => o.role) : [];
    onRoleChange(roles);
  };

  return (
    <React.Fragment>
      <Row className="mt-3">
        <Col md="12">
          <Card>
            <CardBody>
              <div className="form">
                <fieldset>
                  <legend>{user.id ? "Edit User" : "Add User"}</legend>
                  <Row>
                    <Col md="12">
                      <Row>
                        <Col md="4">
                          <FormGroup className="mt-3">
                            <Label for="email">Email</Label>
                            <Input
                              type="text"
                              className="form-control mt-3"
                              placeholder="Enter Email"
                              name="email"
                              value={user.email}
                              onChange={onChange}
                            />
                            {user.errors.email.length > 0 && (
                              <span className="text-danger">
                                <b>{user.errors.email}</b>
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mt-3">
                            <Label for="roles">Roles</Label>
                            <Select
                              isMulti
                              placeholder={"Add a Role"}
                              components={animatedComponents}
                              value={selectedRoles}
                              onChange={changeHandler}
                              options={options}
                            />
                            {user.errors.roles.length > 0 && (
                              <span className="text-danger">
                                <b>{user.errors.roles}</b>
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mt-3">
                            <Label for="isActiveToggler" className="me-2 mt-5">Active</Label>
                            <Input
                              checked={user.active}
                              type="switch"
                              id="isActiveToggler"
                              onChange={(e) => setActive(e)}
                              className="mt-5"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" className="mt-3">
                      <Button
                        color="dark"
                        size="sm"
                        className="me-2"
                        title="Save"
                        onClick={save}
                      >
                        <FontAwesomeIcon icon={faSave} className="me-2" />
                        <span>Save</span>
                      </Button>
                      <Button
                        color="default"
                        size="sm"
                        title="Reset form"
                        onClick={newUser}
                      >
                        <span>Reset Form</span>
                      </Button>
                    </Col>
                  </Row>
                  {validationError !== "" && (
                    <Row>
                      <Col md="12">
                        <span className="text-danger">
                          <b>{validationError}</b>
                        </span>
                      </Col>
                    </Row>
                  )}
                </fieldset>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <InfoModal
        isOpen={modalShow && !error}
        toggle={() => setModalShow(!modalShow)}
        header={"Save User"}
        body={"User saved!"}
      />
    </React.Fragment>
  );
};

export const Users = () => {
  const { list, me, fetchItem, fetchList, newUser } = useUsers();
  const { fetchLists } = useDomainListAPI();
  useEffect(() => {
    fetchList();
    fetchLists();
    // eslint-disable-next-line
  }, [me.email]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('email', {
      cell: (info) => (
        <span
          style={{
            cursor: "pointer",
            color: "blue",
          }}
          onClick={() => fetchItem(info.row.original.id)}
          title={"Edit User"}
        >
          {info.getValue()}
        </span>
      ),
      header: 'Users',
    }),
  ];
  const listExists = list && list.length > 0;

  return (
    <React.Fragment>
      <Container className="pt-5 pb-5">
        <Row>
          <Col md="12" className="pt-2">
            <h2>Users</h2>
          </Col>
        </Row>
        {listExists && (
          <React.Fragment>
            <Row>
              <Col md="12 text-end mb-2">
                <a
                  href="#!"
                  className="btn btn-default btn-sm"
                  title="Add a new user"
                  onClick={() => newUser()}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  <span>Add New</span>
                </a>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <ListTable data={list} columns={columns} />
              </Col>
            </Row>
          </React.Fragment>
        )}
        <UsersForm />
      </Container>
    </React.Fragment>
  );
};
