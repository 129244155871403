import React, { useContext } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { ButtonNavigation } from "./ButtonNavigation";
import { ModelList } from "./ModelList";
import { StateStore } from "../store/GlobalStore";

const webRoot = process.env.REACT_APP_WEB_CONTEXT === "/" ? "" : process.env.REACT_APP_WEB_CONTEXT

export const Model = () => {
  const { state } = useContext(StateStore);
  const analysis = state.analysis.savepoint;
  return (
    <div>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <div className="form">
                <fieldset>
                  <legend>HEC-HMS Model</legend>
                  <ModelList analysisId={analysis.id} />
                </fieldset>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ButtonNavigation
        prevLink={`${webRoot}/analysis/${analysis.id}/sampling`}
        prevLinkTooltip="Go back to Sampling tab"
        nextLink={`${webRoot}/analysis/${analysis.id}/execute`}
        nextLinkTooltip="Go to Execute tab"
      />
    </div>
  );
};
