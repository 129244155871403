import React, { useState } from "react";
import { unstable_useBlocker as useBlocker } from "react-router-dom";
import { ActionModal } from "../components/ActionModal";

export const Prompt = ({ header, message, shouldPrompt }) => {
    
  let blocker = useBlocker(shouldPrompt);  
  const [showModal, setShowModal] = useState(false);
  const handleConfirm = () => {
    blocker.proceed();
  };
  const handleCancel = () => {
    blocker.reset();
    setShowModal(!showModal);
  };
  return (
    <ActionModal
      header={header}
      body={message}
      isOpen={blocker.state === "blocked"}
      action={() => handleConfirm()}
      toggle={() => handleCancel()}
    />
  );
};