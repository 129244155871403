import "./css/App.css";
import React from "react";
import { Provider } from "./store/GlobalStore";
import router from "./Router";
import { RouterProvider } from "react-router-dom";

const App = () => {
  return (
    <Provider>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </Provider>
  );
};

export default App;