import "../css/BootstrapConfiguration.css";
import React, { useMemo, useState, useEffect } from "react";
import { Row, Col, FormGroup, Label, Input, Button, UncontrolledTooltip } from "reactstrap";
import { usePrecipFrequencyAPI } from "../hooks/precipFrequencyAPI";
import { useDomainListAPI } from "../hooks/domainListAPI";
import { A14 } from "../reducers/precipFrequencyReducer";
import { DistributionParameterForm } from "./DistributionParameterForm";
import { checkErrors } from "../util/validation";
import { NumericFormat } from "react-number-format";
import { useLoading } from "../hooks/Loading";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "./Loader";

export const BootstrapParameters = ({ readOnly }) => {
  const [isLoading, load] = useLoading();
  const {
    item,
    onBootstrapChange,
    setBootstrapDefault,
    setDistribution,
    processA14,
    a14Bootstrap,
    bootstrap,
    analysis
  } = usePrecipFrequencyAPI();
  const { distributionTypes } = useDomainListAPI();
  const isValid = useMemo(() => checkErrors(item.errors), [item.errors]);
  const [defaultChecked, setDefaultChecked] = useState({
    parameters: true,
    aep: true,
    prng: true,
  });
  const defaultChangeHandler = (field) => {
    if (!defaultChecked[field]) {
      setBootstrapDefault(field);
    }
    setDefaultChecked({
      ...defaultChecked,
      [field]: !defaultChecked[field],
    });
  };
  const selectDistribution = (e) => {
    const index = e.target.options.selectedIndex;
    const distribution = distributionTypes[index - 1];
    for (let i = 0; i < distribution.parameters.length; i++) {
      distribution.parameters[i]["value"] = "";
    }
    setDistribution(distribution);
  };
  const switchElement = (e) => {
    const id = e.target.id
    const idArray = id.split("-")
    const isFormattedElement = idArray.length === 2 ? true : false
    if (isFormattedElement) {
      const elInputName = idArray[1]
      const elInput = document.getElementById(elInputName)
      elInput.style.display = "inline";
      elInput.focus()
      const elFormatted = e.target
      elFormatted.style.display = "none";
    } else {
      const id = e.target.id
      const elInput = e.target
      elInput.style.display = "none";
      elInput.blur()
      // example: "#formatted-minAep"
      const elFormattedName = "formatted-" + id
      const elFormatted = document.getElementById(elFormattedName)
      elFormatted.style.display = "inline";
    }
  }
  useEffect(() => {
    if (item.defaultsChecked){
      setDefaultChecked(item.defaultsChecked)
    }
  }, [item.defaultsChecked])
  return (
    <React.Fragment>
      <Row>
        <Col>
          {item.a14 && (
            <Button
              color="dark"
              size="sm"
              disabled={
                !item.a14.feature ||
                item.a14.region === "" ||
                item.duration === "" ||
                isLoading
              }
              className="me-2"
              title="Process A14 Grids"
              onClick={() => load(processA14())}
            >
              <span>Process A14 Grids</span>
            </Button>
          )}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md="12">
          <h3 style={{fontWeight:"400"}}>Bootstrap Configuration</h3>
        </Col>
      </Row>
      <Row>
        <Col md="8">
          <Row>
            <Col md="6">
              <FormGroup className="mt-3">
                <Label for="distributionType">Distribution type</Label>
                <select
                  className="form-control"
                  name="distributionType"
                  value={item.bootstrap.distributionType}
                  onChange={selectDistribution}
                >
                  <option value="" />
                  {distributionTypes.map((dt, index) => (
                    <option key={index} value={dt.abbrev}>
                      {dt.abbrev} ({dt.name})
                    </option>
                  ))}
                </select>
                {item.errors.bootstrap.distributionType.length > 0 && (
                  <span className="text-danger">
                    <b>{item.errors.bootstrap.distributionType}</b>
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col md="6"></Col>
          </Row>
          <Row className={'labelRow'}>
            <Col md="12" className="mt-3">
              <b>Bootstrap Parameters</b>
              <br />
              <Label for="useBootstrapDefaults" className={'smallLabel'}>Use Defaults</Label>&nbsp;&nbsp;<input type="checkbox" id="useBootstrapDefaults" value="true" checked={defaultChecked ? defaultChecked.parameters : true} onChange={() => defaultChangeHandler('parameters')} />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup className="mt-3">
                <Label for="realizations">Realizations
                  <span id={'infoIconRealization'}>
                    <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
                  </span>
                  <UncontrolledTooltip target="infoIconRealization" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
                    <div className="text-start">
                      <p>
                      Note: # Realizations must be between
                      </p>
                      <p>
                      - 100 and 10,000 for Quantile Sampling,
                      </p>
                      <p>
                      - 10 and 1000 for Full Uncertainty Sampling.
                      </p>
                    </div>
                  </UncontrolledTooltip>
                </Label>
                <Input
                  name="numRealizations"
                  value={item.bootstrap.numRealizations.toString()}
                  onChange={onBootstrapChange}
                  type="number"
                  className="form-control"
                  placeholder="Enter number of realizations"
                  required
                  disabled={defaultChecked.parameters}
                />
                {item.errors.bootstrap.numRealizations.length > 0 && (
                  <span className="text-danger">
                    <b>{item.errors.bootstrap.numRealizations}</b>
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mt-3">
                <Label for="numIncrements">Output increments</Label>
                <Input
                  name="numIncrements"
                  value={item.bootstrap.numIncrements.toString()}
                  onChange={onBootstrapChange}
                  type="number"
                  className="form-control"
                  placeholder="Enter number of output increments"
                  required
                  disabled={defaultChecked.parameters}
                />
                {item.errors.bootstrap.numIncrements.length > 0 && (
                  <span className="text-danger">
                    <b>{item.errors.bootstrap.numIncrements}</b>
                  </span>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup className="mt-3">
                <Label for="upperConfidence">Upper confidence interval</Label>
                <Input
                  name="upperConfidence"
                  value={item.bootstrap.upperConfidence.toString()}
                  onChange={onBootstrapChange}
                  type="number"
                  className="form-control"
                  placeholder="Enter upper confidence interval"
                  required
                  disabled={defaultChecked.parameters}
                />
                {item.errors.bootstrap.upperConfidence.length > 0 && (
                  <span className="text-danger">
                    <b>{item.errors.bootstrap.upperConfidence}</b>
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mt-3">
                <Label for="lowerConfidence">Lower confidence interval</Label>
                <Input
                  name="lowerConfidence"
                  value={item.bootstrap.lowerConfidence.toString()}
                  onChange={onBootstrapChange}
                  type="number"
                  className="form-control"
                  placeholder="Enter lower confidence interval"
                  required
                  disabled={defaultChecked.parameters}
                />
                {item.errors.bootstrap.lowerConfidence.length > 0 && (
                  <span className="text-danger">
                    <b>{item.errors.bootstrap.lowerConfidence}</b>
                  </span>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row className={'labelRow'}>
            <Col md="12" className="mt-3">
              <Label for="useAepDefaults" className={'smallLabel'}>Use Defaults</Label>&nbsp;&nbsp;<input type="checkbox" id="useAepDefaults" value="true" checked={defaultChecked ? defaultChecked.aep : true} onChange={() => defaultChangeHandler('aep')} />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup className="mt-3">
                <Label for="minAep">Minimum output AEP</Label>
                <NumericFormat
                  id="formatted-minAep"
                  value={parseFloat(item.bootstrap.minAep.toString())}
                  className="form-control"
                  placeholder="Enter minimum output probability"
                  required
                  displayType={'input'}
                  decimalScale={analysis.decimalDigitsAep}
                  onFocus={switchElement}
                  disabled={defaultChecked.aep}
                />
                <NumericFormat
                  name="minAep"
                  id="minAep"
                  value={parseFloat(item.bootstrap.minAep.toString())}
                  onChange={onBootstrapChange}
                  className="form-control"
                  placeholder="Enter minimum output probability"
                  required
                  displayType={'input'}
                  style={{ display: 'none' }}
                  onBlur={switchElement}
                />
                {item.errors.bootstrap.minAep.length > 0 && (
                  <span className="text-danger">
                    <b>{item.errors.bootstrap.minAep}</b>
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mt-3">
                <Label for="maxAep">Maximum output AEP</Label>
                <NumericFormat
                  id="formatted-maxAep"
                  value={item.bootstrap.maxAep.toString()}
                  className="form-control"
                  placeholder="Enter maximum output probability"
                  required
                  type="integer"
                  decimalScale={analysis.decimalDigitsAep}
                  displayType={'input'}
                  onFocus={switchElement}
                  disabled={defaultChecked.aep}
                />
                <NumericFormat
                  name="maxAep"
                  id="maxAep"
                  value={item.bootstrap.maxAep.toString()}
                  onChange={onBootstrapChange}
                  className="form-control"
                  placeholder="Enter maximum output probability"
                  type="integer"
                  required
                  displayType={'input'}
                  style={{ display: 'none' }}
                  onBlur={switchElement}
                />
                {item.errors.bootstrap.maxAep.length > 0 && (
                  <span className="text-danger">
                    <b>{item.errors.bootstrap.maxAep}</b>
                  </span>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row className={'labelRow'}>
            <Col md="12" className="mt-3">
              <Label for="usePrngDefault" className={'smallLabel'}>Use Defaults</Label>&nbsp;&nbsp;<input type="checkbox" id="usePrngDefault" value="true" checked={defaultChecked ? defaultChecked.prng : true} onChange={() => defaultChangeHandler('prng')} />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup className="mt-3">
                <Label for="rngSeed">PRNG seed</Label>
                <Input
                  name="rngSeed"
                  value={item.bootstrap.rngSeed && item.bootstrap.rngSeed.toString()}
                  onChange={onBootstrapChange}
                  type="number"
                  className="form-control"
                  placeholder="Enter PRNG seed"
                  disabled={defaultChecked.prng}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              {/* {item.a14 && (
                <Button
                  color="dark"
                  size="sm"
                  disabled={
                    !item.a14.feature ||
                    item.a14.region === "" ||
                    item.duration === "" ||
                    isLoading
                  }
                  className="me-2"
                  title="Process A14 Grids"
                  onClick={() => load(processA14())}
                >
                  <span>Process A14 Grids</span>
                </Button>
              )} */}
              <Button
                color="dark"
                size="sm"
                disabled={!isValid || isLoading}
                title="Run Bootstrap"
                onClick={
                  item.frequencyType === A14
                    ? () => load(a14Bootstrap())
                    : () => load(bootstrap())
                }
              >
                <span>Run Bootstrap</span>
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md="4" className="border-left">
          <Row>
            <Col md="12">
              <FormGroup className="mt-3">
                <Label for="erl">ERL</Label>
                <Input
                  name="erl"
                  value={item.bootstrap.erl.toString()}
                  onChange={onBootstrapChange}
                  type="number"
                  className="form-control"
                  placeholder={
                    readOnly
                      ? "Process A14 grids to get value"
                      : "Enter a value for ERL"
                  }
                  required
                />
                {item.errors.bootstrap.erl.length > 0 && (
                  <span className="text-danger">
                    <b>{item.errors.bootstrap.erl}</b>
                  </span>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="mt-3">
              <b>Distribution Parameters</b>
            </Col>
          </Row>
          <DistributionParameterForm readOnly={readOnly} />
        </Col>
      </Row>
      <Loader loading={isLoading} />
    </React.Fragment>
  );
};
