import { useContext } from "react";
import { DispatchStore, StateStore } from "../store/GlobalStore";
import { authRoot, apiRoot } from "../services/api";
import { userActions } from "../reducers/userReducer";
export const useUsers = () => {
  const { state } = useContext(StateStore);
  const { list, user, me, error, token } = state.user;
  const { dispatch } = useContext(DispatchStore);

  const login = async (email, password, remember) => {
    let token = state.user.token;
    try {
      if (!token) {
        let formData = new FormData();
        formData.append("user", email);
        formData.append("password", password);
        const res = await fetch(`${authRoot}/login`, {
          method: "POST",
          body: formData,
        });
        let json = await res.json();
        if (res.status === 401) {
          window.localStorage.removeItem("rrftToken");
          dispatch(userActions.userError("Incorrect email or password"));
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
        } else if (res.status === 403) {
          dispatch(
            userActions.userError(
              "You do not have the appropriate permissions to view / use this resource."
            )
          );
        } else {
          token = json.access;
          dispatch(userActions.setToken(token, remember));
          dispatch(userActions.userError(null));
        }
      }
      await getMe(token);
    } catch (err) {
      console.log(err);
    }
  };
  const isAuthenticated = () => {
    return token && me;
  };
  const isAdmin = () => {
    let role = me.roles.find((r) => r.name === "admin");
    return role;
  };
  const signOut = () => {
    dispatch(userActions.setToken(null));
    dispatch(userActions.setMe(null));
    window.localStorage.removeItem("rrftToken");
  };
  const getMe = async (token) => {
    if (!token) {
      token = window.localStorage.getItem("rrftToken");
    }
    if (token) {
      const res = await fetch(`${apiRoot}/users/me/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(userActions.userError(json.error));
      } else {
        dispatch(userActions.setMe(json.data));
      }
    }
  };
  const onRoleChange = (roles) => dispatch(userActions.handleRoleChange(roles));
  const onChange = (e) => dispatch(userActions.handleChange(e));
  const fetchList = async () => {
    try {
      const res = await fetch(`${apiRoot}/users/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(userActions.userError(json.error));
      }
      dispatch(userActions.setUserList(json.data));
    } catch (error) {
      dispatch(userActions.userError(error));
    }
  };

  const fetchItem = async (id) => {
    try {
      const res = await fetch(`${apiRoot}/users/${id}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(userActions.userError(json.error));
      }
      dispatch(userActions.setUser(json.data));
    } catch (error) {
      dispatch(userActions.userError(error));
    }
  };
  const setActive = (e) => dispatch(userActions.setActive(e.target.checked));
  const newUser = () =>
    dispatch(
      userActions.setUser({
        email: "",
        active: true,
        roles: [],
        errors: {
          email: "Required.",
          active: "",
          roles: "Required.",
        },
      })
    );
  const saveUser = async () => {
    try {
      let data = user;
      delete data.errors;
      const res = await fetch(`${apiRoot}/users/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await res.json();
      if (res.status === 401) {
        window.localStorage.removeItem("rrftToken");
        dispatch(userActions.userError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (res.status === 403) {
        dispatch(
          userActions.userError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else if (json.error) {
        dispatch(userActions.userError(json.error));
      }
      dispatch(userActions.setUser(json.data));
    } catch (error) {
      dispatch(userActions.userError(error));
    }
  };
  return {
    list,
    me,
    user,
    error,
    login,
    isAuthenticated,
    newUser,
    fetchList,
    fetchItem,
    setActive,
    onRoleChange,
    onChange,
    saveUser,
    isAdmin,
    signOut,
    getMe,
  };
};
