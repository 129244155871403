import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
const { DateTime, Duration } = require("luxon");

export const TSPlotlyChart = (
  {
    title,
    ts,
    xAxisText,
    xAxisMin,
    xAxisMax,
    yAxisText,
    yAxisMin,
    yAxisMax,
    yAxis2Text,
    yAxis2Min,
    yAxis2Max,
    decimalDigitsGeneral,
    decimalDigitsAep,
    downloadFileName,
    showLegend,
  }) => {
  const [data, setData] = useState([]);
  const [layout, setLayout] = useState({});

  var disk = {
    // Plotly disk: https://github.com/plotly/plotly.js/blob/master/src/fonts/ploticon.js
    'width': 857.1,
    'height': 1000,
    'path': 'm214-7h429v214h-429v-214z m500 0h72v500q0 8-6 21t-11 20l-157 156q-5 6-19 12t-22 5v-232q0-22-15-38t-38-16h-322q-22 0-37 16t-16 38v232h-72v-714h72v232q0 22 16 38t37 16h465q22 0 38-16t15-38v-232z m-214 518v178q0 8-5 13t-13 5h-107q-7 0-13-5t-5-13v-178q0-8 5-13t13-5h107q7 0 13 5t5 13z m357-18v-518q0-22-15-38t-38-16h-750q-23 0-38 16t-16 38v750q0 22 16 38t38 16h517q23 0 50-12t42-26l156-157q16-15 27-42t11-49z',
    'transform': 'matrix(1 0 0 -1 0 850)'
  };

  const convertTsToTrace = (ts) => {
    var series = []
    var t1_x = []
    var t1_y = []
    var t2_x = []
    var t2_y = []
    var sum = 0
    ts.data.forEach((d) => {
      t1_x.push(d.datetime)
      t1_y.push(d.value.doubleValue)
      t2_x.push(d.datetime)
      t2_y.push(sum += d.value.doubleValue)
    });
    series.push({
      name: ts.metaInfo.name,
      header: "Scaled Precipitation (in)",
      type: 'bar',
      x: t1_x,
      y: t1_y,
      width: Duration.fromISO(ts.metaInfo.timeInfo.interval).toMillis() * 1,
      offset: Duration.fromISO(ts.metaInfo.timeInfo.interval).toMillis() * -1,
    });
    series.push({
      name: "Accumulated",
      header: "Accumulated Precipitation (in)",
      type: 'line',
      x: t2_x,
      y: t2_y,
      yaxis: 'y2',
    });
    return series;
  }

  const addHoverTextToSeries = (inSeries) => {
    let copySeries = JSON.parse(JSON.stringify(inSeries));
    let outSeries = copySeries.map((series) => {
      series.hoverinfo = "text"
      let hoverText = []
      for (var i = 0; i < series.x.length; i++) {
        let str = series.name + "<br>x: " + DateTime.fromISO(series.x[i]).toLocaleString(DateTime.DATETIME_SHORT) + "<br>y: " + series.y[i].toFixed(decimalDigitsGeneral)
        hoverText.push(str)
      };
      series.hovertext = hoverText
      return series
    })
    return outSeries
  };

  const updateXAxis = () => {
    return {
      title: xAxisText ? xAxisText : "Datetime",
      gridcolor: "grey",
      zeroline: false,
      mirror: "all",
      showgrid: true,
      showline: true,
      range: [
        xAxisMin ? xAxisMin : null,
        xAxisMax ? xAxisMax : null,
      ],
      minor: {
        showgrid: true,
        gridcolor: "lightgrey",
      }
    }
  };

  const updateYAxis = () => {
    return {
      title: yAxisText ? yAxisText : "Values",
      zeroline: true,
      mirror: "all",
      showgrid: true,
      showline: true,
      autorange: (yAxisMin || yAxisMax) ? null : true,
      range: [
        yAxisMin ? yAxisMin : null,
        yAxisMax ? yAxisMax : null,
      ],
      rangemode: "tozero",
    }
  };

  const updateYAxis2 = () => {
    return {
      title: yAxis2Text ? yAxis2Text : "Accumulated Values",
      // zeroline: true,
      // mirror: "all",
      showgrid: false,
      showline: false,
      overlaying: 'y',
      side: 'right',
      autorange: (yAxis2Min || yAxis2Max) ? null : true,
      range: [
        yAxis2Min ? yAxis2Min : null,
        yAxis2Max ? yAxis2Max : null,
      ],
      rangemode: "tozero",
    }
  };

  const updateData = () => {
        return addHoverTextToSeries(convertTsToTrace(ts))
  };

  const cleanFileName = (filename) => {
    let cleanedTitle = filename
      .replace(/<\/?[^>]+(>|$)/g, "_")
      .replace(/[^a-zA-Z0-9]/g, "_")
      .replace(" ", "_")
      .replace(/_+/g, "_");
    if (cleanedTitle.endsWith("_")) {
      cleanedTitle = cleanedTitle.substring(0, cleanedTitle.length - 1);
    }
    return cleanedTitle;
  };

  const csvDownload = {
    name: 'downloadCsv',
    title: 'Download data as csv',
    icon: disk,
    click: function (d) {
      // Borrowed some from here https://github.com/plotly/plotly.js/issues/2171
      var data = d.data
      var csvData = [];
      // Create header
      var header = ['Datetime']
      let xArr = [];
      data.forEach((trace) => {
        header.push(trace.header);
        xArr = xArr.concat(trace.x);
      });
      csvData.push(header);

      // Remove duplicate x values
      let xArrNoDups = [...new Set([...xArr])];

      // Create rows
      xArrNoDups.forEach((x) => {
        let rowArr = [];
        rowArr.push(x)
        data.forEach((trace) => {
          rowArr.push(trace.y[trace.x.indexOf(x)]);
        });
        csvData.push(rowArr);
      });
      var csvFile = csvData.map(e => e.map(a => '"' + ((a ?? "").toString().replace(/"/gi, '""')) + '"').join(",")).join("\r\n");
      var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
      var link = document.createElement("a");
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", downloadFileName == null ?  "BAP_Plot_Data.csv" : cleanFileName(downloadFileName) + ".csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    const updatePlot = () => {
      let layout = {
        title: title ? title : null,
        margin: { t: 50 },
        showlegend: showLegend,
        xaxis: updateXAxis(),
        yaxis: updateYAxis(),
        yaxis2: updateYAxis2(),
      }
      let data = updateData()
      setData(data)
      setLayout(layout)
    }
    updatePlot()
    // eslint-disable-next-line
  }, [
    title,
    ts,
    xAxisText,
    xAxisMin,
    xAxisMax,
    yAxisText,
    yAxisMin,
    yAxisMax,
    decimalDigitsGeneral,
    decimalDigitsAep,
    showLegend,
  ]);

  return (
    <div>
      <Plot
        data={data}
        layout={layout}
        style={{ "width": "100%", "height": "100%" }}
        useResizeHandler
        config={{
          displaylogo: false,
          toImageButtonOptions: {
            filename: downloadFileName == null ? "Timeseries Plot" : downloadFileName,
            height: 500,
            width: 700,
          },
          modeBarButtons: [
            [csvDownload],
            [
              'toImage',
              'zoom2d',
              'pan2d',
              'autoScale2d',
              'resetScale2d',
            ]
          ]
        }}
      />
    </div>

  );
}
