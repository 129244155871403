import "../css/Login.css";
import React, { useState } from "react";
import { Form, Label, Input, Button } from "reactstrap";
import { useUsers } from "../hooks/userApi";
import { useNavigate } from "react-router-dom";

const webRoot = process.env.REACT_APP_WEB_CONTEXT === "/" ? "" : process.env.REACT_APP_WEB_CONTEXT;

export const Login = (props) => {
  const { login, error } = useUsers();
  let { redirectTo } = props;
  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");
  const hasToken = window.localStorage.getItem("rrftToken") ? true : false;
  const [remember, setRemember] = useState(hasToken);
  const navigate = useNavigate();
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        login(email, pw, remember).then(() => {
          redirectTo
            ? navigate(redirectTo, { replace: true })
            : navigate(`${webRoot}/analysisList`, { replace: true });
        });
      }}
      className="form-signin shadow bg-light"
    >
      <p style={{ whiteSpace: "nowrap" }}>
        <img className="mb-4 me-2" src={`${webRoot}/img/logo.png`} alt="" height="72" />
        <br />
        US Army Corps of Engineers
        <br />
        Rainfall Runoff Frequency Tool
      </p>
      <h2 className="h3 mb-3 font-weight-normal">Please sign in</h2>
      {/* <Label for="inputEmail" className="sr-only">
        Email address
      </Label> */}
      <Input
        type="email"
        id="email"
        className="form-control"
        value={email}
        placeholder="Email address"
        onChange={(e) => setEmail(e.target.value)}
        required
        autoFocus
      />
      {/* <Label for="inputPassword" className="sr-only">
        Password
      </Label> */}
      <Input
        type="password"
        id="password"
        onChange={(e) => setPw(e.target.value)}
        className="form-control"
        placeholder="Password"
        required
      />
      <div className="checkbox mb-3">
        <Label>
          <Input
            type="checkbox"
            value={remember}
            onChange={(e) => setRemember(!remember)}
          />{" "}
          Remember me
        </Label>
      </div>
      <Button type={"submit"} color="dark" size="lg" block>
        Sign in
      </Button>
      {error && <span className="text-danger"><b>{error}</b></span>}
    </Form>
  );
};
