import React, { useEffect, useState, useMemo } from 'react';
import { Card, CardBody, Label, Table, Button, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faXmark } from "@fortawesome/free-solid-svg-icons";
import { usePrecipFrequencyAPI } from "../hooks/precipFrequencyAPI";
import { NumericFormat } from "react-number-format";

export const EditTable = props => {
  const { analysis } = usePrecipFrequencyAPI();
  const { title, gdata, columns, emptyRowObj, onAdd, onEdit, onDelete } = props;
  const emptyRow = useMemo(() => {return emptyRowObj},[emptyRowObj])
  const [data, setData] = useState([emptyRow])
  useEffect(() => {
    if (gdata == null || gdata.length === 0){
      setData([emptyRow])
    } else {
      setData([...gdata, emptyRow])
    }
  }, [emptyRow, gdata])

  const onPaste = (e) => {
    let pasted = e.clipboardData.getData('text/plain'); 
    let parsed = pasted.trim().split(/\r\n|\n|\r/).map(row => row.split('\t'));
    parsed.forEach((row, rowIndex) => {
      if (row.every(col => col) && !row.some(isNaN)) {
        let dataRow = {};
        row.forEach((col, colIndex) => {
          if (colIndex < columns.length) {
            dataRow[columns[colIndex].name] = parseFloat(col);
          }
        });
        if (rowIndex > gdata.length - 1) {
          onAdd(dataRow);
        }
        else {
          onEdit(dataRow, rowIndex);
        }
      }
    });
    e.preventDefault();
  };

  const onInput = (e, colName, rowIndex) => {
    let row = Object.assign({}, data[rowIndex]);
    row[colName] = parseFloat(e.target.value);
    if (rowIndex > (gdata.length-1)){
      onAdd(row)
    } else {
      onEdit(row, rowIndex);
    }
  }

  const switchElement = (e) => {
    const id = e.target.id
    const idArray = id.split("-")
    const isFormattedElement = idArray.length === 2 ? true : false
    if (isFormattedElement) {
      //hide formatted, display real value
      const elInputName = idArray[1]
      const elInput = document.getElementById(elInputName)
      elInput.style.display = "inline";
      elInput.focus()
      const elFormatted = e.target
      elFormatted.style.display = "none";
    } else {
      //hide real value, display formatted
      const id = e.target.id
      const elInput = e.target
      elInput.style.display = "none";
      elInput.blur()
      // example: "#formatted-minAep"
      const elFormattedName = "formatted-" + id
      const elFormatted = document.getElementById(elFormattedName)
      elFormatted.style.display = "inline";
    }
  }

  return (
    <Card>
      <CardBody>
        <Label>
          {title} 
          <span id={'infoIcon'}>
            <FontAwesomeIcon icon={faInfoCircle} className="ms-2" />
          </span>
          <UncontrolledTooltip target="infoIcon" placement="auto" autohide={false} style={{ "maxWidth": "500px" }}>
            <div className="text-start">
              <p>
                To paste Excel data, first copy the data from Excel then click the first cell 
                of the table below. Use CTRL-v (or Command-v for Macintosh) to paste the values.</p>
            </div>
          </UncontrolledTooltip>
        </Label>
        <Table bordered responsive>
          <thead>
            <tr>
              {columns.map((column, columnIndex) => {
                return (
                  <th key={columnIndex}>
                    {column.label}
                  </th>
                );
              })}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => {
              return (
                <tr key={rowIndex} style={{margin:"0", padding:"0"}}>
                  {columns.map((column, columnIndex) => {
                    return (
                      <td key={columnIndex} style={{margin:"0", padding:"0"}}>
                        <NumericFormat
                          id={'formatted-'+column.name + "_" + rowIndex}
                          value={row[column.name] ? row[column.name] : ""}
                          className="edit-table-input"
                          style={{margin:"0", padding:".8rem"}}
                          readOnly
                          onFocus={switchElement}
                          decimalScale={column.name === "probability" ? analysis.decimalDigitsAep : analysis.decimalDigitsGeneral}
                        />
                        <NumericFormat
                          id={column.name + "_" + rowIndex}
                          value={row[column.name] ? row[column.name] : ""}
                          className="edit-table-input"
                          onBlur={(e) => { onInput(e, column.name, rowIndex); switchElement(e)}}
                          onPaste={(e) => { onPaste(e, column.name, rowIndex); }}
                          displayType={'input'}
                          style={{margin:"0", padding:".8rem", display:"none"}}
                        />
                      </td>
                    )}
                  )}
                  <td className="text-center">
                    {rowIndex < data.length - 1 &&
                      <Button
                        id={"delete_" + rowIndex}
                        color="link"
                        size="sm"
                        onClick={(e) => { onDelete(rowIndex) }}>
                        <FontAwesomeIcon icon={faXmark} />
                      </Button>
                    }
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};