import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileUpload,
  faFileDownload,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { EditTable } from "./EditTable";
import { useDomainListAPI } from "../hooks/domainListAPI";
import { usePrecipFrequencyAPI } from "../hooks/precipFrequencyAPI";
import { useLoading } from "../hooks/Loading";
import Loader from "./Loader";
import { Modal, ModalBody, Button } from "reactstrap";

const eddTemplate = `${process.env.PUBLIC_URL}/ee_cdf_edd.csv`;

export const EmpiricalExpectedCDF = ({ precipFrequencyId }) => {
  const [isLoading, load] = useLoading();
  const {
    item,
    onChange,
    addCurve,
    editCurve,
    deleteCurve,
    fileUpload,
    error,
  } = usePrecipFrequencyAPI();


  const { durations } = useDomainListAPI();

  const toast = (msg, msgClass) => {
    const el = document.getElementById("toast");
    if (el == null) {
      return;
    }
    el.innerHTML = msg;
    el.className = el.className.replace("invisible", "visible " + msgClass);
    setTimeout(() => {
      el.className = el.className.replace("visible " + msgClass, "invisible");
    }, 5000);
  };
  const uploadFile = async (e) => {
    if (e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const validFileTypes = [
        "text/x-csv",
        "application/vnd.ms-excel",
        "application/csv",
        "application/x-csv",
        "text/csv",
        "text/comma-separated-values",
        "text/x-comma-separated-values",
      ];
      if (validFileTypes.indexOf(selectedFile.type) === -1) {
        toast(
          `File type must be CSV. Uploaded file type: ${selectedFile.type}`,
          "text-danger"
        );
      } else if (selectedFile.size > 2000000000) {
        toast("Maximum file size is 20 megabytes.", "text-danger");
      } else {
        await load(fileUpload(precipFrequencyId, selectedFile));
        if (!error) {
          toast("File has been uploaded.", "text-success");
        }
      }
      e.target.value = null;
    }
  };

  const columns = [
    {
      name: "probability",
      label: "Annual Exceedance Probability",
      min: 0,
      step: 0.5,
    },
    {
      name: "value",
      label: "Precipitation (inches)",
      min: 0,
      step: 1,
    },
  ];

  const emptyRow = { probability: "", value: "", curveType: "Expected" };
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const closeModal = () => {
    setModal(false);
  };
  const modalStyle = {
    border: "0",
    borderRadius: "4px",
    bottom: "auto",
    minHeight: "10rem",
    left: "50%",
    padding: "2rem",
    position: "fixed",
    right: "auto",
    top: "50%",
    height: "50%",
    transform: "translate(-50%,-50%)",
    minWidth: "10rem",
    width: "50%",
    maxWidth: "40rem",
  };
  const [gdata, setData] = useState(item.curves);
  useEffect(() => {
    setData(item.curves);
  }, [item.curves]);
  return (
    <React.Fragment>
      <Row className="mt-3">
        <Col md="12">
          <legend title="EmpiricalCDF">Empirical Expected CDF</legend>
          <Row>
            <Col md="12">
              <Row>
                <Col md="4">
                  <FormGroup className="mt-3">
                    <Label for="duration">Duration</Label>
                    <Input
                      type="number"
                      min="1"
                      step="1"
                      className="form-control"
                      placeholder="Enter duration"
                      name="duration"
                      value={item.duration.toString()}
                      onChange={onChange}
                    />
                    {item.errors.duration.length > 0 && (
                      <span className="text-danger">
                        <b>{item.errors.duration}</b>
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mt-3">
                    <Label for="durationUnit">Duration Units</Label>
                    <select
                      className="form-control"
                      name="durationUnit"
                      value={item.durationUnit}
                      onChange={onChange}
                    >
                      <option value="" />
                      {durations.map((s) => (
                        <option key={s.id} value={s.name}>
                          {s.name}
                        </option>
                      ))}
                    </select>
                    {item.errors.durationUnit.length > 0 && (
                      <span className="text-danger">
                        <b>{item.errors.durationUnit}</b>
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="mt-3">
                    <Label>Spatial type</Label>
                    <Input
                      type="text"
                      className="form-control"
                      name="spatialType"
                      readOnly="readOnly"
                      value={item.spatialType.toString()}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Row>
                <span>Import CDF from CSV file (replaces existing data)</span>
              </Row>
              <Row>
                <Col md="3" className="mt-2">
                  <label
                    htmlFor="upload"
                    className="custom-upload w-100 text-center"
                  >
                    <FontAwesomeIcon icon={faFileUpload} className="me-3" />{" "}
                    Select file to upload
                  </label>
                  <Input id="upload" type="file" onChange={uploadFile} />
                </Col>
                <Col md="3" className="mt-2">
                  <a
                    href={eddTemplate}
                    target="_blank"
                    rel={"noopener noreferrer"}
                    className="btn btn-default btn-sm w-100 text-center"
                    title="Download EDD template"
                  >
                    <FontAwesomeIcon icon={faFileDownload} className="me-3" />
                    <span>Download EDD template</span>
                  </a>
                </Col>
                <Col md="3" className="mt-2">
                  <Button
                    color="light"
                    size="sm"
                    className="custom-upload w-100 text-center"
                    onClick={toggle}
                  >
                    <FontAwesomeIcon icon={faTable} className="me-3" />
                    <span>Edit Table Data </span>
                  </Button>
                </Col>
              </Row>
              <Row>
                <span
                  id="toast"
                  className="float-right font-italic invisible"
                ></span>
              </Row>
              <Row>
                <Modal
                  isOpen={modal}
                  toggle={toggle}
                  style={modalStyle}
                  scrollable={true}
                >
                  <ModalBody>
                    <EditTable
                      title="Add Data"
                      gdata={gdata}
                      columns={columns}
                      emptyRowObj={emptyRow}
                      onAdd={addCurve}
                      onEdit={editCurve}
                      onDelete={deleteCurve}
                    />
                    <Button
                      color="dark"
                      size="sm"
                      className="me-2"
                      onClick={closeModal}
                      style={{ marginTop: "10px" }}
                    >
                      <span>Close</span>
                    </Button>
                  </ModalBody>
                </Modal>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Loader loading={isLoading} />
    </React.Fragment>
  );
};
